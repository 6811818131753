import React from 'react'

import PasswordChecklist from 'react-password-checklist'
import ReactTooltip from 'react-tooltip'

const RULES = ['minLength', 'specialChar', 'number', 'capital']
const CONFIRM_RULES = ['match']

export default class PasswordInput extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: this.props.value,
      matchValue: props.matchValue,
      rules: RULES,
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      ...nextProps,
    })
  }

  isValid() {
    let { value, matchValue } = this.state
    let isValid = true

    // Check password length
    if (value == null || value.length < 8) {
      isValid = false
    }

    // Check password contains a number
    var reg = /[0-9]+/
    if (!reg.test(value)) {
      isValid = false
    }

    // Check password contains a special character
    if (!/[~`¿¡!#$%\^&*€£@+÷=\-\[\]\\';,/{}\(\)|\\":<>\?\.\_]/g.test(value)) {
      isValid = false
    }

    // Check password contains a capital letter
    reg = /[^a-zA-Z0-9]+/
    if (!reg.test(this.state.value)) {
      isValid = false
    }

    if (matchValue && value != matchValue) {
      isValid = false
    }

    return isValid
  }

  _renderTooltip() {
    let { value, matchValue, confirmInputFocused, rules } = this.state
    if (!this.props.showValidationCheck) {
      return
    }
    if (confirmInputFocused && rules.indexOf('match') == -1) {
      rules = CONFIRM_RULES
    }
    if (!confirmInputFocused && rules.indexOf('match') > -1) {
      rules = RULES
    }
    return (
      <ReactTooltip
        id="password-validation"
        effect="solid"
        event="onFocus"
        eventOff="onBlur"
        place="bottom"
        className='react-tooltip brand-border-color'
      >
        <PasswordChecklist
          rules={rules}
          minLength={8}
          value={value || ''}
          valueAgain={matchValue || ''}
          onChange={(isValid) => {}}
          className={"react-password-checklist"}
          messages={{
            minLength: 'Password has at least 8 characters.',
          }}
          iconComponents={{
            ValidIcon: <span class="icon material-icons-two-tone brand-text-color">done</span>, 
            InvalidIcon: <span class="icon material-icons-two-tone">close</span>
          }}
        />
      </ReactTooltip>
    )
  }

  _renderLabel() {
    if (typeof this.props.label === 'function') {
      return this.props.label()
    }
    if (!this.props.label) {
      return null
    }
    return (
      <label className={this.props.labelClassName}>{this.props.label}</label>
    )
  }

  render() {
    let { value, matchValue } = this.state

    return (
      <>
        <div className={this.props.containerClassName}>
          {this._renderLabel()}
          <div className={this.props.inputContainerClassName}>
            <input
              {...this.props}
              ref={(ref) => (this.input = ref)}
              onFocus={() => {
                if (!this.props.showValidationCheck) {
                  return
                }
                ReactTooltip.show(this.input)
              }}
              onBlur={() => {
                if (!this.props.showValidationCheck) {
                  return
                }
                ReactTooltip.hide()
              }}
              data-tip
              data-for="password-validation"
              className={this.props.className}
              type="password"
              placeholder={this.props.placeholder}
              name="password"
              value={value}
              onChange={(e) => {
                if (this.props.onChange) {
                  this.props.onChange(e.target.value, e.target.name)
                }
                this.setState({ value: e.target.value })
              }}
            />
          </div>
        </div>
        {this.props.onConfirmChange && (
          <div className={this.props.containerClassName}>
            <label className={this.props.labelClassName}>
              {this.props.confirmLabel}
            </label>
            <div className={this.props.inputContainerClassName}>
              <input
                {...this.props}
                ref={(ref) => (this.confirmPassword = ref)}
                onFocus={() => {
                  if (!this.props.showValidationCheck) {
                    return
                  }
                  this.setState({ confirmInputFocused: true })
                  ReactTooltip.show(this.confirmPassword)
                }}
                onBlur={() => {
                  if (!this.props.showValidationCheck) {
                    return
                  }
                  this.setState({ confirmInputFocused: false })
                  ReactTooltip.hide()
                }}
                data-tip
                data-for="password-validation"
                className={this.props.className}
                type="password"
                placeholder={this.props.confirmPlaceholder}
                name="confirm-password"
                value={matchValue}
                onChange={(e) => {
                  if (this.props.onConfirmChange) {
                    this.props.onConfirmChange(e.target.value, e.target.name)
                  }
                  this.setState({ matchValue: e.target.value })
                }}
              />
            </div>
          </div>
        )}
        {this._renderTooltip()}
      </>
    )
  }
}

PasswordInput.defaultProps = {
  containerClassName: 'form-group',
  labelClassName: 'font-size-h6 font-weight-bolder text-dark',
  label: 'Password',
  placeholder: 'Password',
  confirmLabel: 'Confirm Password',
  confirmPlaceholder: 'Confirm Password',
}
