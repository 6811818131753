import React from 'react'

import { withRouter } from 'react-router-dom'

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/bootstrap.css'
import PasswordInput from '../common/PasswordInput'

import moment from 'moment'

import Select from '../common/Select'
import AsyncSelect from '../common/AsyncSelect'
import LocationInput from '../common/LocationInput'
import Branding from '../common/Branding'

import Currency from '../../../utils/Currency'
import Backend from '../../../utils/Backend'
import General from '../../../utils/General'
import Splink from '../../../utils/Splink'
import Notify from '../../../utils/Notify'
import Event from '../../../utils/Event'

import Tour from 'reactour'

let YEARS = []

export class Form extends React.Component {
  constructor(props) {
    super(props)

    let minDate = moment().add('years', -18)
    for (var i = 0; i < 200; i++) {
      let year = moment(minDate).add('years', -i).years()
      YEARS.push({ value: year, label: year.toString() })
    }
    this.state = this._getState(props)

    this.location = React.createRef()

    setTimeout(() => {
      this.showTour()
    }, 3000)
  }

  componentDidMount() {
    Event.on('SHOW_ACTIVATE_TOUR', () => this.showTour(true))
    this._checkSlug()
  }

  showTour(shakeTour = null) {
    this.setState({ shakeTour, isTourOpen: true, tourStep: 0 })
  }

  _getState(props) {
    let { company, authToken, phone, address } = props

    address = address || { country_short: 'IE' }

    return {
      phone,
      company,
      authToken,
      address,
      slug: company.slug,
    }
  }

  _isFormValid() {
    let {
      dob,
      address,
      errorSlug,
      first_name,
      last_name,
      phone_number,
      password,
      sector,
      website,
    } = this.state

    let errors = []

    let inputSplinkError = null
    let inputFirstNameError = null
    let inputLastNameError = null
    let inputDOBError = null
    let inputPhoneError = null
    let inputPasswordError = null
    let inputSectorError = null
    let inputWebsiteError = null

    if (errorSlug) {
      inputSplinkError = {
        selector: '#input-splink',
        message: `Please enter a valid ${window.General.Branding.LinkName} url`,
      }
      errors.push(inputSplinkError)
    }
    if (!first_name) {
      inputFirstNameError = {
        selector: '#input-first-name',
        message: 'Please enter your first name',
      }
      errors.push(inputFirstNameError)
    }
    if (!last_name) {
      inputLastNameError = {
        selector: '#input-last-name',
        message: 'Please enter your last name',
      }
      errors.push(inputLastNameError)
    }
    if (!this.location.current.isValid(errors.length === 0)) {
      errors.push({
        selector: '#input-address',
        message: 'Please include your address',
      })
    }
    if (!dob) {
      inputDOBError = {
        selector: '#input-dob',
        message: 'Please enter a valid date of birth',
      }
      errors.push(inputDOBError)
    }
    if (!phone_number || phone_number <= 6) {
      inputPhoneError = {
        selector: '#input-phone',
        message: 'Please include a valid phone number',
      }
      errors.push(inputPhoneError)
    }
    if (!website) {
      inputWebsiteError = {
        selector: '#input-website',
        message: 'Please include a valid website/LinkedIn url',
      }
      errors.push(inputWebsiteError)
    }
    if (!password || password.length < 6) {
      inputPasswordError = {
        selector: '#input-password',
        message: 'Password must be 6 or more characters',
      }
      errors.push(inputPasswordError)
    }
    if (!sector) {
      inputSectorError = {
        selector: '#input-sector',
        message: 'Please select a sector',
      }
      errors.push(inputSectorError)
    }

    this.setState({
      errors,
      inputSplinkError,
      inputFirstNameError,
      inputLastNameError,
      inputDOBError,
      inputPhoneError,
      inputPasswordError,
      inputSectorError,
      inputWebsiteError,
    })

    if (errors.length > 0) {
      if (errors[0].selector !== '#input-address') {
        General.scrollTo(errors[0].selector)
      }
      return false
    }
    return true
  }

  _updateDOB() {
    let { day, month, year } = this.state

    if (day == null || month == null || year == null) {
      return
    }

    let dob = moment([year, month, day])
    if (!dob.isValid()) {
      this.setState({
        dob: null,
        inputDOBError: {
          selector: '#input-dob',
          message: 'Please enter a valid date of birth',
        },
      })
    } else {
      this.setState({ dob, inputDOBError: null })
    }
  }

  _updatePhone(value, data) {
    if (!data || !data.dialCode || !value) return

    let phone_country_code = `+${data.dialCode}`
    let phone_number = value.replace(/[^0-9]+/g, '').slice(data.dialCode.length)

    this.setState({
      phone: value,
      phone_country_code,
      phone_number,
      inputPhoneError: null,
    })
  }

  _getData() {
    let {
      first_name,
      last_name,
      phone,
      dob,
      slug,
      address,
      company,
      password,
      authToken,
      phone_country_code,
      phone_number,
      sector,
      website,
      userAddress,
    } = this.state

    address.country = address.country_short
    let connect_data = {
      country_short: address.country_short,
      currency: company.settings.payment_plan.currency.code,
      business_type: 'individual',
      persons: [],
      representative: {
        first_name,
        last_name,
        phone: `+${phone}`,
        address,
        relationship: {
          director: false,
          executive: false,
          owner: false,
        },
        dob: {
          year: dob.format('YYYY'),
          day: dob.format('D'),
          month: dob.format('M'),
        },
      },
    }

    if (
      website.indexOf('http://') === -1 &&
      website.indexOf('https://') === -1
    ) {
      website = `http://${website}`
    }

    return {
      slug,
      first_name,
      last_name,
      company: company.id,
      sector: sector.id,
      website_url: website,
      password,
      phone,
      phone_country_code,
      phone_number,
      connect_data,
      address: userAddress,
      date_of_birth: dob.format('YYYY-MM-DD'),
      auth_token: authToken,
    }
  }

  _activateAccount() {
    if (!this._isFormValid()) {
      return
    }

    let { company } = this.state

    let data = this._getData()

    this.setState({ loading: true })
    Backend.activate(company, data)
      .then(() => {
        window.location = window.General.ClientDashboardUrl + '?welcome=true'
      })
      .catch((error) => {
        this.setState({ loading: false })
        Notify.error(error.message)
      })
  }

  _checkSlug() {
    let { slug, company } = this.state
    this.setState({ isLoadingSlug: true })
    Backend.slugify(slug)
      .then((response) => {
        if (slug.length <= 1 || slug.length > 25) {
          this.setState({
            inputSplinkError: {
              message: `${window.General.Branding.LinkName} must be between 2 and 25 characters long`,
            },
            errorSlug: true,
            isLoadingSlug: false,
          })
          return
        }
        if (!slug || (response.slug !== slug && slug !== company.slug)) {
          this.setState({ errorSlug: true, isLoadingSlug: false })
        } else {
          this.setState({ errorSlug: false, isLoadingSlug: false })
        }
      })
      .catch((error) => {
        Notify.error(error.message)
        this.setState({ isLoadingSlug: false })
      })
  }

  render() {
    let {
      shakeTour,
      tourStep,
      company,
      loading,
      slug,
      errorSlug,
      phone,
      day,
      month,
      year,
      address,
      inputSplinkError,
      inputFirstNameError,
      inputLastNameError,
      inputDOBError,
      inputPhoneError,
      inputPasswordError,
      inputSectorError,
      inputWebsiteError,
    } = this.state

    let className = 'form activate-form'
    if (loading) {
      className += ' activate-form-loading'
    }

    let slugClassName = 'input-group-prepend-bg-dark '

    if (slug) {
      slugClassName += errorSlug
        ? 'input-group-prepend-bg-dark-error'
        : 'input-group-prepend-bg-dark'
    }

    let maskClassName =
      tourStep === 0 ? 'tour-mask-hidden' : 'tour-mask-visible'
    return (
      <>
        <div className={className}>
          <div id="activate-title" className="pb-13 pt-lg-0 pt-5">
            <h3 className="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">
              Secure Your Account
            </h3>
            <p className="font-weight-bold activate-form-explainer">
              Take a minute to enter your details so we can verify and secure
              your account to process payments.
            </p>
          </div>

          <form autoComplete="off">
            <div id="input-splink" className="form-group">
              <label>
                <span className="font-size-h6 font-weight-bolder text-dark">
                  {window.General.Branding.LinkName}
                </span>{' '}
                (What URL Should Your Customers Visit To Pay You)
              </label>
              <div className={`input-group with-append ${slugClassName}`}>
                <input
                  type="text"
                  name="slug"
                  className="form-control form-control-lg form-control-solid h-auto py-7 px-6 text-right font-size-h6 login-slug-field"
                  value={slug}
                  placeholder=""
                  onChange={(e) =>
                    this.setState({
                      slug: e.target.value,
                      errorSlug: false,
                      inputSplinkError: null,
                    })
                  }
                  onBlur={(e) => {
                    this._checkSlug()
                  }}
                />
                <div className="input-group-append input-group-pill">
                  <span className="input-group-text input-group-payment-form h5">
                    .{window.General.Branding.Domain}
                  </span>
                </div>
              </div>
              {inputSplinkError && (
                <span className="validation-error-message">
                  {inputSplinkError.message}
                </span>
              )}
            </div>

            <div className="row">
              <div className="col-lg-6">
                <div id="input-first-name" className="form-group">
                  <label>
                    <span className="font-size-h6 font-weight-bolder text-dark">
                      First Name
                    </span>
                  </label>
                  <div
                    className={`input-group ${
                      inputFirstNameError ? 'validation-error' : ''
                    }`}
                  >
                    <input
                      className="form-control h-auto border-0"
                      type="text"
                      placeholder="First Name"
                      name="first_name"
                      autoComplete="off"
                      onChange={(e) =>
                        this.setState({
                          first_name: e.target.value,
                          inputFirstNameError: null,
                        })
                      }
                    />
                  </div>
                  {inputFirstNameError && (
                    <span className="validation-error-message">
                      {inputFirstNameError.message}
                    </span>
                  )}
                </div>
              </div>

              <div className="col-lg-6">
                <div id="input-last-name" className="form-group">
                  <label>
                    <span className="font-size-h6 font-weight-bolder text-dark">
                      Last Name
                    </span>
                  </label>
                  <div
                    className={`input-group ${
                      inputLastNameError ? 'validation-error' : ''
                    }`}
                  >
                    <input
                      className="form-control h-auto border-0"
                      type="text"
                      placeholder="Last Name"
                      name="last_name"
                      autoComplete="off"
                      onChange={(e) =>
                        this.setState({
                          last_name: e.target.value,
                          inputLastNameError: null,
                        })
                      }
                    />
                  </div>
                  {inputLastNameError && (
                    <span className="validation-error-message">
                      {inputLastNameError.message}
                    </span>
                  )}
                </div>
              </div>
            </div>

            <div id="input-address">
              <label>
                <span className="font-size-h6 font-weight-bolder text-dark">
                  Address
                </span>{' '}
                (Secure Your Account For Payments)
              </label>
              <LocationInput
                ref={this.location}
                className="form-control h-auto border-0"
                requirePostalCode={this.props.requirePostalCode}
                allowManualInput={true}
                onUpdated={(address) => {
                  let connectAddress = {
                    line1: address.line_1,
                    line2: address.line_2,
                    city: address.city,
                    state: address.state,
                    country: address.country,
                    country_short: address.country_short,
                    postal_code: address.postal_code,
                  }
                  this.setState({
                    address: connectAddress,
                    userAddress: address,
                  })
                }}
              />
            </div>

            <div id="input-dob" className="mb-5">
              <label>
                <span className="font-size-h6 font-weight-bolder text-dark">
                  Date of Birth
                </span>{' '}
                (Secure Your Account For Payments)
              </label>

              <div className="row">
                <div className="col-lg-4">
                  <div className="form-group mb-0">
                    <div
                      className={`input-group ${
                        inputDOBError && !day ? 'validation-error' : ''
                      }`}
                    >
                      <Select
                        options={DAYS}
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.value}
                        placeholder="Day"
                        className="form-control h-auto border-0 c-selectbox"
                        onSelected={(option) => {
                          this.setState({ day: option.value }, () =>
                            this._updateDOB()
                          )
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="form-group mb-0">
                    <div
                      className={`input-group ${
                        inputDOBError && !month ? 'validation-error' : ''
                      }`}
                    >
                      <Select
                        options={MONTHS}
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.value}
                        placeholder="Month"
                        className="form-control h-auto border-0 c-selectbox"
                        onSelected={(option) => {
                          this.setState({ month: option.value }, () =>
                            this._updateDOB()
                          )
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="form-group mb-0">
                    <div
                      className={`input-group ${
                        inputDOBError && !year ? 'validation-error' : ''
                      }`}
                    >
                      <Select
                        options={YEARS}
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.value}
                        placeholder="Year"
                        className="form-control h-auto border-0 c-selectbox"
                        onSelected={(option) => {
                          this.setState({ year: option.value }, () =>
                            this._updateDOB()
                          )
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {inputDOBError && (
                <span className="validation-error-message">
                  {inputDOBError.message}
                </span>
              )}
            </div>

            <div id="input-phone" className="form-group">
              <label>
                <span className="font-size-h6 font-weight-bolder text-dark">
                  Phone Number
                </span>{' '}
                (Secure Your Account For Payments)
              </label>
              <div
                className={`input-group ${
                  inputPhoneError ? 'validation-error' : ''
                }`}
              >
                <PhoneInput
                  country={address?.country_short?.toLowerCase()}
                  value={phone}
                  onChange={(value, data) => this._updatePhone(value, data)}
                  inputProps={{
                    autoComplete: 'off',
                    className: 'form-control h-auto border-0',
                  }}
                />
              </div>
              {inputPhoneError && (
                <span className="validation-error-message">
                  {inputPhoneError.message}
                </span>
              )}
            </div>

            <div id="input-website" className="form-group">
              <label>
                <span className="font-size-h6 font-weight-bolder text-dark">
                  Website/LinkedIn Url
                </span>
              </label>
              <div
                className={`input-group ${
                  inputWebsiteError ? 'validation-error' : ''
                }`}
              >
                <input
                  className="form-control h-auto border-0"
                  type="text"
                  placeholder="www.mywebsite.com"
                  name="website_url"
                  autoComplete="off"
                  onChange={(e) =>
                    this.setState({
                      website: e.target.value,
                      inputWebsiteError: null,
                    })
                  }
                />
              </div>
              {inputWebsiteError && (
                <span className="validation-error-message">
                  {inputWebsiteError.message}
                </span>
              )}
            </div>

            <div id="input-sector" className="form-group">
              <label>
                <span className="font-size-h6 font-weight-bolder text-dark">
                  Sector
                </span>
              </label>
              <div
                className={`input-group ${
                  inputSectorError ? 'validation-error' : ''
                }`}
              >
                <AsyncSelect
                  endpoint={window.Api.Sectors}
                  placeholder={'Sector'}
                  validateTokens={false}
                  params={{}}
                  onSelected={(sector) => {
                    this.setState({ sector, inputSectorError: null })
                  }}
                  getOptions={(sectors) => General.getAsyncOptions(sectors)}
                  styles={{}}
                />
              </div>
              {inputSectorError && (
                <span className="validation-error-message">
                  {inputSectorError.message}
                </span>
              )}
            </div>

            <div id="input-password" className="form-group">
              <PasswordInput
                containerClassName=""
                inputContainerClassName={`input-group ${
                  inputPasswordError ? 'validation-error' : ''
                }`}
                labelClassName="font-size-h6 font-weight-bolder text-dark"
                className="form-control h-auto border-0"
                onChange={(value) => {
                  this.setState({
                    password: value,
                    inputPasswordError: null,
                  })
                }}
                showValidationCheck
              />
              {inputPasswordError && (
                <span className="validation-error-message">
                  {inputPasswordError.message}
                </span>
              )}
            </div>

            <div className="form-group">
              <button
                type="button"
                id="kt_login_signup_submit"
                className="btn btn-primary btn-block"
                onClick={(e) => this._activateAccount()}
                disabled={loading}
              >
                {loading && (
                  <span className="loading mr-2">
                    <i className="fa fa-spinner fa-spin"></i>
                  </span>
                )}
                Activate My Account
              </button>
            </div>
          </form>
        </div>
        <Tour
          startAt={0}
          steps={TOUR_STEPS}
          className={shakeTour ? 'tour-container tour-shake' : 'tour-container'}
          accentColor={'#333'}
          scrollDuration={300}
          maskClassName={maskClassName}
          isOpen={this.state.isTourOpen}
          onRequestClose={() => this.setState({ isTourOpen: false })}
          onAfterOpen={this.disableBody}
          onBeforeClose={this.enableBody}
          goToStep={tourStep}
          showNumber={tourStep != 0}
          showButtons={false}
          showNavigation={false}
          showCloseButton={false}
          badgeContent={(curr, tot) => (
            <p className="tour-step-badge">{tourStep}</p>
          )}
          getCurrentSep={(tourStep) =>
            this.setState({ tourStep: tourStep + 1 })
          }
          disableInteraction={true}
          closeWithMask={true}
        />
        <Branding name="Activate" />
      </>
    )
  }
}

Form.defaultProps = {}

const MONTHS = [
  { value: 0, label: 'January' },
  { value: 1, label: 'Febuary' },
  { value: 2, label: 'March' },
  { value: 3, label: 'April' },
  { value: 4, label: 'May' },
  { value: 5, label: 'June' },
  { value: 6, label: 'July' },
  { value: 7, label: 'August' },
  { value: 8, label: 'September' },
  { value: 9, label: 'October' },
  { value: 10, label: 'November' },
  { value: 11, label: 'December' },
]

const DAYS = [
  { value: 1, label: '1' },
  { value: 2, label: '2' },
  { value: 3, label: '3' },
  { value: 4, label: '4' },
  { value: 5, label: '5' },
  { value: 6, label: '6' },
  { value: 7, label: '7' },
  { value: 8, label: '8' },
  { value: 9, label: '9' },
  { value: 10, label: '10' },
  { value: 11, label: '11' },
  { value: 12, label: '12' },
  { value: 13, label: '13' },
  { value: 14, label: '14' },
  { value: 15, label: '15' },
  { value: 16, label: '16' },
  { value: 17, label: '17' },
  { value: 18, label: '18' },
  { value: 19, label: '19' },
  { value: 20, label: '20' },
  { value: 21, label: '21' },
  { value: 22, label: '22' },
  { value: 23, label: '23' },
  { value: 24, label: '24' },
  { value: 25, label: '25' },
  { value: 26, label: '26' },
  { value: 27, label: '27' },
  { value: 28, label: '28' },
  { value: 29, label: '29' },
  { value: 30, label: '30' },
  { value: 31, label: '31' },
]

const step = function (title, text, arrow) {
  return (
    <div className={'tour-step ' + arrow}>
      <div className="tour-content">
        <h3 className="mb-0 text-center">{title}</h3>
        <p hidden>{text}</p>
        <span className="material-icons">{arrow}</span>
      </div>
    </div>
  )
}

const TOUR_STEPS = [
  {
    selector: '#activate-title',
    position: 'left',
    content: step(
      'Last Step',
      'Congrats, your payment link has been created. Take a moment to verify your account, so you can start taking payments straight away',
      'arrow_right'
    ),
  },
]

export default withRouter(Form)
