
export default class Splink {

  static getSplinkUrl(company){
    return `https://${company.slug}.${window.General.WebsiteDomain}`
  }

  static getShopUrl(company){
    return `${Splink.getSplinkUrl(company)}/shop`
  }

  static getProductUrl(company, product){
    return `${Splink.getSplinkUrl(company)}/shop/${product.slug}`
  }

  static getPaymentLinkUrl(company, paymentLink){
    return `${Splink.getSplinkUrl(company)}/channels/${paymentLink.slug}`
  }

  static getPaymentRequestUrl(company, paymentRequest){
    return `${Splink.getSplinkUrl(company)}/payment-requests/${paymentRequest.slug}`
  }

  static getPaymentLinksUrl(company){
    return `${Splink.getSplinkUrl(company)}/channels`
  }

  static getPaymentLinkSlug(company, paymentLink){
    return `/channels/${paymentLink.slug}`
  }

  static getProductSlug(company, product){
    return `/shop/${product.slug}`
  }
}
