import React from 'react'
import Modal from 'react-bootstrap/Modal'

import Backend from "../../../../utils/Backend"
import Notify from "../../../../utils/Notify";

import LockInputAppendIcon from "../LockInputAppendIcon"

export default class ACHModal extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      show: props.show,
      name: props.name,
      email: props.email,
      accountNumber: props.accountNumber,
      routingNumber: props.routingNumber
    }
  }

  _isValid(){
    let { name, email, routingNumber, accountNumber } = this.state

    let routingError = null
    let accountError = null
    let nameError = null
    let emailError = null

    if(!name){
      nameError = "Please enter a valid account holder name"
    }
    if(!email){
      emailError = "Please enter a valid email"
    }
    if(!routingNumber){
      routingError = "Please enter a valid routing number"
    }
    if(!accountNumber){
      accountError = "Please enter a valid account number"
    }

    this.setState({
      nameError,
      emailError,
      routingError,
      accountError
    })

    return !nameError && !emailError && !routingError && !accountError
  }

  _onPayPressed(){
    if(!this._isValid()){
      return
    }

    let { name, email, routingNumber, accountNumber } = this.state

    this.props.onPayPressed(name, email, routingNumber, accountNumber)
  }

  render() {
    let {
      show,
      error,
      nameError,
      emailError,
      routingError,
      accountError,
      name,
      email,
      accountNumber,
      routingNumber
    } = this.state

    return (
      <Modal
        show={show}
        onHide={() => this.props.onClose()}
        centered
        className={this.props.modalClassName}
      >
        <Modal.Header closeButton>
          <Modal.Title> Pay By Bank</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p className="text-muted mt-0 mb-6">Enter your banking details below to Pay By Bank</p>
          <div id="input-name" className="form-group mb-2">
            <div className={`input-group with-append ${nameError ? "validation-error" : ""}`}>
              <input
                className="form-control h-auto"
                type="text"
                placeholder={"Account Holder Name"}
                value={name}
                onChange={e => {

                  this.setState({
                    name: e.target.value || null,
                    nameError: null
                  })
                }}
              />
              <LockInputAppendIcon/>
            </div>
            { nameError &&
              <span className="validation-error-message">{ nameError }</span>
            }
          </div>
          <div id="input-email" className="form-group mb-2">
            <div className={`input-group with-append ${emailError ? "validation-error" : ""}`}>
              <input
                className="form-control h-auto"
                type="text"
                placeholder={"Email Address"}
                value={email}
                onChange={e => {

                  this.setState({
                    email: e.target.value || null,
                    emailError: null
                  })
                }}
              />
              <LockInputAppendIcon/>
            </div>
            { emailError &&
              <span className="validation-error-message">{ emailError }</span>
            }
          </div>
          <div id="input-routing" className="form-group mb-2">
            <div className={`input-group with-append ${routingError ? "validation-error" : ""}`}>
              <input
                className="form-control h-auto"
                type="number"
                value={routingNumber}
                placeholder={"Routing Number"}
                onChange={e => {

                  this.setState({
                    routingNumber: e.target.value || null,
                    routingError: null
                  })
                }}
              />
              <LockInputAppendIcon/>
            </div>
            { routingError &&
              <span className="validation-error-message">{ routingError }</span>
            }
          </div>

          <div id="input-acccount" className="form-group mb-2">
            <div className={`input-group with-append ${accountError ? "validation-error" : ""}`}>
              <input
                className="form-control h-auto"
                type="number"
                value={accountNumber}
                placeholder={"Account Number"}
                onChange={e => {

                  this.setState({
                    accountNumber: e.target.value || null,
                    accountError: null
                  })
                }}
              />
              <LockInputAppendIcon/>
            </div>
            { accountError &&
              <span className="validation-error-message">{ accountError }</span>
            }
          </div>
        </Modal.Body>

          <Modal.Footer>
            <div className="w-100 text-right">
              <button
                type="button"
                className={`btn btn-secondary mr-3`}
                onClick={() => this.props.onClose()}
              >
                Close
              </button>
              <button
                type="button"
                className={`btn btn-primary`}
                onClick={() => this._onPayPressed()}
              >
                Pay By Bank
              </button>
            </div>
          </Modal.Footer>
      </Modal>
    )
  }
}

ACHModal.defaultProps = {
  modalClassName: 'sub-modal',
}
