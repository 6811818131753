import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import { toast } from 'react-toastify';

import TagManager from 'react-gtm-module'

import setupApiConstants from './constants/Api'
import setupGeneralConstants from './constants/General'

import logo from './logo.svg';
import './App.css';

import Api from './constants/Api';
import General from './constants/General';
import generalUtils from "./utils/General";

import Backend from './utils/Backend'

import Open from './pages/Open'
import Shop from './pages/Shop'
import Activate from './pages/Activate'
import PaymentLink from './pages/PaymentLink'
import PaymentLinks from './pages/PaymentLinks'
import PaymentRequest from './pages/PaymentRequest'
import Subscription from './pages/Subscription'

import NotFound from './pages/NotFound'

import ProtectedRoute from './components/ProtectedRoute';
import UnauthenticatedRoute from './components/UnauthenticatedRoute';

import Intercom from './utils/Intercom';

import './assets/css/pages/login/classic/login-3.css';
import './assets/plugins/global/plugins.bundle.css';
import './assets/plugins/custom/prismjs/prismjs.bundle.css';
import './assets/css/style.bundle.css';
import './assets/css/themes/layout/header/base/light.css';
import './assets/css/themes/layout/header/menu/light.css';
import './assets/css/themes/layout/brand/dark.css';
import './assets/css/themes/layout/aside/dark.css';
import './assets/css/pages/login/login-1.css';
import './assets/css/custom.css';
import './assets/scss/app.scss';
import './assets/js/app.js';

toast.configure()

const ROUTES = [
    { path: "/404", component: NotFound },

    { path: "/", component: Open },
    { path: "/activate/:authToken", component: Activate },

    { path: "/shop", component: Shop },
    { path: "/shop/:productSlug", component: Shop },

    { path: "/pr/:paymentRequestSlug", component: PaymentRequest },
    { path: "/payment-requests/:paymentRequestSlug", component: PaymentRequest },

    { path: "/payment-links", component: PaymentLinks },
    { path: "/channels", component: PaymentLinks },
    { path: "/subsplinks", component: PaymentLinks },

    { path: "/pl/:paymentLinkSlug", component: PaymentLink },
    { path: "/channels/:paymentLinkSlug", component: PaymentLink },
    { path: "/payment-links/:paymentLinkSlug", component: PaymentLink },

    { path: "/:paymentLinkSlug", component: PaymentLink },

    { path: "/subscriptions/:initialPaymentId/:authToken", component: Subscription },
];

const tagManagerArgs = {
  gtmId: 'GTM-KKSSSG7',
}

export default class App extends React.Component {
  constructor(props) {
    super(props)
    setupApiConstants()
    setupGeneralConstants()

    this.state = {
      loading: true,
    }
  }

  componentDidMount() {
    let scripts = []

    this._addScripts(scripts)

    Backend.getBranding()
    .then(branding => {
      window.General.Branding.Name = branding.platform_name
      window.General.Branding.LinkName = branding.link_name
      window.General.Branding.Colors = branding.colors

      window.General.Branding.TermsUrl      = branding.terms_url
      window.General.Branding.SupportUrl    = branding.support_url
      window.General.Branding.AddOnsUrl     = branding.add_ons_url
      window.General.Branding.NotFoundUrl   = branding.not_found_url
      window.General.Branding.ClientGTMID   = branding.client_gtm_id
      window.General.Branding.PaymentGTMID  = branding.payment_gtm_id

      if(branding.logo){
        window.General.Branding.Logo = branding.logo?.original
      }

      if(branding.banner){
        window.General.Branding.Banner = branding.banner?.original
      }

      if(branding.favicon){
        window.General.Branding.Favicon = branding.favicon?.thumbnail
      }

      window.General.Branding.Domain = branding.domain
      window.General.WebsiteDomain = branding.domain
      window.General.ClientDashboardUrl = `https://dashboard.${branding.domain}`
      window.General.AssociationId = branding.association_id

      window.General.PlatformPaymentMethods = branding.platform_payment_methods

      if (window.General.Branding.PaymentGTMID) {
        let tagManagerArgs = {
          gtmId: window.General.Branding.PaymentGTMID,
        }
        TagManager.initialize(tagManagerArgs)
      }

      window.Api.Keys.IntercomAppId = branding.intercom_app_id
      Intercom.load()
      Intercom.update()

      this.setState({ loading: false })
    })
    .catch(() => {
      this.setState({ loading: false })
    })
  }

  _getScript(path) {
    const script = document.createElement('script')

    script.src = path

    return script
  }

  // TO:DO remove need for sleep, the issue is vendors.bundle.js, takes too long to load
  // and scripts.bundle.js executes before it finishes causing all sorts of errors.
  async _addScripts(scripts) {
    for (var i = 0; i < scripts.length; i++) {
      let script = scripts[i]
      document.body.appendChild(script)
      await this._sleep(500)
    }
  }

  _sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms))
  }

  render() {
    let {
      loading
    } = this.state

    if(loading){
      return null
    }

    let companySlug = null

    let parts = window.location.host.split(".")
    if(parts.length > 2 && (window.location.host.indexOf(".herokuapp.com") === -1 && window.location.host.indexOf(".ngrok.io") === -1)){
      companySlug = parts[0]
    }
    else if(generalUtils.getQueryVariable('layout')=="classic"){
      companySlug = "stripe-classic"
    }
    else if(generalUtils.getQueryVariable('layout')=="modern"){
      companySlug = "stripe-modern"
    }
    else if(generalUtils.getQueryVariable('layout')=="retro"){
      companySlug = "stripe-retro"
   }
    else if(generalUtils.getQueryVariable('layout')=="retro-activate"){
      companySlug = "retroactivate"
    }
    else if(generalUtils.getQueryVariable('layout')=="inapp"){
      companySlug = "emp-retro"
      window.ReactNativeWebView = true;
    }
    else{
      // localhost
      companySlug = "stripe-retro"
    }

    return (
      <BrowserRouter>
        <Switch>
            {
              ROUTES.map(({component: Component, ...route}, i) => {
                return (
                  <Route
                    key={i}
                    {...route}
                    exact
                    render={props => (
                      <Component
                        {...props}
                        companySlug={companySlug}
                      />
                    )}
                  />
                );
            })}
          <Redirect to="/" />
        </Switch>
      </BrowserRouter>
    )
  }
}
