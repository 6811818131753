import React from 'react'

import { IbanElement } from '@stripe/react-stripe-js';

const IBAN_STYLE = {
  base: {
    marginTop: '20px',
    fontSize:'13px',
    color: '#fff',
    fontFamily:'Poppins, Helvetica, "sans-serif"',
    '::placeholder': {
      color: '#B5B5C3',
      textTransform: 'capitalize',
      fontSize:'13px',
      fontFamily:'Poppins, Helvetica, "sans-serif"'
    },
    ':-webkit-autofill': {
      color: '#fff',
    },
  },
  invalid: {
    color: '#fa6160',
  }
};

const IBAN_ELEMENT_OPTIONS = {
  supportedCountries: ['SEPA'],
  placeholderCountry: 'IE',
  style: IBAN_STYLE,
  hideIcon: true,
};

export default class Sepa extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      loading: false,
      company: props.company,
      customer: props.customer,
      paymentMethod: props.paymentMethod
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  collectDetails(){
    let {
      paymentMethod
    } = this.state
    return this._getPaymentMethod()
    .then(stripePaymentMethod => {
      let paymentDetails = {
        payment_method_id: stripePaymentMethod.id,
        browser: {
          user_agent: window.navigator.userAgent,
        }
      }
      return {paymentMethod, paymentDetails}
    })
  }

  async _getPaymentMethod(){
    const customer = this.props.customer

    if(!customer.first_name || !customer.last_name){
      throw { message: "Please specify the account holder's full name" }
    }
    else if(!customer.email || !customer.last_name){
      throw { message: "Please specify the account holders email" }
    }

    const iban = this.props.elements.getElement(IbanElement);

    return this.props.stripe.createPaymentMethod({
      type: 'sepa_debit',
      sepa_debit: iban,
      billing_details: {
        name: customer.first_name + " " + customer.last_name,
        email: customer.email
      },
    })
    .then(({paymentMethod, error}) => {
      if(paymentMethod == null){
        throw { message: "Please check your IBAN and try again" }
      }
      return paymentMethod
    });
  }

  render(){
    let options = IBAN_ELEMENT_OPTIONS

    let layout = this.state.company.settings.layout

    if(layout === "classic"){
      options.style.base.color='#464E5F';
      options.style.base["::placeholder"].color='#B5B5C3';
      options.style.base[":-webkit-autofill"].color='#464E5F';
    }

    if(layout === "retro" || layout === "dashboard"){
      options.style.base.fontSize='12px';
      options.style.base.color='#464E5F';
      options.style.base["::placeholder"].fontSize='12px';
      options.style.base[":-webkit-autofill"].color='#464E5F';
    }

    let name = this.state.company.association?.name || "splink"

    return (
      <>
        <div className="form-group text-left">

          <div className="input-group with-append">

            <IbanElement
              stripe={this.props.stripe}
              options={options}
              className="form-control h-auto border-0"
            />

            <div className="input-group-append input-group-pill">
                <span className="input-group-text input-group-payment-form">
                  <span className="svg-icon svg-icon-white svg-icon-2x">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
										    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
										        <mask fill="white">
										            <use xlinkHref="#path-1"/>
										        </mask>
										        <g/>
										        <path d="M7,10 L7,8 C7,5.23857625 9.23857625,3 12,3 C14.7614237,3 17,5.23857625 17,8 L17,10 L18,10 C19.1045695,10 20,10.8954305 20,12 L20,18 C20,19.1045695 19.1045695,20 18,20 L6,20 C4.8954305,20 4,19.1045695 4,18 L4,12 C4,10.8954305 4.8954305,10 6,10 L7,10 Z M12,5 C10.3431458,5 9,6.34314575 9,8 L9,10 L15,10 L15,8 C15,6.34314575 13.6568542,5 12,5 Z" fill="#000000"/>
										    </g>
                    </svg>
                  </span>
                </span>
              </div>

          </div>

        </div>

        <div className="form-group text-left">
          <div className="mandate-acceptance">
            By providing your IBAN and confirming this payment,
            you are authorizing {name} and Stripe, our payment service provider,
            to send instructions to your bank to debit your account and
            your bank to debit your account in accordance with those instructions.
            You are entitled to a refund from your bank under the
            terms and conditions of your agreement with your bank.
            A refund must be claimed within 8 weeks starting from
            the date on which your account was debited.
          </div>
        </div>

      </>
    )
  }
}

Sepa.defaultProps = {
}
