import React from 'react';

import PageLayout from "./components/PageLayout";
import LoadingPage from "./components/LoadingPage";

import Select from "./components/common/Select";

import Backend from "../utils/Backend";
import Splink from "../utils/Splink";
import General from "../utils/General";

export default class PaymentLink extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      paymentLinks: null
    }
  }

  componentDidMount() {
    let companySlug = this.props.companySlug
    Backend.getPaymentLinks(companySlug)
    .then(({ company, payment_links }) => {
      if(payment_links.length === 0){
        this.props.history.push(`/`)
      }
      this.setState({ company, paymentLinks: payment_links })
    }).catch(error => {
      General.splinkNotFound(companySlug)
    })
  }

  render() {
    let {
      company,
      paymentLinks,
      selectedPaymentLink
    } = this.state

    if(!company || !paymentLinks){
      return <LoadingPage/>
    }

    return (
      <PageLayout company={company}>

        <p>Please choose a channel from the list below:</p>

        <div className="form-group text-center mt-10">
          <div className="input-group">
            <Select
              options={paymentLinks}
              placeholder="Select A Channel"
              getOptionLabel={paymentLink => paymentLink.title}
              getOptionValue={paymentLink => paymentLink.id}
              className="form-control h-auto border-0 c-selectbox"
              onSelected={paymentLink => {
                this.setState({ selectedPaymentLink: paymentLink })
              }}
            />
          </div>
        </div>

        { selectedPaymentLink &&
          <div className="form-group text-center mt-10">
            <button
              className="btn btn-primary btn-block no-icon"
              onClick={() => this.props.history.push(Splink.getPaymentLinkSlug(company, selectedPaymentLink))}
            >
              <span className="btn__loader">
                Go
              </span>
            </button>
          </div>
        }

      </PageLayout>
    )
  }

}
