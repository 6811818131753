import React from 'react'

import { usePlaidLink } from 'react-plaid-link';

import BankPayButton from "../button/BankPayButton"

import Backend from "../../../../utils/Backend"


export default class ACH extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      loading: false,
      company: props.company,
      customer: props.customer,
      paymentPlan: props.paymentPlan,
      paymentMethod: props.paymentMethod
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  collectDetails(){
    let {
      paymentMethod
    } = this.state

    return new Promise((resolve, reject) => {
      Backend.getPlaidLinkToken(paymentMethod)
      .then(response => {
        let linkHandler = window.Plaid.create({
          token: response.link_token,
          onSuccess: (public_token, metadata) => {
            resolve({
              paymentMethod,
              paymentDetails: {
                plaid_public_token: public_token,
                plaid_account_id: metadata.accounts[0].id
              }
            })
          },
          onExit: (err, metadata) => {
            reject(err)
          },
        });
        linkHandler.open()
      })
    })
  }

  render(){
    let name = this.state.company.association?.name || "splink"

    return (
      <>
        <BankPayButton
          primaryColor={this.state.company.settings.primary_color}
          paymentPlan={this.state.paymentPlan}
          paymentMethod={this.state.paymentMethod}
          title="Pay By ACH"
          onClick={e => {
            this.props.onPayPressed(this.props.paymentMethod)
          }}
        />
      </>
    )
  }
}

ACH.defaultProps = {
}
