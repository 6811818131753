import React, { Component } from 'react';

import { Helmet } from "react-helmet";

import Color from '../../../utils/Color'

export default class Branding extends Component  {

  render(){

    let primaryColor = window.General.Branding.Colors.primary
    let textOnPrimaryColor = window.General.Branding.Colors.text_on_primary
    let primaryColorDarkened = Color.brightness(primaryColor, -20)
    let primaryColorLightened = Color.alpha(primaryColor, 0.1)

    let takeColor = window.General.Branding.Colors.take
    let takeColorLightened = Color.alpha(takeColor, 0.1)

    let requestColor = window.General.Branding.Colors.request
    let requestColorLightened = Color.alpha(requestColor, 0.1)

    let shopColor = window.General.Branding.Colors.shop
    let shopColorLightened = Color.alpha(shopColor, 0.1)

    let recurringColor = window.General.Branding.Colors.recurring
    let recurringColorLightened = Color.alpha(recurringColor, 0.1)

    return (
      <>
        <Helmet>
          <title>{ `${window.General.Branding.Name}  | ${this.props.name}` }</title>
          <link rel="icon" href={ window.General.Branding.Favicon }/>
          <meta name="description" content={ `${window.General.Branding.Name} | ${this.props.name}` }/>
        </Helmet>
        <style dangerouslySetInnerHTML={{__html: `
          .login-aside {
            background-color: ` + primaryColor + ` !important;
          }
          .text-primary,
          .metr_v702 .text-primary,
          .text-hover-primary:hover,
          a.text-hover-primary:hover {
            color: ` + primaryColor + ` !important;
          }
          a.text-primary:hover,
          a.text-primary:focus,
          .metr_v702 a.text-hover-primary:hover {
            color: ` + primaryColorDarkened + ` !important;
          }
          .btn.btn-primary:not(.btn-paynow),
          .switch input:checked ~ span:after {
            color: ` + textOnPrimaryColor + ` !important;
            border-color: ` + primaryColor + ` !important;
            background-color: ` + primaryColor + ` !important;
          }
          .btn.btn-primary:not(.btn-paynow):hover {
            border-color: ` + primaryColorDarkened + ` !important;
            background-color: ` + primaryColorDarkened + ` !important;
          }
          .btn.btn-light,
          .btn.btn-light-primary {
            color: ` + primaryColor + ` !important;
            border-color: transparent !important;
            background-color: ` + primaryColorLightened + ` !important;
          }
          .btn.btn-outline-primary {
            color: ` + primaryColor + ` !important;
            border-color: ` + primaryColor + ` !important;
            background-color: transparent !important;
          }
          .btn.btn-outline-primary:hover {
            color: ` + textOnPrimaryColor + ` !important;
            border-color: ` + primaryColor + ` !important;
            background-color: ` + primaryColor + ` !important;
          }
          .flatpickr-day.selected,
          .Table__page .select-page:hover,
          .btn.btn-light:hover,
          .btn.btn-light-primary:hover,
          .btn.btn-hover-primary:hover:not(.btn-text):not(:disabled):not(.disabled),
          .btn.btn-hover-primary:focus:not(.btn-text),
          .btn.btn-hover-primary.focus:not(.btn-text) {
            color: ` + textOnPrimaryColor + ` !important;
            border-color: transparent !important;
            background-color: ` + primaryColor + ` !important;
          }
          .bg-primary,
          .Table__pagination .switchPageButton:hover,
          .Table__pageButton:hover,
          .Table__pageButton--active,
          .MuiLinearProgress-barColorPrimary,
          .Toastify__toast--success,
          .checkbox > input:checked ~ span,
          .radio > input:checked ~ span {
            background-color: ` + primaryColor + ` !important;
          }
          .-sort-asc .rt-resizer::before,
          .-sort-desc .rt-resizer::after,
          .rt-thead .-sort-asc .rt-resizable-header-content,
          .rt-thead .-sort-desc .rt-resizable-header-content,
          .aside-menu .menu-nav > .menu-item.menu-item-active > .menu-heading .menu-text,
          .aside-menu .menu-nav > .menu-item.menu-item-active > .menu-link .menu-text,
          .aside-menu .menu-nav > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover > .menu-heading .menu-text,
          .aside-menu .menu-nav > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover > .menu-link .menu-text,
          .aside-menu .menu-nav > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover > .menu-heading .menu-icon,
          .aside-menu .menu-nav > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover > .menu-link .menu-icon,
          .aside-menu .menu-nav > .menu-item.menu-item-active > .menu-heading .menu-icon,
          .aside-menu .menu-nav > .menu-item.menu-item-active > .menu-link .menu-icon {
            color: ` + primaryColor + ` !important;
          }
          .aside-menu .menu-nav > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover > .menu-heading .menu-icon.svg-icon svg g [fill],
          .aside-menu .menu-nav > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover > .menu-link .menu-icon.svg-icon svg g [fill],
          .aside-menu .menu-nav > .menu-item.menu-item-active > .menu-heading .menu-icon.svg-icon svg g [fill], .aside-menu .menu-nav > .menu-item.menu-item-active > .menu-link .menu-icon.svg-icon svg g [fill],
          .brand .btn:hover .svg-icon svg g [fill] {
            fill: ` + primaryColor + ` !important;
          }
          .btn-light-primary .svg-icon svg g [fill],
          .wizard.wizard-2 .wizard-nav .wizard-steps .wizard-step[data-wizard-state="current"] .wizard-icon .svg-icon svg g [fill] {
            fill: ` + primaryColor + ` !important;
          }
          .btn-light-primary:hover .svg-icon svg g [fill] {
            fill: ` + textOnPrimaryColor + ` !important;
          }
          .label.label-light-info,
          .symbol.symbol-light-info .symbol-label {
            color: ` + takeColor + ` !important;
            background-color: ` + takeColorLightened + ` !important;
          }
          .svg-icon.svg-icon-info svg g [fill] {
            fill: ` + takeColor + ` !important;
          }
          .label.label-light-danger,
          .symbol.symbol-light-danger .symbol-label {
            color: ` +  requestColor + ` !important;
            background-color: ` + requestColorLightened + ` !important;
          }
          .svg-icon.svg-icon-danger svg g [fill] {
            fill: ` + requestColor + ` !important;
          }
          .label.label-light-success,
          .symbol.symbol-light-success .symbol-label {
            color: ` + shopColor + ` !important;
            background-color: ` + shopColorLightened + ` !important;
          }
          .svg-icon.svg-icon-success svg g [fill] {
            fill: ` + shopColor + ` !important;
          }
          .label.label-light-primary,
          .symbol.symbol-light-primary .symbol-label {
            color: ` + recurringColor + ` !important;
            background-color: ` + recurringColorLightened + ` !important;
          }
          .svg-icon.svg-icon-primary svg g [fill] {
            fill: ` + recurringColor + ` !important;
          }
          .ReactTable .rt-expander:after {
            border-top: 7px solid ` + primaryColor + ` !important;
          }
          .select-page {
            color: ` + Color.alpha(primaryColor, 0.5) + `;
            background: url(/static/media/chevron-down.7d30c44b.svg) no-repeat right ` + primaryColorLightened + `;
          }
          .form-control:focus {
            border-color: ` + Color.alpha(primaryColor, 0.5) + `;
          }
          .MuiLinearProgress-colorPrimary {
            background-color: ` + Color.alpha(primaryColor, 0.5) + `;
          }
          .offcanvas .offcanvas-content .offcanvas-demo.offcanvas-demo-active, .offcanvas-mobile .offcanvas-content .offcanvas-demo.offcanvas-demo-active {
            border: 5px solid ` + primaryColor + ` !important;
          }
          .btn.btn-primary.btn-shadow {
              box-shadow: 0px 9px 16px 0px ` + Color.alpha(primaryColor, 0.5) + ` !important;
          }
          .switch.switch-outline.switch-primary input:checked ~ span:before {
              border: 2px solid ` + primaryColor + ` !important;
              background-color: transparent;
          }
          .accordion .card .card-header .card-title,
          .brand-text-color {
            color: ` + primaryColor + `;
          }
          .custom-file-input:focus ~ .custom-file-label,
          .brand-border-color  {
              border-color: ` + primaryColor + ` !important;
          }
            .react-tooltip.place-bottom::after {
              border-bottom-color: ` + primaryColor + ` !important;
            }
         `}}
         />
       </>
    )
  }


}
