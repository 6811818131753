import $ from "jquery"

// Console warning shown to prevent people tricking them into performing self-XSS attacks on themselves
console.log("%cStop!", "font-family:helvetica;font-size:50px; font-weight:bold; color:red; -webkit-text-stroke:1px black;");
console.log("%cDo not use this browser feature under any circumstances. Please contact support if you require assistance.", "font-family:helvetica;font-size:20px;");

// Fixed Header in Shop 2.0
$(window).scroll(function(){
    if ($(this).scrollTop() > 125) {
       $('.fixed-header').addClass('show');
    } else {
       $('.fixed-header').removeClass('show');
    }
});