import React from 'react';

import PaymentForm from "./components/payment/Form";
import PaymentAttempt from "./components/payment/PaymentAttempt";

import PageLayout from "./components/PageLayout";
import LoadingPage from "./components/LoadingPage";

import Backend from "../utils/Backend";
import General from "../utils/General";

export default class Shop extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      shop: null
    }
  }

  componentDidMount() {
    let companySlug = this.props.companySlug
    let productSlug =  this.props.match.params.productSlug
    let questions = []
    let product = null

    let savedState = PaymentAttempt.getState()

    Backend.getShop(companySlug, savedState?.paymentAttemptId)
    .then(({ company, products, payment_attempt_id}) => {

      if(!company.settings.shop_show_out_of_stock){
        products = products.filter(product => product.stock != 0)
      }

      if(products.length === 0){
        this.props.history.push(`/`)
        return
      }
      let paymentPlans = products[0].payment_plans
      let paymentPlan = paymentPlans[0]
      let collectAddress = false

      product = products.find(product => product.slug === productSlug)

      if(product){
        questions = product.questions
        paymentPlans = product.payment_plans
        paymentPlan = paymentPlans[0]
        collectAddress = product.collect_address
        paymentPlan.product_payment_plans = [paymentPlan.id]
        paymentPlan.selected_products = [{
          ...General.clone(product),
          quantity: 1,
          questions
        }]
      }
      this.setState({
        company,
        product,
        products,
        questions,
        savedState,
        paymentPlan,
        paymentPlans,
        collectAddress,
        paymentAttemptId: payment_attempt_id
      })
    }).catch(error => {
      General.splinkNotFound(companySlug)
    })
  }

  render() {
    let {
      company,
      product,
      products,
      questions,
      savedState,
      paymentPlan,
      paymentPlans,
      collectAddress,
      paymentAttemptId
    } = this.state

    if(!company){
      return <LoadingPage/>
    }

    let shopVersion = "v1"
    let isShopV2 = false;

    if(company.settings.shop_layout === "standard"){
      shopVersion = "v2"
      isShopV2 = true
    }

    return (
      <PageLayout company={company} containerClassName={`page shop ${shopVersion} fixed-header`}>

        <PaymentForm
          savedState={savedState}
          company={company}
          products={products}
          selectedProduct={product}
          questions={questions}
          paymentPlan={paymentPlan}
          paymentPlans={paymentPlans}
          paymentAttemptId={paymentAttemptId}
          collectEmail={company.settings.collect_email}
          collectAddress={collectAddress}
          showProductGallery={isShopV2}
        />

      </PageLayout>
    )
  }

}
