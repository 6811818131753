import React from 'react';

import moment from "moment"

import PaymentPlanSelect from "./PaymentPlanSelect"

import Carousel from 'react-bootstrap/Carousel'

import Questions from "./Questions"
import PaymentMethodsContainer from "./PaymentMethodsContainer"

import Select from "../common/Select";
import Currency from "../../../utils/Currency";
import General from "../../../utils/General";
import Notify from "../../../utils/Notify"

import ErrorModal from "../modal/ErrorModal";

import $ from "jquery"

import { TAX } from '../../../constants/Variables';

export default class ProductGallery extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      ...this._getState(props),
      mode: "new_to_old"
    }

    this.questions = React.createRef()
}

    componentWillReceiveProps(nextProps){
      this.setState({
        ...this._getState(nextProps),
        mode: this.state.mode,
        questions: this.state.questions,
        showCartPanel: this.state.showCartPanel,
        selectedProduct: this.state.selectedProduct,
      })
    }

    _getState(props){
      let paymentPlan = JSON.parse(JSON.stringify(props.paymentPlan))

      return {
        paymentPlan,
        quantity: props.quantity || 1,
        products: this._order(props.products, this.state?.mode),
        showCartPanel: props.showCartPanel,
        selectedProduct: props.selectedProduct || null,
      }
    }

    _order(products, mode="new_to_old"){

      switch(mode){
        case "old_to_new":
          return products.sort(function(a,b){
            return moment(a.created_at).valueOf() - moment(b.created_at).valueOf();
          });
        case "new_to_old":
          return products.sort(function(a,b){
            return moment(b.created_at).valueOf() - moment(a.created_at).valueOf();
          });
        case "price_high_to_low":
          return products.sort(function(a,b){
            return b.payment_plans[0].total - a.payment_plans[0].total;
          });
        case "price_low_to_high":
          return products.sort(function(a,b){
            return a.payment_plans[0].total - b.payment_plans[0].total;
          });
        default:
          return products
      }
    }

    _hideProductDetails = () => {
        this.setState({
          selectedProduct: null
        });
        // Prevent Page Scrolling When a Product Details is Open
        if(document.body.offsetWidth < 768){
          $("body").removeClass( "m-qv-open" );
          // const body = document.body;
          // const scrollY = body.style.top;
          // body.style.position = '';
          // body.style.top = '';
          // window.scrollTo(0, parseInt(scrollY || '0') * -1);
        }
    };

    _decreaseQuantity(){
        this.setState({ quantity: this.state.quantity - 1 });
    }

    _increaseQuantity(){
        this.setState({ quantity: this.state.quantity + 1 });
    }

    _addProduct(){
      if(!this.questions.current.isValid(true)){
        return
      }
      let {
        quantity,
        questions,
        paymentPlan,
        selectedProduct,
      } = this.state

      let {
        selected_products
      } = paymentPlan

      let error = null

      if(!selected_products || selected_products.length === 0){
        paymentPlan = General.preparePaymentPlan(selectedProduct.payment_plans[0], true)
      }
      else{
        let selectedProductPaymentPlan = selectedProduct.payment_plans[0]

        if(selectedProductPaymentPlan.currency.code !== paymentPlan.currency.code){
          let currencyName = paymentPlan.currency.name
          error = `As you've added a product that's billed in ${currencyName}, all subsequent products must also be billed in ${currencyName}`
        }
        else if(selectedProductPaymentPlan.frequency !== paymentPlan.frequency){
          let frequency = paymentPlan.frequency || "once off"
          error = `As you've added a product that's billed ${frequency}, all subsequent products must be billed ${frequency}`
        }
        else if(selectedProductPaymentPlan.installments !== paymentPlan.installments){
          let installments = paymentPlan.installments || "no"
          error = `As you've added a product that's billed with ${installments} installment(s), all subsequent products must be billed ${installments} with ${installments} installment(s)`
        }
      }

      if(quantity < 1){
        error = "Invalid product quantity, must be 1 or more"
      }

      if(error){
        this.setState({
          showErrorModal: true,
          error: { message: error }
        })
        return
      }else{
        Notify.success("Added to Cart")
      }

      selected_products.push({
        ...this.state.selectedProduct,
        quantity,
        questions
      })

      paymentPlan.selected_products = selected_products
      this._updatePaymentPlan(paymentPlan)
    }

    _updatePaymentPlan(paymentPlan){
      let subtotal = paymentPlan.selected_products.reduce((total, current) => {
        return total + (current.payment_plans[0].subtotal * current.quantity)
      }, 0)

      let vat = paymentPlan.selected_products.reduce((total, current) => {
        if(!current.payment_plans[0].vat){
          return total
        }
        return total + (current.payment_plans[0].vat * current.quantity)
      }, 0)

      paymentPlan.subtotal = subtotal
      paymentPlan.vat = vat
      paymentPlan.total = subtotal + vat

      let questions = []
      paymentPlan.product_payment_plans = []
      let group = 0
      for(var i=0; i < paymentPlan.selected_products.length; i++){
        let product = paymentPlan.selected_products[i]
        for(var j=0; j < product.quantity; j++){
          paymentPlan.product_payment_plans.push(product.payment_plans[0].id)
          for(var k=0; k < product.questions.length; k++){
            let question = General.clone(product.questions[k])
            if(question.answer){
              question.answer.group = group
            }
            questions.push(question)
          }
          group += 1
        }
      }

      let collectAddress = paymentPlan.selected_products.find(product => product.collect_address == true) != null

      this.props.onUpdated(paymentPlan, questions, collectAddress)
    }

    _showCartPanel(){

      if (window.pageYOffset > 225) {
        $('html, body').animate({
            scrollTop: 126
        }, 'slow');
        window.setTimeout(() => {
            this.setState({
                showCartPanel: true
            });
        }, 900);
      } else {
          this.setState({
              showCartPanel: true
          });
      }

    };

    _hideCartPanel(){
        this.setState({ showCartPanel: false });
    };


    render() {
      let {
        products,
        selectedProduct,
        paymentPlan,
        error,
        mode,
        showErrorModal
      } = this.state

      let {
        inApp,
        paymentMethods,
        nfcPrioritised,
        supportsNonCard,
        showAlternativePaymentMethods
      } = this.props

      const { activeProduct, showCartPanel } = this.state;
      const filterOptions = [
          { value: 'new_to_old', label: 'Newest to Oldest' },
          { value: 'old_to_new', label: 'Oldest to Newest' },
          { value: 'price_high_to_low', label: 'Price High to Low' },
          { value: 'price_low_to_high', label: 'Price Low to High' }
      ]

      let collectAddress = this.props.collectAddress

      return (
        <>
          <div className="pdp">


              {/* Starts : Filter */}
              <div className="filter mb-2 form-group">
                  <div className="icon">
                      <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path className='stroke' d="M26.4286 8.57141H10.7143" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                          <path className='stroke' d="M7.85716 8.57141H3.57144" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                          <path className='stroke' d="M26.4286 22.8572H10.7143" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                          <path className='stroke' d="M7.85716 22.8572H3.57144" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                          <path className='stroke' d="M19.2857 15.7143H3.57144" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                          <path className='stroke' d="M26.4286 15.7143H22.1429" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                          <path className='stroke2' fill-rule="evenodd" clip-rule="evenodd" d="M9.28572 5.71429C9.6646 5.71429 10.028 5.8648 10.2959 6.13271C10.5638 6.40062 10.7143 6.76399 10.7143 7.14287V10C10.7143 10.3789 10.5638 10.7423 10.2959 11.0102C10.028 11.2781 9.6646 11.4286 9.28572 11.4286C8.90684 11.4286 8.54348 11.2781 8.27557 11.0102C8.00766 10.7423 7.85715 10.3789 7.85715 10V7.14287C7.85715 6.76399 8.00766 6.40062 8.27557 6.13271C8.54348 5.8648 8.90684 5.71429 9.28572 5.71429Z" stroke-linecap="round" stroke-linejoin="round" />
                          <path className='stroke2' fill-rule="evenodd" clip-rule="evenodd" d="M9.28572 20C9.6646 20 10.028 20.1505 10.2959 20.4184C10.5638 20.6863 10.7143 21.0497 10.7143 21.4286V24.2857C10.7143 24.6646 10.5638 25.028 10.2959 25.2959C10.028 25.5638 9.6646 25.7143 9.28572 25.7143C8.90684 25.7143 8.54348 25.5638 8.27557 25.2959C8.00766 25.028 7.85715 24.6646 7.85715 24.2857V21.4286C7.85715 21.0497 8.00766 20.6863 8.27557 20.4184C8.54348 20.1505 8.90684 20 9.28572 20Z" stroke-linecap="round" stroke-linejoin="round" />
                          <path className='stroke2' fill-rule="evenodd" clip-rule="evenodd" d="M20.7143 12.8572C21.0932 12.8572 21.4566 13.0077 21.7245 13.2756C21.9924 13.5435 22.1429 13.9069 22.1429 14.2857V17.1429C22.1429 17.5218 21.9924 17.8851 21.7245 18.153C21.4566 18.421 21.0932 18.5715 20.7143 18.5715C20.3354 18.5715 19.9721 18.421 19.7042 18.153C19.4362 17.8851 19.2857 17.5218 19.2857 17.1429V14.2857C19.2857 13.9069 19.4362 13.5435 19.7042 13.2756C19.9721 13.0077 20.3354 12.8572 20.7143 12.8572Z" stroke-linecap="round" stroke-linejoin="round" />
                      </svg>
                  </div>
                  <div className="input-group">
                      <Select
                          value={mode}
                          options={filterOptions}
                          getOptionLabel={option => option.label}
                          getOptionValue={option => option.value}
                          placeholder="Price (high to low)"
                          className="form-control h-auto border-0 c-selectbox"
                          onSelected={option => {
                            console.log("ddddd", option)
                            products = this._order(products, option.value)
                            this.setState({
                              products,
                              mode: option.value
                            })
                          }}
                      />
                  </div>
              </div>
              {/* End : Filter */}

              {/* Starts : Button Cart */}
              <button
                  className="btn-cart mb-7"
                  onClick={() => this._showCartPanel()}
              >
                  <span className="row no-gutters align-items-center">
                      <span className="col-auto icon">
                          <svg width="40" height="23" viewBox="0 0 40 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <circle cx="19.4092" cy="5.09624" r="4.09624" fill="#FF7676" />
                              <rect x="25.0947" y="1.00012" width="8.27619" height="7.8164" transform="rotate(19.5383 25.0947 1.00012)" fill="#FFB931" />
                              <path className='fill' d="M35.4707 4.70685L10.0503 4.70685L12.0642 12.4324C12.6633 14.7307 14.7388 16.3345 17.1139 16.3345H31.068C33.1772 16.3345 35.079 15.0648 35.8877 13.1168L37.8805 8.31649C38.5938 6.59824 37.3311 4.70685 35.4707 4.70685Z" fill="#83BEFF" />
                              <path d="M19.5596 7.36493L16.4395 12.2257" stroke="#DEEEFF" stroke-width="2.60923" stroke-linecap="round" />
                              <path d="M25.7046 7.36469L22.5845 12.2255" stroke="#DEEEFF" stroke-width="2.60923" stroke-linecap="round" />
                              <path d="M31.8486 7.36481L28.7286 12.2256" stroke="#DEEEFF" stroke-width="2.60923" stroke-linecap="round" />
                              <path d="M2 2.02368H5.84569C7.27064 2.02368 8.53065 2.94874 8.95748 4.30827L12.0159 14.05C12.4428 15.4095 13.7028 16.3346 15.1277 16.3346H34.1995" stroke="#D7D7D7" stroke-width="3.91385" stroke-linecap="round" />
                              <circle cx="16.3097" cy="19.4654" r="2.15205" fill="#C4C4C4" stroke="#575757" stroke-width="1.95692" />
                              <circle cx="31.069" cy="19.4652" r="2.15205" fill="#C4C4C4" stroke="#575757" stroke-width="1.95692" />
                          </svg>
                      </span>
                      <span className="col amount">{Currency.format(paymentPlan.currency.symbol, paymentPlan.total)}</span>
                      <span className="col-auto count"><span>{paymentPlan.selected_products.length}</span></span>
                  </span>
              </button>
              {/* End : Your Cart */}


              {/* Starts : Show Cart Panel */}
              {this.state.showCartPanel &&

                  <div className="your-cart">

                      <div className="row">
                          {/* Starts : column left */}
                          <div className="col-md-6 col-left">

                              <div className="details">

                                  <h4>Your Cart</h4>

                                  {/* Start : products added to the cart */}
                                  <div className="items">
                                    { paymentPlan.selected_products.map((product, index) => {
                                        return this._renderProductitem(product, index)
                                      })
                                    }
                                  </div>
                                  {/* End : products added to the cart */}

                                  {/* Start : Price table */}
                                  <table className="w-100  mt-auto">
                                      <tr>
                                          <td>Subtotal</td>
                                          <td align="right">{Currency.format(paymentPlan.currency.symbol, paymentPlan.subtotal)}</td>
                                      </tr>
                                      {/*
                                      <tr>
                                          <td>Delivery Fee</td>
                                          <td align="right">€5.00</td>
                                      </tr>
                                      */}
                                      <tr>
                                          <td>{TAX}</td>
                                          <td align="right">{Currency.format(paymentPlan.currency.symbol, paymentPlan.vat)}</td>
                                      </tr>
                                      <tr className="total">
                                          <td >Total</td>
                                          <td align="right">{Currency.format(paymentPlan.currency.symbol, paymentPlan.total)}</td>
                                      </tr>
                                  </table>
                                  {/* End : Price table */}


                              </div>

                          </div>
                          {/* End : column left */}
                          {/* Starts : column right */}
                          <div className="col-md-6 col-right mt-7 mt-md-0">

                              <div className="details">

                                  { collectAddress &&
                                    <div className="delivery">
                                        <h4>Delivery Details</h4>
                                        { this.props.renderAddress() }
                                    </div>
                                  }

                                  <div className="payment">
                                      <h4>Payment Details</h4>
                                      { this.props.renderPaymentPlan() }
                                      { this.props.renderPayment() }
                                  </div>

                                  <PaymentMethodsContainer
                                    inApp={inApp}
                                    paymentMethods={paymentMethods}
                                    nfcPrioritised={nfcPrioritised}
                                    supportsNonCard={supportsNonCard}
                                    showAlternativePaymentMethods={showAlternativePaymentMethods}
                                  />

                              </div>

                          </div>
                          {/* End : column right */}
                      </div>

                      {/* start : btn close */}
                      <div className="btn-close">
                          <button
                              className="btn"
                              onClick={() => this._hideCartPanel()}
                          >
                              close
                          </button>
                      </div>
                      {/* end : btn close */}

                  </div>

              }
              {/* End : Show Cart Panel */}


              {/* Starts : Products */}
              <div className="products">
                  <div className="row">

                      {products.map((product, index) => {
                          let paymentPlan = product.payment_plans[0]
                          let total = paymentPlan.total
                          if(paymentPlan.reverse_fee === "optional"){
                            total -= (paymentPlan.reverse_fee_amount || 0)
                          }

                          let selected = false
                          let className = `product-${index}`
                          if(product.id === selectedProduct?.id){
                            className += " show-details"
                            selected = true
                            // Prevent Page Scrolling When a Product Details is Open
                            if(document.body.offsetWidth < 768){
                              $("body").addClass( "m-qv-open" );
                              // const scrollY = document.documentElement.style.getPropertyValue('--scroll-y');
                              // const body = document.body;
                              // body.style.position = 'fixed';
                              // body.style.top = `-${scrollY}`;
                            }
                          }

                          return (
                              <>
                                  {/* Starts : Product */}
                                  <div
                                      key={index}
                                      className={`product col-md-4 ${className}`}
                                  >
                                      {/* Starts : tile */}
                                      <a
                                          className="box"
                                          href="javascript:void(0)"
                                          onClick={() => {
                                            this.setState({
                                              selectedProduct: General.clone(product),
                                              questions: General.clone(product.questions)
                                            })

                                            {/* starts : show the full QV in the view port */}
                                            if(document.body.offsetWidth >= 768){
                                              window.setTimeout(() => {

                                                var closeButtonPosition, qVHeight, floatingHeaderHeight, scrollTo;
                                                closeButtonPosition  = $(".show-details .qv .btn-close").offset().top;
                                                qVHeight  = $(".show-details").outerHeight();
                                                floatingHeaderHeight = 40;
                                                scrollTo = closeButtonPosition - qVHeight - floatingHeaderHeight;

                                                  $('html, body').animate({
                                                    scrollTop: scrollTo
                                                  }, 800);

                                              }, 200);
                                            }
                                            {/* end : show the full QV in the view port */}

                                          }}

                                      >
                                          <span className="img">
                                              { product.featured_image ? (
                                                <span
                                                  data-name={product.name}
                                                  style ={{ backgroundImage: "url("+product.featured_image.thumbnail+")" }}
                                                />
                                              ) : (
                                                <div class="user">
                                                  <span class="product-letter overflow-hidden bg-secondary d-flex justify-content-center align-items-center"

                                                  >
                                                    { product.name[0] }
                                                  </span>
                                                </div>
                                              )}
                                              <button className="btn">View Product</button>
                                          </span>
                                          <span className="name">
                                              {product.name}
                                          </span>
                                          <span className="price">
                                              {Currency.format(paymentPlan.currency.symbol, total)}
                                          </span>

                                      </a>
                                      {/* End : tile */}

                                      {/* Starts : details */}
                                      <div className="details">
                                          {selected && this._renderProductDetails()}
                                      </div>
                                      {/* End : details */}

                                      {/* start : Hide Details */}
                                      <div
                                        className='m-close'
                                        onClick={() => this._hideProductDetails()}
                                      />
                                      {/* end : Hide Details */}

                                  </div>
                                  {/* Starts : Product */}

                              </>
                          );

                      })}

                  </div>
              </div>
              {/* End : Products */}

          </div>

          <ErrorModal
            show={showErrorModal}
            error={error}
            onClose={() => this.setState({ showErrorModal: false })}
          />
        </>
      )
    }

    _renderProductDetails() {

      let { selectedProduct } = this.state
      if(!selectedProduct){
        return
      }

      let paymentPlan = selectedProduct.payment_plans[0]
      paymentPlan._original = JSON.parse(JSON.stringify(paymentPlan))
      let total = paymentPlan.total
      if(paymentPlan.reverse_fee === "optional"){
        total -= (paymentPlan.reverse_fee_amount || 0)
      }

      if(selectedProduct.featured_image){
        selectedProduct.images.unshift(selectedProduct.featured_image)
      }

      let showCarouselArrows = "hide-arrows";
      if(selectedProduct.images?.length > 1){
        showCarouselArrows="show-arrows";
      }

      let inStock = selectedProduct.stock == null || selectedProduct.stock > 0
      return (
          <div className="qv">

                {/* Starts : top Traingle shape */}
                <div className="triangle">
                    <svg width="54" height="32" viewBox="0 0 54 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M27 0L53.8468 31.5H0.153212L27 0Z" fill="white" />
                    </svg>
                </div>
                {/* End : top Traingle shape */}

                <div className="row no-gutters justify-content-between">

                    {/* Starts : Carousel */}

                    <div className="col-md-4 text-center">
                      { selectedProduct?.images?.length === 0 &&
                        <div class="p-carousel hide-arrows">
                          <div class="carousel slide">
                            <div class="carousel-inner">
                              <div class="active carousel-item">
                                <span class="product-letter overflow-hidden bg-secondary d-flex justify-content-center align-items-center"

                                >
                                  { selectedProduct.name[0] }
                                </span>
                              </div>
                            </div>
                            <a class="carousel-control-prev" role="button" href="#">
                              <span aria-hidden="true" class="carousel-control-prev-icon"></span>
                              <span class="sr-only">Previous</span>
                            </a>
                            <a class="carousel-control-next" role="button" href="#">
                              <span aria-hidden="true" class="carousel-control-next-icon"></span>
                              <span class="sr-only">Next</span>
                            </a>
                          </div>
                        </div>
                      }
                      { selectedProduct?.images?.length > 0 &&
                          <div
                            className={`p-carousel ${showCarouselArrows}`}
                          >
                            <Carousel
                              indicators={false}
                            >
                              { selectedProduct.images.map(image => {
                                  return (
                                    <Carousel.Item>
                                      <span
                                        style ={{ backgroundImage: "url("+image.original+")" }}
                                      />
                                    </Carousel.Item>
                                  )
                                })
                              }
                            </Carousel>
                          </div>
                        }
                    </div>

                    {/* End : Carousel */}

                    {/* Starts : add to cart */}
                    <div className="col-md-7 col-right mt-7 mt-md-0 d-flex flex-column">
                        <div className="name">
                            { selectedProduct.name }
                        </div>
                        <div className="price">
                            {Currency.format(paymentPlan.currency.symbol, total)}
                        </div>

                        { paymentPlan._original.type === "recurring" &&
                          <PaymentPlanSelect
                            paymentPlans={[paymentPlan]}
                            paymentPlan={paymentPlan}
                            showSubscriptionMessage={false}
                            onUpdated={paymentPlan => {
                            // do nothing
                            }}
                          />
                        }
                        <div className="about mb-auto">
                            { selectedProduct.description}
                        </div>
                        <Questions
                          ref={this.questions}
                          questions={selectedProduct.questions}
                          onUpdated={questions => {
                            // to do update
                            this.setState({ questions })
                          }}
                        />
                        <div className="row">
                            <div className="col-md-auto">
                                <div className="quantity input-number with-buttons">
                                    <button
                                      className="minus"
                                      disabled={this.state.quantity === 1}
                                      onClick={() => this._decreaseQuantity()}
                                    >
                                        <span className="material-icons-outlined">remove</span>
                                    </button>
                                    <input type="number" value={this.state.quantity} />
                                    <button
                                      className="minus"
                                      onClick={() => this._increaseQuantity()}
                                    >
                                        <span class="material-icons-outlined">add</span>
                                    </button>
                                </div>
                            </div>
                            <div className="col-md mt-4 mt-md-0">
                                <button
                                  className={`btn ${inStock ? "btn-primary" : "btn-secondary"} btn-block no-icon`}
                                  disabled={!inStock}
                                  onClick={() => this._addProduct()}
                                >
                                  {inStock ? "Add To Cart" : "Out Of Stock"}
                                </button>
                            </div>
                        </div>

                    </div>
                    {/* end : add to cart */}

                </div>

                {/* start : Hide Details */}
                <div className="btn-close">
                    <button
                        className="btn"
                        onClick={() => this._hideProductDetails()}
                    >
                        <span className='text'>close</span>
                        <span className="icon material-icons-outlined">close</span>
                    </button>
                </div>
                {/* end : Hide Details */}

          </div>
      )

    }

    _renderProductitem(product, index) {
      let { paymentPlan } = this.state
      let productPaymentPlan = product.payment_plans[0]
      let total = productPaymentPlan.total
      if(productPaymentPlan.reverse_fee === "optional"){
        total -= (productPaymentPlan.reverse_fee_amount || 0)
      }

      let quantity = paymentPlan.selected_products[index].quantity
      return (
          <div className="item">
              <div className="row">

                  <div className="col-auto text-center">
                      <span className="img">
                        { product.featured_image ? (
                          <span
                            data-name={product.name}
                            style ={{ backgroundImage: "url("+product.featured_image.thumbnail+")" }}
                          />
                        ) : (
                          <div class="user">
                            <span class="h1 overflow-hidden bg-secondary d-flex justify-content-center align-items-center">
                              { product.name[0] }
                            </span>
                          </div>
                        )}
                      </span>
                  </div>

                  <div className="col">
                      <div className="name">
                          {product.name}
                      </div>
                      <div className="price">
                          {Currency.format(productPaymentPlan.currency.symbol, total)}
                      </div>
                      <div className="quantity v2 input-number with-buttons">
                          <button
                            className="minus"
                            disabled={quantity === -1}
                            onClick={() => {
                              quantity -= 1
                              paymentPlan.selected_products[index].quantity = quantity
                              if(quantity == 0){
                                paymentPlan.selected_products.splice(index, 1)
                              }
                              this._updatePaymentPlan(paymentPlan)
                            }}
                          >
                              <span class="material-icons-outlined">remove</span>
                          </button>
                          <input type="number" value={product.quantity} />
                          <button
                            className="minus"
                            onClick={() => {
                              paymentPlan.selected_products[index].quantity += 1
                              this._updatePaymentPlan(paymentPlan)
                            }}
                          >
                              <span class="material-icons-outlined">add</span>
                          </button>
                      </div>
                  </div>

              </div>
              <a
                  href="javascript:void(0)"
                  className="close"
                  onClick={() => {
                    paymentPlan.selected_products.splice(index, 1)
                    this._updatePaymentPlan(paymentPlan)
                  }}
              >
                  <span class="material-icons-two-tone">
                      close
                  </span>
              </a>
          </div>
      )

  }

}
