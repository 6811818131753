import React from 'react'

import Modal from 'react-bootstrap/Modal'

import LockInputAppendIcon from "../LockInputAppendIcon"

import CountrySelect from "../../common/CountrySelect"
import Select from "../../common/CustomSelect";

import General from "../../../../utils/General";
import Backend from "../../../../utils/Backend";
import Currency from "../../../../utils/Currency";
import Notify from "../../../../utils/Notify";
import Email from "../../../../utils/Email";
import Name from "../../../../utils/Name";

const ibantools = require('ibantools');

export default class BankSelectionModal extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      show: props.show,
      banks: [],
      authData: {},
      creditor: {},

      paymentMethod: null,
      loadedBanks: false,
      company: props.company,
      cusomer: props.customer,
      processor: props.processor,
      paymentPlan: props.paymentPlan,
      paymentAttemptId: props.paymentAttemptId
    }

  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  componentDidMount(){
    //this._load()
  }

  _load(){
    let { processor, country, company } = this.state

    this.setState({ banks: [], loadingBanks: true })
    Backend.getBanks(processor, country?.value, company)
    .then(banks => {
      this.setState({ banks, loadingBanks: false })
    })
  }

  _getCompatiblePaymentMethod(bank){
    let { paymentMethods } = this.state

    let supportedLocalInstruments = bank.supportedLocalInstruments.map(
      instrument => instrument.toLowerCase()
    )

    let { sepa, sepa_instant, faster_payments } = paymentMethods

    if(sepa_instant && supportedLocalInstruments.indexOf("sepa_instant") > -1){
      return sepa_instant
    }

    if(faster_payments && supportedLocalInstruments.indexOf("faster_payments") > -1){
      return faster_payments
    }

    if(sepa && supportedLocalInstruments.indexOf("sepa") > -1){
      return sepa
    }

    return null
  }


  _onSelected(bank){
    let initialEmbeddedAuthFields = []
    if(bank.initialEmbeddedAuthFields){

      initialEmbeddedAuthFields = bank.initialEmbeddedAuthFields.map(field => {
        return {
          ...field,
          answered: false
        }
      })
    }

    let paymentMethod = this._getCompatiblePaymentMethod(bank)
    let incompatibleBank = false
    let compatiblePaymentMethods = []

    if(!paymentMethod){
      incompatibleBank = true
      let paymentMethods = this.state.paymentMethods
      if(paymentMethods.sepa){
        compatiblePaymentMethods.push("SEPA")
      }
      else if(paymentMethods.sepa_instant){
        compatiblePaymentMethods.push("SEPA INSTANT")
      }
      else if(paymentMethods.faster_payments){
        compatiblePaymentMethods.push("FASTER PAYMENTS")
      }
    }

    this.setState({
      authData: {},
      paymentMethod,
      incompatibleBank,
      selectedBank: bank,
      confirmedPayment: false,
      compatiblePaymentMethods,
      initialEmbeddedAuthFields,
    })
  }

  _reset(){
    this.setState({
      authData: {},
      selectedBank: null,
      creditor: {},
      incompatibleBank: false,
      confirmedPayment: false,
      initialEmbeddedAuthFields: []
    })
  }

  _isSepa(paymentMethod){
    return paymentMethod?.type.indexOf("sepa") > -1
  }

  _isFasterPayments(paymentMethod){
    return paymentMethod?.type === "faster_payments"
  }

  _canContinue() {
    let {
      customer,
      creditor,
      selectedBank,
      paymentMethod,
      initialEmbeddedAuthFields,
    } = this.state;

    const initiationDebtor =
      selectedBank?.mandatoryFields?.find((field) => {
        let fieldPath = field.fieldPaths?.find(
          (fieldPath) => fieldPath === "initiation.debtor"
        );

        return fieldPath != null;
      }) != null;

    if (!customer.first_name && !customer.last_name) {
      return false;
    }

    if (!customer.email) {
      return false;
    }

    if (!initiationDebtor) {
      return true;
    }

    if (initialEmbeddedAuthFields.find((field) => !field.answered) != null) {
      return false;
    }

    if (!paymentMethod) {
      return false;
    }

    if (this._isSepa(paymentMethod)) {
      return creditor.iban != null;
    }

    if (this._isFasterPayments(paymentMethod)) {
      return creditor.account_number != null && creditor.sort_code != null;
    }

    return false;
  }

  _renderContent(){
    let {
      banks,
      country,
      selectedBank,
      loadingBanks,
      confirmedPayment,
      incompatibleBank,
      initialEmbeddedAuthFields
    } = this.state

    if(incompatibleBank){
      return this._renderIncompatibleBank()
    }

    if(selectedBank){
      if(!confirmedPayment){
        return this._renderConfirmationDetails()
      }

      return this._renderAuthContent()
    }

    return (
      <>
        <Modal.Header closeButton>
          <Modal.Title>Select Bank</Modal.Title>
        </Modal.Header>

        <Modal.Body>

          <CountrySelect
            className={"input-group"}
            onSelected={country => {
              this.setState({ country }, () => this._load())
            }}
          />

          { loadingBanks &&
            <p className="my-10 text-center">Loading...</p>
          }
          { (country && !loadingBanks && banks.length == 0) &&
            <>
              <p className="mt-10 text-center">No Banks Found.</p>
              <p className="mb-10 text-muted text-center">Please change your search criteria and try again.</p>
            </>
          }

          {
            banks.map(bank => {
              let className = "token-bank-account border rounded-lg bg-hover-primary"
              let textClassName = "font-weight-bold line-height-sm"
              if(bank.id == selectedBank?.id){
                className += " bg-primary"
                textClassName += " text-white"
              }

              return (
                <div className="mt-10">
                  <div className={className}>
                    <a onClick={() => this._onSelected(bank)}>
                      <div className="row p-2">

                        <div className="col-2 text-center">
                          <img
                            className={`token-bank-logo`}
                            src={bank.logoUri}
                          />
                        </div>

                        <div className="col my-auto">
                          <div className={textClassName}>
                            {bank.name}
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              )
            })
          }

        </Modal.Body>

        <Modal.Footer>

          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => this.props.onCancel()}
          >
            Cancel
          </button>

        </Modal.Footer>
      </>
    )
  }

  _renderConfirmationDetails(){
    let {
      company,
      paymentPlan,
      selectedBank,
      paymentAttemptId
    } = this.state

    let details = [
      {
        label: "Payment of",
        value: Currency.format(paymentPlan.currency.symbol, paymentPlan.total)
      },
      {
        label: "To",
        value: company.name
      },
      {
        label: "From",
        value: selectedBank.name
      },
      {
        label: "Reference",
        value: paymentAttemptId
      },
    ]
    return (
      <>
        <Modal.Header closeButton>
          <Modal.Title>Payment Confirmation</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>Please confirm the following secure payment details</p>

          <div className="border p-3 mb-5">
            {
              details.map(detail => {
                return (
                  <div className="row mb-5">
                    <div className="col-4">
                      {detail.label}:
                    </div>
                    <div className="col-8 text-right">
                      <b>{detail.value}</b>
                    </div>
                  </div>
                )
              })
            }
          </div>

          <p className="mt-3 w-100">
            By continuing, you agree to the {window.General.Branding.Name}'s{" "}
            <a target="_blank" href={window.General.Branding.TermsUrl}>
              Terms and conditions
            </a> and the Privacy Policy and Terms of {window.General.Branding.Name}'s bank connectivity provider,{" "}
            <a target="_blank" href="https://token.io/pis-terms-of-service">
              Token.io.
            </a>
          </p>


        </Modal.Body>

        <Modal.Footer>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => this._reset()}
          >
            Cancel
          </button>

          <button
            type="button"
            className="btn btn-primary"
            onClick={() => this.setState({ confirmedPayment: true })}
          >
            Confirm
          </button>
        </Modal.Footer>
      </>
    )
  }

  _renderAuthContent(){
    let {
      creditor,
      authData,
      customer,
      selectedBank,
      paymentMethod,
      initialEmbeddedAuthFields
    } = this.state


    const initiationDebtor =
      selectedBank?.mandatoryFields?.find((field) => {
        let fieldPath = field.fieldPaths?.find(
          (fieldPath) => fieldPath === "initiation.debtor"
        );

        return fieldPath != null;
      }) != null;


    let canContinue = this._canContinue()

    let customerFullName = customer.first_name
    if(customerFullName && customer.last_name){
      customerFullName += ` ${customer.last_name}`
    }
    return (
      <>
        <Modal.Header closeButton>
          <Modal.Title>Payment</Modal.Title>
        </Modal.Header>

        <Modal.Body>

          <div className="w-100 text-center">
            {selectedBank.logoUri &&
              <img
                className={`token-bank-logo`}
                src={selectedBank.logoUri}
              />
            }
            <h2>{selectedBank.name}</h2>
          </div>

          <p className="mt-8 mb-15 text-center">Enter the below details to initiate a secure payment</p>


          <div className="form-group row">
            <label className="col-3 col-form-label">
              Full Name
            </label>
            <div className="col-9">
              <input
                className="form-control form-control-solid"
                type={"text"}
                defaultValue={customerFullName}
                onChange={(e) => {
                  let fullName = e.target.value
                  customer.first_name = Name.getFirstName(fullName)
                  customer.last_name = Name.getLastName(fullName)
                  this.setState({ customer })
                }}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-3 col-form-label">
              Email
            </label>
            <div className="col-9">
              <input
                className="form-control form-control-solid"
                type={"text"}
                defaultValue={`${customer.email || ""}`}
                onChange={(e) => {
                  customer.email = e.target.value || null
                  this.setState({ customer })
                }}
              />
            </div>
          </div>

          { this._isSepa(paymentMethod) && initiationDebtor && (
            <div className="form-group row">
              <label className="col-3 col-form-label">
                IBAN
              </label>
              <div className="col-9">
                <input
                  className="form-control form-control-solid"
                  type={"text"}
                  onChange={(e) => {
                    creditor.iban = e.target.value || null
                    this.setState({ creditor })
                  }}
                />
              </div>
            </div>
          )}

          { this._isFasterPayments(paymentMethod) && initiationDebtor && (
            <>
              <div className="form-group row">
                <label className="col-3 col-form-label">
                  Account Number
                </label>
                <div className="col-9">
                  <input
                    className="form-control form-control-solid"
                    type={"text"}
                    onChange={(e) => {
                      creditor.account_number = e.target.value || null
                      this.setState({ creditor })
                    }}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-3 col-form-label">
                  Sort Code
                </label>
                <div className="col-9">
                  <input
                    className="form-control form-control-solid"
                    type={"text"}
                    onChange={(e) => {
                      creditor.sort_code = e.target.value || null
                      this.setState({ creditor })
                    }}
                  />
                </div>
              </div>
            </>
          )}

          {
            initialEmbeddedAuthFields.map(field => {
              let input = null
              if(field.type == "CHOICE_FIELD"){
                input = (
                  <Select
                    className="async-select-paginate form-control-solid"
                    classNamePrefix="async-select-paginate"
                    placeholder="Please Select An Option"
                    onChange={option => {
                      authData[field.id] = option.value
                      field.answered = true
                      this.setState({ authData, initialEmbeddedAuthFields })
                    }}
                    options={field.options.map(option => {
                        return {
                          label: option,
                          value: option
                        }
                      })
                    }
                  />
                )
              }else{
                input = (
                  <input
                    className="form-control form-control-solid"
                    type={(field.type || "text").toLowerCase()}
                    onChange={(e) => {
                      authData[field.id] = e.target.value || null
                      field.answered = authData[field.id] != null
                      this.setState({ authData, initialEmbeddedAuthFields })
                    }}
                  />
                )
              }
              return (
                <div className="form-group row">
                  <label className="col-3 col-form-label">
                    {General.toTitleCase(field.displayName)}
                  </label>
                  <div className="col-9">
                    { input }
                  </div>
                </div>
              )
            })
          }
        </Modal.Body>

        <Modal.Footer>

          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => this._reset()}
          >
            Back
          </button>

          <button
            type="button"
            className={`btn btn-primary`}
            disabled={!canContinue}
            onClick={() => {
              let cred = General.clone(creditor)
              if(cred.iban){
                cred.iban = cred.iban?.replaceAll(/\s/g,'')
                if(!ibantools.isValidIBAN(cred.iban)){
                  Notify.error("Please enter a valid iban")
                  return
                }
              }
              this.props.onSelected(
                selectedBank, cred, customer, authData, paymentMethod
              )
            }}
          >
            Continue
          </button>
        </Modal.Footer>
      </>
    )
  }

  _renderIncompatibleBank(){
    let {
      compatiblePaymentMethods
    } = this.state

    let label = "Ooops, the bank you selected is not compatible. Please select a bank that "
    if(compatiblePaymentMethods.length > 1){
      label += "supports one of the following:"
    }else{
      label += "supports the following:"
    }

    return (
      <>
        <Modal.Header closeButton>
          <Modal.Title>Incompatible Bank</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>{label}</p>
          <ul>
          { compatiblePaymentMethods.map(paymentMethod => {
              return (
                <li>{paymentMethod}</li>
              )
            })
          }
          </ul>

        </Modal.Body>

        <Modal.Footer>

          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => this.props.onCancel()}
          >
            Cancel
          </button>

          <button
            type="button"
            className={`btn btn-primary`}
            onClick={() => this._reset()}
          >
            Ok
          </button>
        </Modal.Footer>
      </>
    )
  }


  render(){
    let {
      show,
      banks,
      selectedBank,
      initialEmbeddedAuthFields
    } = this.state

    return (
      <Modal
        show={show}
        onHide={() => this.props.onCancel()}
        dialogClassName="w-70"
        className={'sub-modal'}
      >
        { this._renderContent() }
      </Modal>
    )
  }
}

BankSelectionModal.defaultProps = {
  modalClassName: '',
  advanced: true
}
