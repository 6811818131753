import React from "react";

import {
  isSafari,
  isChrome,
  isAndroid,
} from 'react-device-detect';


import { Portal } from 'react-portal';

import { PaymentInputsContainer } from 'react-payment-inputs';

import DigitalWalletButton from "./DigitalWalletButton"

import Customer from "../Customer"

import PaymentMethodPortal from '../PaymentMethodPortal';

import LockInputAppendIcon from "../LockInputAppendIcon"

import Separator from "../../common/Separator"

import PayButton from "../button/PayButton"

import Backend from "../../../../utils/Backend"
import Currency from "../../../../utils/Currency";
import Name from "../../../../utils/Name";
import Notify from "../../../../utils/Notify";
import PaymentMethodUtils from "../../../../utils/PaymentMethods";


const TOKEN_FRAME_PARAMS = {
  "useexpiry": false,
  "usecvv": false,
  "enhancedresponse": true,
  "inactivityto": 300,
  "tokenizewheninactive": true,
  "maskfirsttwo": true,
  "formatinput": true,
  "placeholder": "Card Number",
  "placeholdermonth": "MM",
  "placeholderyear": "YYYY",
  "tokenpropname": "cardconnect_token"
}

export default class PaymentMethods extends React.Component{

  constructor(props) {
    super(props);

    let company = props.company

    this.state = {
      ...this._getState(props),
      loading: true,
      tokenFrameUrl: this._getTokenFrameUrl(props.company, props.paymentMethods)
    }

    this.customer = React.createRef()
    this.expiryField = React.createRef()
    this.cvcField = React.createRef()
  }

  _getTokenFrameUrl(company, paymentMethods){
    let env = PaymentMethodUtils.getDefault(paymentMethods).data.site
    let tokenFrameUrl = `https://${env}.cardconnect.com/itoke/ajax-tokenizer.html?`

    for (const [key, value] of Object.entries(TOKEN_FRAME_PARAMS)) {
      tokenFrameUrl += `${key}=${value}&`
    }

    tokenFrameUrl += "css=" + encodeURIComponent(this._getCSSForLayout(company))

    return tokenFrameUrl
  }

  _getCSSForLayout(company){

    let css =  `
      body{
        margin:0;
      }
      #ccnumfield {
        background-color: transparent;
        font-size: 13px;
        font-family: Poppins, Helvetica, "sans-serif";
        color: #464E5F;
        border: 0;
        outline: none;
        padding:0;
        line-height: 1;
      }
      #ccnumfield::placeholder {
        font-size: 13px;
        font-family: Poppins, Helvetica, "sans-serif";
        color: #B5B5C3;
        text-transform: capitalize;
        border: 0;
        opacity:1;
      }
    `

    let retro_css =  `
      body{
        margin:0;
      }
      #ccnumfield {
        background-color: transparent;
        font-size: 12px;
        font-family: Poppins, Helvetica, "sans-serif";
        color: #464E5F;
        border: 0;
        outline: none;
        padding:0;
        line-height: 1;
      }
      #ccnumfield::placeholder {
        font-size: 12px;
        font-family: Poppins, Helvetica, "sans-serif";
        color: #B5B5C3;
        text-transform: capitalize;
        border: 0;
        opacity:1;
      }
    `

    let modern_css =  `
      body{
        margin:0;
      }
      #ccnumfield {
        background-color: transparent;
        font-size: 13px;
        font-family: Poppins, Helvetica, "sans-serif";
        color: #fff;
        border: 0;
        outline: none;
        padding:0;
        line-height: 1;
      }
      #ccnumfield::placeholder {
        font-size: 13px;
        font-family: Poppins, Helvetica, "sans-serif";
        color: #B5B5C3;
        text-transform: capitalize;
        border: 0;
        opacity:1;
      }
    `

    switch(company.settings.layout){
      case "classic":
        return css
      case "retro":
        return retro_css
      case "modern":
        return modern_css
      case "dashboard":
      return retro_css
      default:
        return css
    }

  }

  componentWillReceiveProps(nextProps) {
    this.setState(this._getState(nextProps))
  }

  componentDidMount(){
    window.addEventListener('message', (event) => {
      let data = null

      try{
        data = event.data ? JSON.parse(event.data) : null
      }
      catch(error){
        return
      }

      if(data.token != null){
        this.setState({
          token: data.token || null,
          aymentRequest: null
        }, () => this.expiryField.current.focus())
      }
    }, false);
  }

  _getState(nextProps){
    let {
      company,
      customer,
      paymentPlan
    } = nextProps

    let cardPaymentMethod = nextProps.paymentMethods.card
    let digitalWalletPaymentMethod = nextProps.paymentMethods.digital_wallet
    return {
      company,
      customer,
      paymentPlan,
      cardPaymentMethod,
      digitalWalletPaymentMethod
    }
  }

  _exchangePaymentRequestForToken(paymentRequest, resolve, reject){
    let { digitalWalletPaymentMethod } = this.state
    Backend.exchangeGooglePayPaymentRequestForCardConnectToken(paymentRequest, digitalWalletPaymentMethod)
    .then(token => {
      resolve()
      this.setState({ paymentRequest })
      this.props.onPayPressed(
        digitalWalletPaymentMethod,
        {
          token,
        }
      )
    })
    .catch(error => {
      reject()
      Notify.error(error.message)
    })
  }

  handleAction(response){
    throw { "error": "3DS", message: "Your card requires 3DS which is currently unsupported, please contact the merchant for assistance"}
  }

  isValid(scrollToError){
    if(this.customer.current && !this.customer.current.isValid(scrollToError)){
      return false
    }
    let { token, expiry, cvc, paymentRequest} = this.state

    if(paymentRequest){
      return true
    }

    let error = null
    if(!token){
      error = "Please enter a valid card number"
    }
    else if(!cvc){
      error = "Please enter a valid cvv/cvc"
    }
    else if(!expiry || expiry.indexOf("/") == -1){
      error = "Please enter a valid expiry date"
    }

    if(error){
      if(scrollToError){
        Notify.error(error)
      }
      return false
    }

    return true
  }

  getPaymentDetails(paymentMethod){
    let {
      token,
      expiry,
      cvc
    } = this.state

    return new Promise((resolve,reject) => {

      resolve({
          paymentMethod,
          paymentDetails: {
            cvv2: cvc,
            token: token,
            expiry: expiry?.replace(" / ", "").trim()
          }
        })
    })
  }

  _canShowDigitalWallet(){
    let {
      paymentPlan,
      digitalWalletPaymentMethod
    } = this.state

    return false

    if(!digitalWalletPaymentMethod){
      return null
    }

    let data = digitalWalletPaymentMethod.data
    if(!paymentPlan.total || window.ReactNativeWebView){
      return false
    }

    if(!data.apple_pay_enabled && !data.google_pay_enabled){
      return false
    }

    if(!data.google_pay_enabled && (isChrome || isAndroid)){
      return false
    }

    if(!data.apple_pay_enabled && isSafari){
      return false
    }


    return true
  }

  _getDigitalWalletConfig(){
    let {
      company,
      customer,
      paymentPlan,
      digitalWalletPaymentMethod
    } = this.state

    let methodData = []

    console.log("3333", digitalWalletPaymentMethod.data.google_pay_enabled)

    return {
      onShowSuccess: (result, resolve, reject): void => {
        // make the payment
        if(result.details){
          customer.email = customer.email || result.payerEmail

          if(!customer.first_name){
            customer.first_name = Name.getFirstName(result.payerName)
            customer.last_name = Name.getLastName(result.payerName)
          }
          this.props.onUpdatedCustomer(customer)
          this._exchangePaymentRequestForToken(result.details, resolve, reject)
        }
        else {
          reject()
        }
      },
      /* tslint:disable-next-line:no-console */
      onShowFail: (error) => console.log('Error', error),
      methodData: [
        {
          supportedMethods: ["https://pay.google.com/about"],
          data: {
            environment: company.settings.live ? window.Api.GooglePayLiveEnv : 'TEST',
            apiVersion: 2,
            apiVersionMinor: 0,
            allowedPaymentMethods: [
              {
                type: 'CARD',
                parameters: {
                  allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
                  allowedCardNetworks: ["AMEX", "DISCOVER", "INTERAC", "JCB", "MASTERCARD", "MIR", "VISA"],
                },
                tokenizationSpecification: {
                  type: 'PAYMENT_GATEWAY',
                  parameters: {
                    gateway: 'cardconnect',
                    gatewayMerchantId: digitalWalletPaymentMethod.data.merchid,
                  },
                },
              },
            ],
            merchantInfo: {
              merchantId: `${company.id}`,
              merchantName: company.name,
            },
            transactionInfo: {
              totalPriceStatus: 'FINAL',
              totalPrice: `${paymentPlan.total / 100.0}`,
              currencyCode: paymentPlan.currency.code.toUpperCase(),
            },
            emailRequired: true,
          },
        },
        {
          supportedMethods: 'https://apple.com/apple-pay',
          data: {
              version: 3,
              merchantIdentifier: 'merchant.io.splink',
              merchantCapabilities: ['supportsDebit'],
              supportedNetworks: ['masterCard', 'visa'],
              countryCode: 'US',
          }
        }
      ],
      details: {
        displayItems: [
          {
            label: 'Due',
            amount: {
              currency: paymentPlan.currency.code.toUpperCase(),
              value: `${paymentPlan.total / 100.0}`
            }
          }
        ],
        total: {
          label: 'Total due',
          amount: {
            currency: paymentPlan.currency.code.toUpperCase(),
            value : `${paymentPlan.total / 100.0}`
          },
        }
      },
      options: {
        requestPayerEmail: true,
        requestPayerName: true
      },
      onMerchantValidation: (event) => {
        event.complete(Promise.resolve(event.validationURL));
      },
    }
  }

  render() {

    let {
      company,
      customer,
      paymentPlan,
      cardPaymentMethod,
      tokenFrameUrl,
      digitalWalletPaymentMethod
    } = this.state

    return (
      <>

        { cardPaymentMethod &&
          <PaymentMethodPortal type={"card"}>

            <Customer
              ref={this.customer}
              customer={customer}
              collectEmail={this.props.collectEmail}
              collectName={true}
              onUpdated={customer => {
                this.props.onUpdatedCustomer(customer)
              }}
            />


            <div className="payment-method card_connect form-group mb-2 text-left">
              <div className="input-group with-append">
                <div className="form-control h-auto border-0">
                  <div className="row">

                    <div className="col cc-number">
                      <iframe
                        src={tokenFrameUrl}
                        id="tokenframe"
                        name="tokenframe"
                        frameBorder="0"
                        scrolling="no"
                        width="100%"
                      />
                    </div>
                    <PaymentInputsContainer>
                      {({ meta, getCardNumberProps, getExpiryDateProps, getCVCProps }) => (
                        <>
                          <div className="col cc-exp">
                            <input
                              className="border-0"
                              {...getExpiryDateProps({
                                  onChange: (e) => {
                                    this.setState({ expiry: e.target.value })
                                  },
                                  ref: expiryField => {
                                    if(expiryField){
                                      this.expiryField.current = expiryField
                                      getExpiryDateProps().ref.current = expiryField
                                    }
                                  }
                                })
                              }
                            />
                          </div>
                          <div className="col cc-cvc">
                            <input
                              className="border-0"
                              {...getCVCProps({
                                  onChange: (e) => {
                                    this.setState({ cvc: e.target.value })
                                  },
                                  ref: cvcField => {
                                    if(cvcField){
                                      this.cvcField.current = cvcField
                                      getCVCProps().ref.current = cvcField
                                    }
                                  }
                                })
                              }
                            />
                          </div>
                        </>
                      )}
                    </PaymentInputsContainer>

                  </div>
                </div>
                <LockInputAppendIcon/>
              </div>
            </div>

            <PayButton
              payButtonTitle={this.props.payButtonTitle}
              payButtonContainerId={this.props.payButtonContainerId}
              backgroundColor={company.settings.primary_color}
              paymentPlan={this.state.paymentPlan}
              onClick={() => {
                this.props.onPayPressed(cardPaymentMethod)
              }}
            />

          </PaymentMethodPortal>
        }

        { this._canShowDigitalWallet()
          &&
          <PaymentMethodPortal type={"digital_wallet"}>
            <DigitalWalletButton
              config={this._getDigitalWalletConfig()}
            />
          </PaymentMethodPortal>
        }

      </>
    )
  }

}
