import React from "react";

import Separator from "../common/Separator"

import LoadingOverlay from 'react-loading-overlay';

import PaymentMethodUtils from "../../../utils/PaymentMethods";


export class PaymentMethodsContainer extends React.Component{

  constructor(props) {
    super(props);

  }

  render() {

    let {
      inApp,
      nfcPrioritised,
      paymentMethods,
      payButtonContainerId,
      showAlternativePaymentMethods
    } = this.props

    let supportsNonCard = PaymentMethodUtils.supportsNonCard(paymentMethods) && !inApp

    return (
      <>
        { !nfcPrioritised &&
          <>

            {!inApp &&
              <div id="alternate-payment-methods">

                <div id="payment-method-section-digital_wallet"/>

                <div id="payment-method-section-google_pay"/>

                <div id="payment-method-section-apple_pay"/>

                <div id="payment-method-section-sepa"/>

                <div id="payment-method-section-sepa_instant"/>

                <div id="payment-method-section-faster_payments"/>

                <div id="payment-method-section-bacs"/>

                <div id="payment-method-section-ach"/>

                <div id="payment-method-section-eft"/>

                <div id="payment-method-section-interac"/>

                <div id="payment-method-section-klarna"/>
              </div>
            }

            { paymentMethods.card && paymentMethods.card.processor.indexOf("boipa") == -1 &&
              <Separator
                text="Card Details"
              />
            }

            <div id="payment-method-section-card"/>

            <div id="payment-form-footer"/>

            { payButtonContainerId &&
              <div id={payButtonContainerId}/>
            }

            <div
              id="other-payment-methods-form-footer"
              style={{
                display: showAlternativePaymentMethods ? "visible" : "none"
              }}
            />

          </>
        }
      </>
    )
  }

}

PaymentMethodsContainer.default = {
  payButtonContainerId: null
}

export default PaymentMethodsContainer
