import React from 'react'
import Modal from 'react-bootstrap/Modal'

export default class SuccessModal extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      show: props.show,
      title: props.title,
      message: props.message,
      buttonTitle: props.buttonTitle
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  render() {
    let {
      show,
      title,
      message,
      buttonTitle
    } = this.state

    return (
      <Modal
        show={show}
        onHide={() => {}}
        centered
      >
        <Modal.Body>
          <div className="swal2-icon swal2-success swal2-icon-show" style={{display: 'flex'}}>
            <div className="swal2-success-circular-line-left"></div>
            <span className="swal2-success-line-tip"></span>
            <span className="swal2-success-line-long"></span>
            <div className="swal2-success-ring"></div>
            <div className="swal2-success-fix"></div>
            <div className="swal2-success-circular-line-right"></div>
          </div>

          <h1 className="text-center">{ title }</h1>
          <p className="text-center">{ message }</p>
        </Modal.Body>

        <Modal.Footer>
          <div className="w-100 text-center">
            <button
              type="button"
              className={`btn btn-secondary`}
              onClick={() => this.props.onButtonClicked()}
            >
              { buttonTitle }
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    )
  }
}

SuccessModal.defaultProps = {
}
