import React from 'react'
import Modal from 'react-bootstrap/Modal'

import LoadingOverlay from 'react-loading-overlay';

import PaymentMethods from "../payment/PaymentMethods"
import PaymentMethodsContainer from "../payment/PaymentMethodsContainer"

import AsyncStorage from '../../../utils/AsyncStorage'

import Backend from "../../../utils/Backend"
import Notify from "../../../utils/Notify"

export default class PaymentInstrumentModal extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      show: props.show,
      subscription: props.subscription,
      company: props.subscription.initial_payment.company,
      authToken: props.authToken,
      customer: { address: {} },
      paymentDetails: null
    }

    this.paymentMethods = React.createRef()
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  resume(state){
    this.setState(state, () => {
      let { paymentMethod, paymentDetails } = state

      // boipa update of details will happen via a webhook to API so no need to
      // call it again
      if(paymentMethod.processor.indexOf("boipa") > -1){
        let url = new URL(window.location.href)
        let params = new URLSearchParams(url.search)
        let result = params.get("result")
        if(result === "success"){
          this.props.onCancel()
          Notify.success("Your card details have been changed. It may take a few moments for the new details to be reflected here.")
        }
        else{
          Notify.success("An unexpected error occured please try again")
        }
        return
      }

      this._changePaymentInstrument(paymentMethod, paymentDetails)
    })
  }

  _isValid(paymentMethod){
    let isValid = true
    if(!this.paymentMethods.current.isValid(isValid, paymentMethod)){
      isValid = false
    }

    return isValid
  }

  _handleChangePaymentInstrument(paymentMethod){
    if(!this._isValid(paymentMethod)){
      return false
    }

    this.setState({ isLoading: true, paymentMethod })
    this.paymentMethods.current.getPaymentDetails(paymentMethod)
    .then(({paymentMethod, paymentDetails}) => {
      this._changePaymentInstrument(paymentMethod, paymentDetails)
    })
    .catch(error => {
      this.setState({isLoading: false})
      Notify.error(error.message)
    })
  }

  _changePaymentInstrument(paymentMethod, paymentDetails){
    let {
      company,
      customer,
      subscription,
      authToken
    } = this.state

    paymentDetails.customer = customer
    let data = {
      customer,
      payment_method: paymentMethod.id,
      payment_details: paymentDetails
    }

    this.setState({isLoading: true, paymentMethod})

    Backend.changePaymentInstrument(subscription, authToken, data)
    .then(response => {
      if(response.requires_action){
        return this.paymentMethods.current.handleAction(response, paymentMethod)
        .then(paymentDetails => {
          return this._changePaymentInstrument(paymentMethod, paymentDetails)
        })
      }

      localStorage.removeItem("payment_instrument_state")
      Notify.success("Default Payment Method successfully updated")
      this.props.onUpdated(response)
      this.setState({ isLoading: false, customer: { address: {} } })
    })
    .catch(error => {
      this.setState({isLoading: false})
      Notify.error(error.message)
    })
  }


  render() {
    let {
      show,
      company,
      customer,
      subscription,
      isLoading
    } = this.state


    let className = "form payment-form payment-instrument-modal"
    if(isLoading){
      className += " payment-form-loading"
    }



    return (
      <Modal
        show={show}
        onHide={() => this.props.onCancel()}
        centered
        className={this.props.modalClassName}
      >
        <Modal.Body>

          <div className={className}>

            <h3 className="font-weight-bolder text-dark font-size-h4 mb-8">
              Change Payment Method
            </h3>

            <PaymentMethods
              ref={this.paymentMethods}
              customer={customer}
              company={company}
              products={[]}
              product={null}
              payButtonTitle={"Change Payment Method"}
              payButtonContainerId="sub-payment-form-footer"
              paymentMethods={company.payment_methods}
              paymentPlan={subscription.payment_plan}
              collectEmail={true}
              collectAddress={false}
              subscriptionPayment={true}
              onUpdated={paymentDetails => {
                this.setState({ paymentDetails })
              }}
              onUpdatedCustomer={customer => {
                this.setState({ customer })
              }}
              onPayPressed={(paymentMethod, paymentDetails) => {
                if(paymentMethod.type !== "digital_wallet"){
                  this._handleChangePaymentInstrument(paymentMethod)
                  return
                }

                if(!this._isValid(paymentMethod)){
                  return
                }
                this._changePaymentInstrument(paymentMethod, paymentDetails)
              }}
              onSaveState={(paymentDetails) => {
                this.setState({ paymentDetails}, () => {
                  AsyncStorage.setItem("payment_instrument_state", JSON.stringify(this.state))
                })
              }}
            />

            <PaymentMethodsContainer
              paymentMethods={company.payment_methods}
              showAlternativePaymentMethods={true}
              payButtonContainerId="sub-payment-form-footer"
            />

          </div>

          <LoadingOverlay
              active={isLoading}
              className={isLoading ? "payment-form-loading-overlay" : ""}
              spinner
              text={<p>Loading... <br/> please do not refresh</p>}
          />

        </Modal.Body>

      </Modal>
    )
  }
}

PaymentInstrumentModal.defaultProps = {
  modalClassName: ''
}
