import React from 'react'
import Modal from 'react-bootstrap/Modal'

import Backend from "../../../../utils/Backend"
import Notify from "../../../../utils/Notify";

import LockInputAppendIcon from "../LockInputAppendIcon"

import PayButton from "../button/PayButton"
import BankPayButton from "../button/BankPayButton"

import Discount from "../../../../utils/Discount";
import Currency from "../../../../utils/Currency";

import IconCard from '../../icons/Card';
import IconBank from '../../icons/Bank';

export default class CashDiscountModal extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      show: props.show,
      company: props.company,
      paymentPlan: props.paymentPlan,
      paymentMethods: props.paymentMethods,
    }
  }

  _renderButton(icon, title, amount, className, onClick){
    return (
      <button
        className={`btn ${className}`}
        onClick={onClick}
      >

          <span className="svg-icon">
            { icon }
          </span>

          <span className="title">
            { title }
          </span>

          <span className="amount">
            { amount }
          </span>

      </button>
    )
  }


  render() {
    let {
      show,
      company,
      paymentPlan,
      paymentMethods,
    } = this.state

    let symbol = paymentPlan.currency.symbol

    let discountedTotal = Discount.getDiscountedTotal(paymentPlan, paymentMethods.ach)
    let discountedPaymentAmountFormatted = `${Currency.format(symbol, discountedTotal)}`
    let originalPaymentAmountFormatted = `${Currency.format(symbol, paymentPlan.total)}`

    return (
      <Modal
        show={show}
        onHide={() => this.props.onClose()}
        centered
        className={`cash-discount-modal ${this.props.modalClassName} layout-${company.settings.layout}`}
      >
        <Modal.Body>
          <div>How would you like to pay?</div>
          <div className="pay-by">
            { this._renderButton(<IconCard />, `Pay by card`, `${originalPaymentAmountFormatted}`, " ", this.props.onCardPressed) }
            { this._renderButton(<IconBank />, `Pay by bank`, `${discountedPaymentAmountFormatted}`, " ", this.props.onACHPressed) }
          </div>
        </Modal.Body>
      </Modal>
    )
  }
}

CashDiscountModal.defaultProps = {
  modalClassName: 'sub-modal',
}
