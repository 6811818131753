import React from 'react'

import { Portal } from 'react-portal';

import Currency from "../../../../utils/Currency";
import Splink from "../../../../utils/Splink";

export default class PayButton extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      loading: false,
      company: props.company,
      paymentPlan: props.paymentPlan,
      canRender: false,
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  componentDidMount(){
    setTimeout(() => this.setState({ canRender: true }), 50)
  }

  render(){
    let {
      canRender,
      paymentPlan,
      loading
    } = this.state

    if(!canRender){
      return false
    }

    let payButtonTitle = "Pay Now"

    if(window.ReactNativeWebView){
      payButtonTitle = "Take Payment"
      if(paymentPlan.total > 0){
        payButtonTitle += ` (${Currency.format(paymentPlan.currency.symbol, paymentPlan.total,"two")})`
      }
    } else {
      if(paymentPlan.total > 0){
        payButtonTitle = `Submit ${Currency.format(paymentPlan.currency.symbol, paymentPlan.total)} Payment`
      }
    }

    payButtonTitle = this.props.payButtonTitle || payButtonTitle

    return (
      <Portal node={document && document.getElementById(this.props.payButtonContainerId)}>
        <div className="form-group text-center pay-button">

          <button
            className="btn btn-primary btn-block btn-paynow"
            disabled={loading}
            onClick={e => {
              e.preventDefault()
              this.props.onClick()
            }}
          >

            <span className="d-flex align-items-center justify-content-center">

              <span className="flex-grow-1 font-weight-bolder">
                <span className="loading mr-2"><i className="fa fa-spinner fa-spin"></i></span>
                { payButtonTitle }
              </span>

              <span className="svg-icon svg-icon-2x ml-2">
                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                      <mask fill="white">
                          <use xlinkHref="#path-1"></use>
                      </mask>
                      <g></g>
                      <path d="M7,10 L7,8 C7,5.23857625 9.23857625,3 12,3 C14.7614237,3 17,5.23857625 17,8 L17,10 L18,10 C19.1045695,10 20,10.8954305 20,12 L20,18 C20,19.1045695 19.1045695,20 18,20 L6,20 C4.8954305,20 4,19.1045695 4,18 L4,12 C4,10.8954305 4.8954305,10 6,10 L7,10 Z M12,5 C10.3431458,5 9,6.34314575 9,8 L9,10 L15,10 L15,8 C15,6.34314575 13.6568542,5 12,5 Z" fill="#000000"></path>
                  </g>
                </svg>
              </span>

            </span>

          </button>

        </div>
      </Portal>
    )
  }
}

PayButton.defaultProps = {
  payButtonContainerId: 'payment-form-footer'
}
