import React from 'react'

import {
  isMobile,
  isIOS,
  isSafari,
  isChrome,
  isAndroid,
  isMacOS
} from 'react-device-detect';

import { PaymentRequestButtonElement } from '@stripe/react-stripe-js';

import { COUNTRY_OPTIONS } from "../../common/CountrySelect";

import General from "../../../../utils/General";
import GooglePayButton from "../button/GooglePayButton";
import ApplePayButton from "../button/ApplePayButton";


export default class DigitalWalletButton extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      paymentPlan: props.paymentPlan,
    }
  }

  componentDidMount(){
    this._update(this.state.paymentPlan)
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps, () => this._update())
  }

  _parseAddress(address){
    if(!address){
      return null
    }

    let countryOption =null
    if(address.country){
      countryOption = COUNTRY_OPTIONS.find(option => option.value.toLowerCase() === address.country.toLowerCase())
    }

    let parsedAddress = {
      line_1: address.addressLine?[0] : address.line1,
      line_2: address.addressLine?[1] : address.line2,
      city: address.city,
      state: address.state,
      postal_code: address.postal_code,
      country_short: address.country,
      country: countryOption?.label || null,
    }

    return {
      line_1: parsedAddress.line_1 || null,
      line_2: parsedAddress.line_2 || null,
      city: parsedAddress.city || null,
      state: parsedAddress.state || null,
      postal_code: parsedAddress.postal_code || null,
      country_short: parsedAddress.country_short || null,
      country: parsedAddress.country || null
    }
  }


  _update = General.debounce(() => {
    let {
      paymentPlan,
      shippingAddress
    } = this.state

    this.setState({
      canMakePayment: false,
      paymentRequest: null
    })

    const paymentRequest = this.props.stripe.paymentRequest({
      country: 'IE',
      currency: paymentPlan.currency.code,
      total: {
        label: 'Total',
        amount: paymentPlan.total,
      },
      requestPayerName: true,
      requestPayerEmail: true,
      // full addrress is witheld by the browser so unusable
      // see: https://stripe.com/docs/js/payment_request/events/on_shipping_address_change
      requestShipping: false,
    });

    paymentRequest.on('paymentmethod', async (ev) => {
      let address = {
        shipping: shippingAddress,
        billing: this._parseAddress(ev.paymentMethod.billing_details.address),
      }

      this.props.onPayPressed({
        payment_method_id: ev.paymentMethod.id
      }, ev.paymentMethod.billing_details, address)
      ev.complete('success');
    });

    paymentRequest.on('shippingaddresschange', ev => {
      this.setState({ shippingAddress: this._parseAddress(ev.shippingAddress) })
      ev.updateWith({
        status: 'success',
        shippingOptions: [
          {
            id: 'free-shipping',
            label: 'Free Shipping',
            detail: 'This purchase has free shipping if required',
            amount: 0,
          },
        ],
      });
    });

    paymentRequest.canMakePayment()
    .then((result) => {
      if (result) {
        this.setState({
          canMakePayment: true,
          paymentRequest,
          applePay: result.applePay
        })
      }else{
        this.setState({
          canMakePayment: false,
        })
      }
    });

  }, 1000, false)


  _renderButton(){
    let {
      applePay,
      paymentPlan,
      canMakePayment,
      paymentRequest,
    } = this.state

    if(applePay){
      return (
        <ApplePayButton
          onClick={e => paymentRequest.show()}
        />
      )
    }

    if(isAndroid || isChrome){
      return (
        <GooglePayButton
          onClick={e => paymentRequest.show()}
        />
      )
    }

    return (
      <PaymentRequestButtonElement
        options={{paymentRequest}}
        className="digital-wallet-button"
      />
    )
  }

  render(){
    let {
      paymentPlan,
      canMakePayment,
      paymentRequest,
      applePay
    } = this.state

    if(!canMakePayment || !paymentRequest){
      return null
    }

    return (
      <div className='digital-wallet-button'>
        { this.props.renderHeader() }
        { this._renderButton() }
        { this.props.renderFooter() }
      </div>
    )
  }
}
