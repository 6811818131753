import React from 'react'
import Modal from 'react-bootstrap/Modal'

import Backend from "../../../../utils/Backend"
import Notify from "../../../../utils/Notify";

export default class FlinksModal extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      data: {},
      show: props.show,
      error: props.error,
      isLoading: true,
      connectLink: props.connectLink
    }
  }

  componentWillReceiveProps(nextProps){
    let isLoading = nextProps.show != this.state.show
    this.setState({
      ...nextProps,
      isLoading
    })
  }

  componentDidMount(){
    this.flinksListener = (e) => {
      let data = this.state.data
      if (e.data.loginId) {
        data.login_id = e.data.loginId
        this.setState({ data })
      }
      if (e.data.step === "ACCOUNT_SELECTED") {
        data.account_id = e.data.accountId
        this.setState({ data }, () => this._onConnected(data.login_id, data.account_id))
      }
    }
    window.addEventListener('message', this.flinksListener);
  }

  componentWillUnmout(){
    window.removeEventListener('message', this.flinksListener)
  }

  _onConnected(loginId, accountId){
    this.setState({ loadingEftDetails: true })
    Backend.exchangeFlinksForEftDetails(this.state.paymentMethod, loginId, accountId)
    .then(eftDetails => {
      this.setState({ loadingEftDetails: false })
      this.props.onConnected(eftDetails)
    })
    .catch(error => {
      this.setState({ loadingEftDetails: false, loading: true })
      Notify.error(error.message)
    })
  }

  _renderContent(){
    let {
      connectLink,
      loadingEftDetails
    } = this.state

    if(loadingEftDetails){
      return (
        <div style={{ width: 600, height: 700 }}>
          { this._renderLoading() }
        </div>
      )
    }

    return (
      <>
        { this.state.isLoading && this._renderLoading() }
        <iframe
          height="600"
          width="100%"
          frameBorder="0"
          onLoad={() => this.setState({ isLoading: false })}
          src={connectLink}
        />
      </>
    )
  }

  _renderLoading(){
    return (
      <div
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
          textAlign: "center",
          verticalAlign: "middle",
          paddingTop: "50%"
        }}
      >
        Loading...
      </div>
    )
  }

  render() {
    let {
      show,
      error,
      connectLink,
    } = this.props

    return (
      <Modal
        show={show}
        onHide={() => {}}
        centered
        className={this.props.modalClassName}
      >
        <Modal.Body
          style={{ padding: 0 }}
        >
          { this._renderContent() }
        </Modal.Body>

        { !this.state.loadingEftDetails &&
          <Modal.Footer>
            <div className="w-100 text-center">
              <button
                type="button"
                className={`btn btn-secondary`}
                onClick={() => this.props.onClose()}
              >
                Close
              </button>
            </div>
          </Modal.Footer>
        }
      </Modal>
    )
  }
}

FlinksModal.defaultProps = {
  modalClassName: '',
}
