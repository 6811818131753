import React from 'react';
import $ from "jquery"

import MetaTags from "../../utils/MetaTags";
import Splink from "../../utils/Splink";

import General from "../../utils/General";

export default class PageLayout extends React.Component {

  constructor(props) {
    super(props);


    let company = props.company
    let association = company.association

    // todo - placeholder logo or remove?
    let droplet = window.ReactNativeWebView ? require("../../assets/media/logos/droplet_with_white_background.png") : require("../../assets/media/logos/droplet.png")
    let logo = company.settings.logo ? company.settings.logo.thumbnail : droplet
    let favicon = require("../../assets/media/logos/favicon.png")

    if(association?.favicon){
      favicon = association.favicon.banner
      if(!company.settings.logo){
        logo = favicon
      }
      else{
        favicon = logo
      }
    }

    let backgroundImage = props.backgroundImage

    if(!backgroundImage){
      backgroundImage = company.settings.background ? company.settings.background.original : '/assets/media/bg/mosaic-studio.jpg'
    }

    backgroundImage = `url(${backgroundImage})`

    let associationLogo = association?.logo_banner?.thumbnail || "/assets/media/logos/logo-light.png"

    let title = props.title || company.name

    // starts : temporary added, have to remove before code merge
    if(General.getQueryVariable('layout')=="modern"){ 
      company.settings.layout = "modern"
    }
    if(General.getQueryVariable('layout')=="classic"){
      company.settings.layout = "classic"
    }
    // end 
    
    let layout = window.ReactNativeWebView ? "in-app" : props.layout || company.settings.layout
    this.state = {
      logo,
      layout,
      company,
      title,
      favicon,
      backgroundImage,
      associationLogo,
      activate: props.activate,
      labelText: props.labelText,
      showCaption: props.showCaption,
      containerClassName: props.containerClassName
    }
  }

  _renderClassic(){

    let {
      logo,
      title,
      company,
      activate,
      labelText,
      showCaption,
      backgroundImage,
      associationLogo,
      containerClassName
    } = this.state

    let primaryColor = company.settings.primary_color
    let secondaryColor = company.settings.secondary_color

    if(secondaryColor && secondaryColor.indexOf("#") === 0){
      secondaryColor = `${secondaryColor}${decimalToHex(0.85)}`
    }

    let primaryLightColor = primaryColor
    if(primaryLightColor && primaryLightColor.indexOf("#") === 0){
      primaryLightColor = `${primaryLightColor}${decimalToHex(0.5)}`
    }

    return (
      <div className={containerClassName}>
        { this._renderStyles() }
        <div className="layout-classic">
          <div className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white min-vh-100" id="kt_login">

            <div className="login-aside" style={{backgroundImage: backgroundImage, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center'}}>
              <div className="pt-lg-40 py-15 bg-dark-o-70 payment-aside">
                <div className="center-vertical-horizontal text-center px-7">

                  <div className="d-flex flex-center top-head mb-10">
                    { logo &&
                      <span className="logo-circle">
                        <img src={logo} alt="" />
                      </span>
                    }
                  </div>

                  <h3
                    className="font-weight-bolder text-center font-size-h4 font-size-h1-lg pre text-white"
                  >
                    {title}
                  </h3>

                  { showCaption  &&
                    <p
                      className="opacity-60 font-weight-bold text-white"
                    >
                      {company.caption}
                    </p>
                  }

                  <div className="pt-5 text-white text-center pt-10 hide-desktop">Powered by <img src={associationLogo} className="max-h-20px" alt="logo" /></div>

                </div>

                <div className="center-horizontal-bottom hide-mobile">
                  <div className="py-5 text-white text-center">Powered by <img src={associationLogo} className="max-h-20px" alt="logo" /></div>
                </div>

              </div>
            </div>

            <div className="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 pt-38 pb-38 mx-auto">
              <div className="d-flex flex-column-fluid flex-center">
                <div className="login-form login-signin">

                  <div className="pb-13 pt-lg-0 pt-5">
                    <h3 className="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">
                      {company.name}
                      { labelText &&
                        <span
                          className="label label-pill float-right label-inline font-weight-bolder"
                          style={{ color: primaryColor, backgroundColor: "#e5e5e5" }}
                        >
                          { labelText }
                        </span>
                      }
                    </h3>
                    <p className="font-weight-bold">{company.caption}</p>
                  </div>

                  { this.props.children }

                </div>

              </div>
            </div>

          </div>
        </div>
      </div>
    )
  }

  _renderModern(){

    let {
      logo,
      title,
      company,
      activate,
      labelText,
      showCaption,
      backgroundImage,
      associationLogo,
      containerClassName
    } = this.state

    let primaryColor = company.settings.primary_color
    let secondaryColor = company.settings.secondary_color

    if(secondaryColor && secondaryColor.indexOf("#") === 0){
      secondaryColor = `${secondaryColor}${decimalToHex(0.85)}`
    }

    let primaryLightColor = primaryColor
    if(primaryLightColor && primaryLightColor.indexOf("#") === 0){
      primaryLightColor = `${primaryLightColor}${decimalToHex(0.5)}`
    }

    return (
      <div className={containerClassName}>
        { this._renderStyles() }
        <div>
          <div>
            <div className="layout-modern">

              <div className="fixed-bg-image" style={{backgroundImage: backgroundImage}}></div>
              <div className="fixed-bg-color bg-dark-o-200"></div>

              <div className="login login-3 login-signin-on d-flex flex-row-fluid min-vh-100" id="kt_login">
                <div className="d-flex flex-center bgi-size-cover bgi-no-repeat flex-row-fluid">
                  <div className="login-form text-white p-7 pt-38 pb-38 position-relative">

                    <div className="d-flex flex-center top-head">
                      { logo &&
                        <span className="logo-circle">
                          <img src={logo} alt="" />
                        </span>
                      }
                    </div>

                    <div className="login-signin text-white">
                      <div className="mb-10 mt-10 text-center">

                        <h3
                          className="font-weight-normal text-white"
                        >
                          {title}
                        </h3>

                        { showCaption  &&
                          <p
                            className="text-white"
                          >{company.caption}</p>
                        }

                        { labelText &&
                          <div className="pt-6">
                            <span
                              className="viewed label label-pill label-inline font-weight-bolder"
                              style={{ color: primaryColor}}
                            >{ labelText }</span>
                          </div>
                        }

                      </div>
                      { this.props.children }
                    </div>

                    <div className="py-5 text-center">Powered by <img src={associationLogo} className="max-h-20px" alt="logo" /></div>

                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    )
  }

  _renderRetro(){

    let {
      logo,
      title,
      company,
      activate,
      labelText,
      showCaption,
      backgroundImage,
      associationLogo,
      containerClassName
    } = this.state

    let primaryColor = company.settings.primary_color
    let secondaryColor = company.settings.secondary_color

    if(secondaryColor && secondaryColor.indexOf("#") === 0){
      secondaryColor = `${secondaryColor}${decimalToHex(0.85)}`
    }

    let primaryLightColor = primaryColor
    if(primaryLightColor && primaryLightColor.indexOf("#") === 0){
      primaryLightColor = `${primaryLightColor}${decimalToHex(0.5)}`
    }

    // starts : Skip the keyboard accessibility
    if(activate){
      setTimeout(function(){
        $(".layout-activate .login-form input, .layout-activate .login-form .react-stripe iframe, .layout-activate .login-form .form-group, .layout-activate .login-form .btn, .layout-activate .react-tel-input .flag-dropdown .selected-flag").attr('tabindex', '-1');
      }, 1200);
    }
    // end : Skip the keyboard accessibility

    return (
      <div className={containerClassName}>
        { this._renderStyles() }
        <div className={activate?"layout-activate":""}>
          <div className={activate?"login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white min-vh-100":""}>

            <div className={activate?"login-aside layout-retro":"layout-retro"}>

              <div className="fixed-bg-image" style={{backgroundImage: backgroundImage}}></div>
              <div className="fixed-bg-color bg-dark-o-200"></div>
              { activate &&
                <div
                  className="fixed-bg-color activate-mask"
                  onClick={() => this.props.onActivateMaskClicked()}
                />
              }

              <div className="login login-3 login-signin-on d-flex flex-row-fluid min-vh-100" id="kt_login">
                <div className="d-flex flex-center bgi-size-cover bgi-no-repeat flex-row-fluid">
                  <div className="login-form text-white p-7 pt-38 pb-38 position-relative">
                    <div className="d-flex flex-center top-head">
                      { logo &&
                        <span className="logo-circle">
                          <img src={logo} alt="" />
                        </span>
                      }
                    </div>

                    <div className="login-signin text-dark" style={{ backgroundColor: secondaryColor }}>

                      <div className="mb-10 mt-10 text-center">

                        <h3
                          className="font-weight-normal"
                          style={{ color: primaryColor }}
                        >
                          {title}
                        </h3>

                        { showCaption  &&
                          <p
                            className="text-dark caption"
                          >{company.caption}</p>
                        }
                        { labelText &&
                          <div className="pt-6">
                            <span
                              className="viewed label label-pill label-inline font-weight-bolder"
                              style={{ color: primaryColor, backgroundColor: "#e5e5e5" }}
                            >{ labelText }</span>
                          </div>
                        }

                      </div>

                      { this.props.children }

                    </div>

                    <div className="py-5 text-center">Powered by <img src={associationLogo} className="max-h-20px" alt="logo" /></div>

                  </div>
                </div>
              </div>
            </div>

            { activate &&
              <div className="layout-classic login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-14 pt-38 pb-38 mx-auto">
                { this.props.renderSidePanel() }
              </div>
            }

          </div>
        </div>
      </div>
    )

  }

  _renderInApp(){

    let {
      logo,
      title,
      company,
      activate,
      labelText,
      showCaption,
      backgroundImage,
      associationLogo
    } = this.state

    let primaryColor =  window.General.Branding.Colors.primary
    let textColorOnPrimary = window.General.Branding.Colors.text_on_primary
    let takeBackgroundColor =  company.association ? window.General.Branding.Colors.take : "#33ca8a"

    let primaryLightColor = primaryColor
    if(primaryLightColor && primaryLightColor.indexOf("#") === 0){
      primaryLightColor = `${primaryLightColor}${decimalToHex(0.5)}`
    }

    return (

      <div className="layout-in-app">

        <style dangerouslySetInnerHTML={{ __html: `
            html{
              background-color: ${takeBackgroundColor} !important;
            }
            .layout-in-app{
              background-color: ${takeBackgroundColor};
            }
            .btn.btn-primary,
            .btn.btn-primary:hover:not(.btn-text):not(:disabled):not(.disabled),
            .btn.btn-primary:not(:disabled):not(.disabled):active:not(.btn-text),
            .btn.btn-primary:focus:not(.btn-text), .btn.btn-primary.focus:not(.btn-text){
              background-color: ${primaryColor};
              border-color: ${primaryColor};
              color: ${textColorOnPrimary};
            }
            .advanced-options .toggle{
              color: ${primaryColor};
            }
            .layout-in-app .dynamic-currency .custom-async-select__control .custom-async-select__value-container .custom-async-select__single-value {
              color: ${primaryColor} !important;
            }
            .layout-in-app .dynamic-currency .custom-async-select__control .custom-async-select__indicators .custom-async-select__indicator svg path {
              fill: ${primaryColor} !important;
              stroke: ${primaryColor} !important;
            }
            .layout-in-app .dynamic-currency .custom-async-select__control {
              border: 0 !important;
              box-shadow: none;
            }
          `}}
          />

        <div className="login login-3 login-signin-on">

            <div className="login-form mx-auto text-white position-relative overflow-hidden d-flex flex-column min-vh-100">

              {/* starts, header */}
              <div className="d-flex flex-column flex-center top-head">

                { logo &&
                  <span className="logo-circle">
                    <img src={logo} alt="" />
                  </span>
                }

                <div className="text-center">
                  <h3 className="text-uppercase">Take Card Payment</h3>
                </div>

              </div>
              {/* end, header */}

              { /* starts, form */}
              <div className="login-signin flex-grow-1 text-dark">
                { this.props.children }
              </div>
              { /* end, form */}

            </div>

        </div>

      </div>

    )

  }

  _renderStyles(){

    let {
      company
    } = this.state

    let layout = company.settings.layout
    let primaryColor = company.settings.primary_color

    let secondaryColor = company.settings.secondary_color

    if(secondaryColor && secondaryColor.indexOf("#") === 0){
      secondaryColor = `${secondaryColor}${decimalToHex(0.85)}`
    }

    return (
      <>
        <style dangerouslySetInnerHTML={{ __html: `
            :root {
              --primary-color: ` + primaryColor + ` ;
              --secondary-color: ` + secondaryColor + ` ;
            }
            .btn.btn-primary{
              border-color: ` + primaryColor + ` !important;
              background-color: ` + primaryColor + ` !important;
            }
            .shop.v2 .btn.btn-primary:hover{
              border-color: ` + primaryColor+"E6" + ` !important;
              background-color: ` + primaryColor+"E6" + ` !important;
            }
            .switch input:checked ~ span:after{
              background-color: ` + primaryColor + ` !important;
            }
          `}}
        />
        { layout === "classic" &&
          <style dangerouslySetInnerHTML={{ __html: `
            .input-group .svg-icon svg g [fill]{
              fill: ` + primaryColor + ` !important;
            }
          `}}
          />
        }
        { ( layout === "classic" || layout === "modern" ) &&
          <style dangerouslySetInnerHTML={{ __html: `
            div[class*="layout-"] .input-group.validation-error{
              border-color: ` + primaryColor + ` !important;
            }
            div[class*="layout-"] .input-group.validation-error .form-control,
            div[class*="layout-"] .validation-error-message{
              color: ` + primaryColor + ` !important;
            }
            div[class*="layout-"] .input-group.validation-error .svg-icon svg g [fill]{
              fill: ` + primaryColor + ` !important;
            }
          `}}
          />
        }
      </>
    )

  }

  render() {
    let {
      favicon,
      layout,
      company,
    } = this.state


    let content = null
    switch(layout){
      case "modern":    content = this._renderModern();   break;
      case "classic":   content = this._renderClassic();  break;
      case "retro":     content = this._renderRetro();    break;
      case "in-app":    content = this._renderInApp();    break;
      default:          content = this._renderModern()
    }

    return (
      <>
        { MetaTags.render(
          `${company.name}  | Payment Page`,
          `${company.name} payment page powered by ${window.General.Branding.Name}`,
          null,
          window.location.href,
          favicon)
        }
        { content }
      </>
    )
  }

}

const decimalToHex = (alpha: number) => alpha === 0 ? '00' : Math.round(255 * alpha).toString(16)

PageLayout.defaultProps = {
  showCaption: true,
  containerClassName: ''
}
