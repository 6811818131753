import React from 'react'

import General from '../../../utils/General'


export default class MobileSelect extends React.Component  {
  constructor(props){
    super(props)
    this.state = this._getState(props)
  }

  componentWillReceiveProps(nextProps){
    this.setState(this._getState(nextProps))
  }

  _getState(props){
    return {
      uuid: General.uuid(),
      value: props.value,
      disabled: props.disabled,
      className:props.className,
      options: props.options.map(option => (
        {
          label: this.props.getOptionLabel(option),
          value: this.props.getOptionValue(option),
          _data: option
        }
      ))
    }
  }

  render(){
    let {
      uuid,
      value,
      options,
      disabled,
      className
    } = this.state
    let props = { ...this.props }
    delete props.options
    delete props.getOptionLabel
    delete props.getOptionValue

    let mobileSelectClassName = "mobile-select";
    if(className!=undefined){
      mobileSelectClassName += " " + className;
    }

    let selectedOption = options.find(option => option.value === value)
    return (
      <div className={mobileSelectClassName}>

        <select
          id={uuid}
          value={selectedOption?.value}
          disabled={disabled}
          onChange={e => {
            let option = options.find(option => option.value == e.target.value)
            this.setState({ value: option.value })
            this.props.onSelected(option._data)
          }}
        >
          { options.map(option => {
              return (
                <option value={option.value}>
                  {option.label}
                </option>
              )
            })
          }
        </select>

        <div className="custom" htmlFor={uuid}>
          { this.props.prompt &&
            <div className="prompt">{this.props.prompt}</div>
          }
          <div className="select">
            { selectedOption?.label || "Please Select" }
          </div>
          <span className="material-icons icon">keyboard_arrow_down</span>
        </div>

      </div>
    )
  }
}
