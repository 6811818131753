import React from "react";

import {
  isSafari,
  isChrome,
  isAndroid,
} from 'react-device-detect';


import { Portal } from 'react-portal';

import { PaymentInputsContainer } from 'react-payment-inputs';

import Customer from "../Customer"

import PaymentMethodPortal from '../PaymentMethodPortal';

import LockInputAppendIcon from "../LockInputAppendIcon"

import FlinksModal from "./FlinksModal"
import InteracModal from "./InteracModal"
import BankInput from "./BankInput"

import Separator from "../../common/Separator"

import PayButton from "../button/PayButton"
import BankPayButton from "../button/BankPayButton"
import InteracPayButton from "./InteracPayButton"

import Backend from "../../../../utils/Backend"
import Currency from "../../../../utils/Currency";
import Name from "../../../../utils/Name";
import Notify from "../../../../utils/Notify";
import General from "../../../../utils/General";

export default class PaymentMethods extends React.Component{

  constructor(props) {
    super(props);

    let company = props.company

    this.state = {
      ...this._getState(props),
      loading: true,
      errors: []
    }

    this.customer = React.createRef()
    this.bankInput = React.createRef()
  }

  componentWillReceiveProps(nextProps) {
    this.setState(this._getState(nextProps))
  }

  componentDidMount(){
    setTimeout(() => {
      this.setState({ ready: true })
    }, 30)
  }

  _getState(nextProps){
    let {
      company,
      customer,
      paymentPlan,
      paymentMethods
    } = nextProps

    return {
      company,
      customer,
      paymentPlan,
      paymentMethods
    }
  }

  handleAction(response){
    throw { "error": "3DS", message: "Payment requires 3DS which is currently unsupported, please contact the merchant for assistance"}
  }

  isValid(scrollToError){
    let { transitNumber, institutionNumber, accountNumber, flinksData} = this.state

    let isValid = true
    if(this.customer.current && !this.customer.current.isValid(scrollToError)){
      scrollToError = false
      isValid = false
    }

    if(!flinksData && this.bankInput.current && !this.bankInput.current.isValid(scrollToError)){
      scrollToError = false
      isValid = false
    }

    return isValid
  }

  getPaymentDetails(paymentMethod){
    let {
      accountNumber,
      institutionNumber,
      transitNumber,
      flinksData
    } = this.state

    return new Promise((resolve,reject) => {

      resolve({
          paymentMethod,
          paymentDetails: {
            account_number: accountNumber,
            institution_number: institutionNumber,
            transit_number: transitNumber,
          }
        })
    })
  }

  _remove(selector, errors){
    let errorIndex = errors.findIndex(error => error.selector === selector)
    if(errorIndex > -1){
      errors.splice(errorIndex, 1)
    }

    return errors
  }

  _renderInterac(){
    let { showInteracModal, paymentMethods, paymentPlan } = this.state

    if(paymentPlan.type != "one_time"){
      return null
    }

    return (
      <PaymentMethodPortal type={"interac"}>
        <InteracPayButton
          onClick={e => {
            //this.setState({ showInteracModal: true })
            window.location = paymentMethods.interac.link
          }}
        />

        <InteracModal
          show={showInteracModal}
          link={paymentMethods.interac.link}
          onClose={() => this.setState({ showInteracModal: false })}
        />

      </PaymentMethodPortal>
    )
  }

  render() {

    let {
      company,
      customer,
      paymentPlan,
      paymentMethods,
      errors,
      accountNumber,
      institutionNumber,
      transitNumber,
      showFlinksModal,
      showInteracModal
    } = this.state

    if(!this.state.ready){
      return null
    }

    if(!paymentMethods.eft && paymentMethods.interac){
      return this._renderInterac()
    }

    return (
      <PaymentMethodPortal type="eft">

        <Customer
          ref={this.customer}
          customer={customer}
          collectEmail={true}
          collectName={true}
          namePlaceholder="Account Holder Name"
          onUpdated={customer => {
            this.props.onUpdatedCustomer(customer)
          }}
        />

        <BankInput
          ref={this.bankInput}
          advanced={true}
          company={company}
          onUpdated={bankDetails => {
            this.setState({ ...bankDetails })
          }}
        />

        <PaymentMethodPortal type="interac">
          <BankPayButton
            primaryColor={company.settings.primary_color}
            paymentPlan={paymentPlan}
            paymentMethod={paymentMethods.eft}
            onClick={e => {
              this.setState({ showFlinksModal: true })
            }}
          />
          { paymentMethods.interac?.link && this._renderInterac() }
        </PaymentMethodPortal>

        <PayButton
          payButtonTitle={this.props.payButtonTitle}
          payButtonContainerId={this.props.payButtonContainerId}
          backgroundColor={company.settings.primary_color}
          paymentPlan={this.state.paymentPlan}
          onClick={() => {
            this.props.onPayPressed(paymentMethods.eft)
          }}
        />

        <FlinksModal
          show={showFlinksModal}
          paymentMethod={paymentMethods.eft}
          onClose={() => this.setState({ showFlinksModal: false })}
          connectLink={paymentMethods.eft.data.flinks_connect_link}
          onConnected={bankDetails => {
            this.setState({
              accountNumber: bankDetails.account_number,
              institutionNumber: bankDetails.institution_number,
              transitNumber: bankDetails.transit_number,
              flinksData: true,
              showFlinksModal: false
            }, () => this.props.onPayPressed(paymentMethods.eft))
          }}
        />

      </PaymentMethodPortal>
    )
  }

}
