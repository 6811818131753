import React from 'react'
import Modal from 'react-bootstrap/Modal'

export default class InteracModal extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      link: props.link,
      show: props.show,
      error: props.error
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  componentDidMount(){
    this.interactListener = (e) => {
      let data = {}
    }
    window.addEventListener('message', this.interacListener);
  }

  componentWillUnmout(){
    window.removeEventListener('message', this.interacListener)
  }

  render() {
    let {
      show,
      error
    } = this.props

    return (
      <Modal
        show={show}
        onHide={() => {}}
        centered
        className={this.props.modalClassName}
      >
        <Modal.Body
          style={{ padding: 0 }}
        >
          <iframe
            height="600"
            width="100%"
            frameBorder="0"
            src={this.props.link}>
          </iframe>
        </Modal.Body>

        <Modal.Footer>
          <div className="w-100 text-center">
            <button
              type="button"
              className={`btn btn-secondary`}
              onClick={() => this.props.onClose()}
            >
              Cancel
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    )
  }
}

InteracModal.defaultProps = {
  modalClassName: ''
}
