import React from 'react'

export default class SepaPayButton extends React.Component {
  render(){
    return (
      <div className="text-center">
        <div
          onClick={() => this.props.onClick()}
          className="sepa-pay-button pay-buttons"
        >
          SEPA
        </div>
        <p className="payment-method-title">SEPA/IBAN</p>
      </div>
    )
  }
}

SepaPayButton.defaultProps = {
}
