
import AuthManager from '../utils/AuthManager'

export default class Intercom {
  static load(){
    if(!window.Api.Keys.IntercomAppId){
      return
    }

     window.intercomSettings = {
       app_id: window.Api.Keys.IntercomAppId,
       hide_default_launcher: true
     };

     var w = window;
     var ic = w.Intercom
     if(typeof ic==="function"){
       ic('reattach_activator');
       ic('update',w.intercomSettings);
     }
     else
     {
       var d=document;
       var i=function(){
         i.c(arguments);
       };
       i.q=[];
       i.c=function(args){
         i.q.push(args);
       };
       w.Intercom=i;
       var l=function(){
         var s=d.createElement('script');
         s.type='text/javascript';
         s.async=true;
         s.src=`https://widget.intercom.io/widget/${window.Api.Keys.IntercomAppId}`;
         var x=d.getElementsByTagName('script')[0];
         x.parentNode.insertBefore(s,x);
       };

       if(document.readyState === 'complete'){
         l()
       }
       else if(w.attachEvent){
         w.attachEvent('onload',l);
       }else{
         w.addEventListener('load',l,false);
       }
     }
  }

  static login(){
    Intercom.update()
  }

  static logout(){
    if (!!window.Intercom) {
      window.Intercom('shutdown')
    }
  }

  static pageChange(){
    Intercom.update()
  }

  static show(){
    if (!!window.Intercom) {
      window.Intercom('show')
    }
  }

  static update(){
    let url = window.location.hostname
    if(url.indexOf("splink.io") === -1 && url.indexOf("splinkdev.io") === -1){
      return
    }

    let data = {
        app_id: window.Api.Keys.IntercomAppId
    }

    if(AuthManager.currentUser){
      let currentUser = AuthManager.currentUser
      data = {
        ...data,
        user_id: `io_${currentUser.user.id}`,
        email: currentUser.user.email,
        name: `${currentUser.user.first_name} ${currentUser.user.last_name}`,
        rebuild: true
      }
    }

    if (!!window.Intercom) {
      window.Intercom('boot', data)
    }
  }

}
