import React from "react";

import LockInputAppendIcon from "./LockInputAppendIcon"

import Backend from "../../../utils/Backend";
import General from "../../../utils/General";
import Currency from "../../../utils/Currency";

import Address from "./Address"

import Email from "../../../utils/Email";
import Name from "../../../utils/Name";

export default class Customer extends React.Component{

  constructor(props) {
    super(props);

    this.state = this._getState(props)

    this.address = React.createRef()
  }

  componentWillReceiveProps(nextProps) {
    this.setState(this._getState(nextProps))
  }

  _getState(props){

    let customer = props.customer

    let name = customer.first_name || null
    if(name && customer.last_name){
      name += " "+customer.last_name
    }

    let data = {
      name,
      customer
    }

    let errors = this.state?.errors || props.errors
    if(errors){
      data.errors = errors
    }

    return data
  }

  isValid(scrollToError){
    let {
      customer,
    } = this.state

    let {
      collectEmail,
      collectName,
      collectBillingAddress
    } = this.props

    let errors = []
    let billingAddressValid = true

    if(collectBillingAddress && !this.address.current.isValid(scrollToError)){
      billingAddressValid = false
      scrollToError = false
    }
    if(collectEmail && !customer.email){
      errors.push({ selector: "#input-email",  message: "Please enter your email" })
    }
    else if(collectEmail && !Email.isValid(customer.email)){
      errors.push({ selector: "#input-email",  message: "Please enter a valid email" })
    }

    if(collectName && !customer.first_name){
      errors.push({ selector: "#input-name",  message: "Please enter your name" })
    }
    // apple pay doesn't require last name
    // else if(!customer.last_name){
    //   errors.push({ selector: "#input-name",  message: "Please enter your full name" })
    // }

    if(errors.length > 0 || !billingAddressValid){
      this.setState({ errors })
      if(scrollToError && billingAddressValid){
        General.scrollTo(errors[0].selector)
      }
      return false
    }

    return true
  }

  _onUpdated(customer, errors){
    this.setState({customer, errors}, () => {
      this.props.onUpdated(customer)
    })
  }

  _remove(selector, errors){
    let errorIndex = errors.findIndex(error => error.selector === selector)
    if(errorIndex > -1){
      errors.splice(errorIndex, 1)
    }

    return errors
  }


  render() {
    let {
      name,
      errors,
      customer
    } = this.state

    let { collectEmail, collectName, collectBillingAddress } = this.props

    let inputNameClassName = "input-group with-append"
    let inputEmailClassName = inputNameClassName

    let errorClassName = " validation-error"

    let inputNameError = null
    let inputEmailError = null

    errors = errors || []
    for(var i = 0; i < errors.length; i++){
      let error = errors[i]
      if(error.selector === "#input-name"){
        inputNameClassName += errorClassName
        inputNameError = error.message
      }
      if(error.selector === "#input-email"){
        inputEmailClassName += errorClassName
        inputEmailError = error.message
      }
    }

    return (
      <>
        { collectBillingAddress &&
          <Address
            ref={this.address}
            customer={customer}
            type="billing_address"
            requirePostalCode={true}
            onUpdated={customer => this.setState({ customer })}
          />
        }

        { collectEmail &&
          <div id="input-email" className="form-group mb-2">
            <div className={inputEmailClassName}>
              <input
                className="form-control h-auto border-0"
                type="text"
                placeholder="Email (receipt)"
                value={customer.email}
                onChange={e => {
                  customer.email = e.target.value.trim()
                  errors = this._remove("#input-email", errors)
                  this._onUpdated(customer, errors)
                }}
              />
              <LockInputAppendIcon/>
            </div>
            { inputEmailError &&
              <span className="validation-error-message">{ inputEmailError }</span>
            }
          </div>
        }

        { collectName &&
          <div id="input-name" className="form-group mb-2">
            <div className={inputNameClassName}>
              <input
                className="form-control h-auto border-0"
                type="text"
                placeholder={this.props.namePlaceholder}
                defaultValue={name}
                onChange={e => {

                  let fullName = e.target.value
                  customer.first_name = Name.getFirstName(fullName)
                  customer.last_name = Name.getLastName(fullName)

                  errors = this._remove("#input-name", errors)
                  this._onUpdated(customer, errors)
                }}
              />
              <LockInputAppendIcon/>
            </div>
            { inputNameError &&
              <span className="validation-error-message">{ inputNameError }</span>
            }
          </div>
        }

      </>

    )
  }

}

Customer.defaultProps = {
  collectEmail: true,
  collectName: true,
  collectBillingAddress: false,
  namePlaceholder: "Name on Card"
}
