import React from "react";

import { Portal } from 'react-portal';

import Customer from "../Customer"

import PaymentMethodPortal from '../PaymentMethodPortal';

import LockInputAppendIcon from "../LockInputAppendIcon"

import Separator from "../../common/Separator"

import PayButton from "../button/PayButton"
import DigitalWalletButtons from "../button/DigitalWalletButtons"
import BankPayButton from "../button/BankPayButton"

import ACHModal from "./ACHModal"
import CashDiscountModal from "./CashDiscountModal"

import Discount from "../../../../utils/Discount";
import Backend from "../../../../utils/Backend"
import Currency from "../../../../utils/Currency";
import AsyncStorage from "../../../../utils/AsyncStorage";
import General from "../../../../utils/General";
import Name from "../../../../utils/Name";
import PaymentMethodUtils from "../../../../utils/PaymentMethods";

const CARD_INPUTS_SINGLE_LINE = false

export default class PaymentMethods extends React.Component{

  constructor(props) {
    super(props);

    this.state = {
      ...this._getState(props),
      mode: null,
      loading: true,
      payButtonLoading: false
    }

    this.form = React.createRef()
    this.customer = React.createRef()
  }

  componentDidMount(){
    this._setupEventHandler()
    this._reloadTSEPScript()
    setTimeout(() => {
      let { paymentPlan, paymentMethods } = this.state
      let showCashDiscountModal = Discount.getAmount(paymentPlan, paymentMethods.ach) > 0
      this.setState({
        showCashDiscountModal,
        canRenderOtherPaymentMethod: !window.ReactNativeWebView
      })
    }, 1000)
  }

  componentWillReceiveProps(nextProps) {
    this.setState(this._getState(nextProps))
  }

  _getState(nextProps){
    let {
      company,
      customer,
      paymentPlan,
      paymentMethods
    } = nextProps

    return {
      company,
      customer,
      paymentPlan,
      paymentMethods
    }
  }

  handleAction(response){
    throw { error: "3DS", message: "Your card requires 3DS which is currently unsupported, please contact the merchant for assistance"}
  }

  isValid(scrollToError){
    let {
      token,
      customer,
      cardError,
      cardHolderEntered,
      paymentMethod,
      paymentMethods
    } = this.state

    let isValid = true

    if(this.customer?.current && !this.customer.current.isValid(scrollToError)){
      isValid = false
    }

    if(paymentMethod?.type === "card" && (!token || cardError)){
      isValid = false
      cardError = "Please check your card details and try again"
    }

    this.setState({ cardError })
    if(cardError && scrollToError){
      General.scrollTo("#tsep-cardNumDiv")
    }

    return isValid
  }

  getPaymentDetails(paymentMethod){
    let {
      paymentForm,
      token,
      expiration_date,
      cvv2,
      card_type,
      zip_code,
      accountNumber,
      routingNumber,
      paymentRequest
    } = this.state

    return new Promise((resolve,reject) => {
      resolve({
          paymentMethod,
          paymentDetails: {
            cvv2,
            zip_code,
            expiration_date,
            card_number: token,
            card_type: card_type,
            account_number: accountNumber,
            routing_number: routingNumber,
            encrypted_data: paymentRequest
          }
        })
    })
  }

  _reloadTSEPScript(){
    let { paymentMethods } = this.state

    var existingScript = document.querySelector('#tsep')
    if(existingScript){
      existingScript.parentNode.removeChild(existingScript)
    }

    let tsepUrl = PaymentMethodUtils.getDefault(paymentMethods).data.tsep_url

    const script = document.createElement('script')

    script.setAttribute("id", "tsep")
    script.src = tsepUrl

    document.body.appendChild(script)
  }

  _setupEventHandler(){
    window.tsepHandler = (eventType, event) => {
      this.setState({ cardError: null })

      if(eventType === "FieldValidationErrorEvent"){
        this.setState({ token: null, cardError:   event.message})
      }
      else if(eventType === "FieldValidationSuccessEvent"){
        this.setState({ cardError: null })
      }
      else if(eventType === "TokenEvent"){
        this._handleTokenization(event)
      }
    }
  }

  _handleTokenization(event){
    this._reloadTSEPScript()

    if(event.status === "FAIL" || event.status === "FAILURE"){
      this.setState({ cardError: "Please check your card details and try again" })
      return
    }

    this.setState({
      token: event.tsepToken,
      card_type: event.cardType,
      cvv2: event.cvv2?.trim(),
      zip_code: event.zipCode,
      expiration_date: event.expirationDate
    })
  }

  _renderCardInputs(){
    let { cardError } = this.state

    if(CARD_INPUTS_SINGLE_LINE){
      return (
        <>
          {/* Starts, Card */}
          <div className="form-group mb-2">
            <div className={`input-group with-append ${cardError ? "validation-error" : ""}`}>

              <div className="form-control h-auto border-0">

                <div className="row">
                  <div id="tsep-cardNumDiv" className="col-4" data-auto-formatting="Y"/>
                  <div id="tsep-datepickerDiv" className="col-3"/>
                  <div id="tsep-cvv2Div" className="col-2"/>
                  <div id="tsep-zipCodeDiv" className="col-3"/>
                </div>
              </div>

              <LockInputAppendIcon/>

            </div>

          </div>
          { cardError &&
            <span className="validation-error-message mb-3">{ cardError }</span>
          }
          {/* end, Card */}
        </>
      )
    }

    return (
      <>
        {/* Starts, Card */}
        <div className="form-group mb-2">
          <div className={`input-group with-append ${cardError ? "validation-error" : ""}`}>

            <div className="form-control h-auto border-0">
              <div id="tsep-cardNumDiv" data-auto-formatting="Y"/>
            </div>

            <LockInputAppendIcon/>

          </div>

        </div>
        { cardError &&
          <span className="validation-error-message mb-3">{ cardError }</span>
        }
        {/* end, Card */}
        {/* Starts, Expiry, cvv, zip */}
        <div className="form-group mb-2">
          <div className={`input-group with-append ${cardError ? "validation-error" : ""}`}>

            <div className="form-control h-auto border-0">
              <div className="row">
                <div id="tsep-datepickerDiv" className="col-4"/>
                <div id="tsep-cvv2Div" className="col-4"/>
                <div id="tsep-zipCodeDiv" className="col-4"/>
              </div>
            </div>

            <LockInputAppendIcon/>

          </div>

        </div>
        { cardError &&
          <span className="validation-error-message mb-3">{ cardError }</span>
        }
        {/* end, Expiry, cvv, zip */}
      </>
    )
  }

  render() {

    let {
      mode,
      company,
      customer,
      loading,
      paymentPlan,
      paymentMethods,
      cardError,
      cardHolderNameError,
      canRenderOtherPaymentMethod,
      showACHModal,
      routingNumber,
      accountNumber,
      payButtonLoading,
      showCashDiscountModal
    } = this.state


    let accountHolderName = customer.first_name
    if(customer.last_name){
      accountHolderName += " " + customer.last_name
    }

    let payByCardPrompt = canRenderOtherPaymentMethod ? "Or Enter Payment Details" : "Enter Payment Details"

    let cardPaymentMethod = paymentMethods.card
    return (
      <>
        { canRenderOtherPaymentMethod &&
          <>

            { mode !== "card" &&
              <PaymentMethodPortal type="ach">
                <BankPayButton
                  primaryColor={company.settings.primary_color}
                  paymentPlan={this.state.paymentPlan}
                  paymentMethod={paymentMethods.ach}
                  title="Pay By ACH"
                  onClick={e => {
                    this.setState({ showACHModal: true })
                  }}
                />
              </PaymentMethodPortal>
            }
            { (paymentPlan.total && mode !== "ach") &&
              <PaymentMethodPortal type="digital_wallet">
                <DigitalWalletButtons
                  company={company}
                  paymentPlan={paymentPlan}
                  gateway={'tsys'}
                  googleMerchantId={"merchant.io.splink"}
                  appleMerchantId={"merchant.io.splink"}
                  onShowSuccess={(result, resolve, reject) => {
                    // make the payment
                    if(result.details){
                      customer.email = customer.email || result.payerEmail

                      if(!customer.first_name){
                        customer.first_name = Name.getFirstName(result.payerName)
                        customer.last_name = Name.getLastName(result.payerName)
                      }
                      this.props.onUpdatedCustomer(customer)

                      console.log("DDDD", result.details)
                      this.setState({
                        paymentMethod: paymentMethods.digital_wallet
                      }, () => this.props.onPayPressed(paymentMethods.digital_wallet, {
                        encrypted_data: result.details
                      }))
                      resolve()
                    }
                    else {
                      reject()
                    }
                  }}
                />
              </PaymentMethodPortal>
            }
          </>
        }

        { mode !== "ach" &&
          <>
            { cardPaymentMethod &&
              <PaymentMethodPortal type="card">
                <div className="components payment tsys payment-methods">

                  <Customer
                    ref={this.customer}
                    customer={customer}
                    collectEmail={true}
                    collectName={true}
                    collectBillingAddress={cardPaymentMethod.data.billing_address_required}
                    onUpdated={customer => {
                      this.props.onUpdatedCustomer(customer)
                    }}
                  />

                  <form id="payment-form"
                    className={`form-tsys format-${company.settings.layout}`}
                  >

                    { this._renderCardInputs() }

                  </form>

                  <PayButton
                    payButtonTitle={this.props.payButtonTitle}
                    payButtonContainerId={this.props.payButtonContainerId}
                    backgroundColor={company.settings.primary_color}
                    paymentPlan={this.state.paymentPlan}
                    loading={payButtonLoading}
                    onClick={() => {
                      this.setState({
                        paymentMethod: cardPaymentMethod,
                        payButtonLoading: true,
                      }, () => {
                        // give time for card tokenization
                        setTimeout(() => {
                          this.setState({ payButtonLoading: false })
                          this.props.onPayPressed(cardPaymentMethod)
                        }, 3000)
                      })
                    }}
                  />
                </div>
              </PaymentMethodPortal>
            }
          </>
        }

        { showACHModal &&
          <ACHModal
            show={showACHModal}
            onClose={() => this.setState({ showACHModal: false })}
            name={accountHolderName}
            email={customer.email}
            routingNumber={routingNumber}
            accountNumber={accountNumber}
            onPayPressed={(name, email, routingNumber, accountNumber) => {
              let { customer } = this.state

              customer.email = email
              customer.first_name = Name.getFirstName(name)
              customer.last_name = Name.getLastName(name)

              this.props.onUpdatedCustomer(customer)

              this.setState({
                routingNumber,
                accountNumber,
                showACHModal: false,
                paymentMethod: paymentMethods.ach
              }, () => this.props.onPayPressed(paymentMethods.ach))
            }}
          />
        }

        { showCashDiscountModal &&
          <CashDiscountModal
            show={showCashDiscountModal}
            company={company}
            paymentPlan={paymentPlan}
            paymentMethods={paymentMethods}
            onClose={() => {}}
            onCardPressed={() => {
              this.setState({ mode: "card", showCashDiscountModal: false })
            }}
            onACHPressed={() => {
              this.setState({ mode: "ach", showCashDiscountModal: false })
            }}
          />
        }
      </>
    )
  }

}
