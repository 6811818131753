import React from 'react';
import { Helmet } from 'react-helmet';

export default class MetaTags {
  static render(title, description, keywords, url, imageUrl, type){

    let children = []
    children.push(<meta name="twitter:card" content="summary_large_image" />)

    if(title){
      children.push(<meta property="og:title" content={title} />)
      children.push(<meta name="twitter:title" content={title} />)
    }

    if(description){
      children.push(<meta name="description" content={description} />)
      children.push(<meta property="og:description" content={description} />)
      children.push(<meta name="twitter:description" content={description} />)
    }

    if(keywords){
      children.push(<meta name="keywords" content={keywords} />)
    }

    if(imageUrl){
      children.push(<link rel="icon" href={ imageUrl }/>)
      children.push(<meta property="og:image" content={imageUrl} />)
      children.push(<meta name="twitter:image" content={imageUrl} />)
    }

    if(url){
      children.push(<meta property="og:url" content={url} />)
    }

    if(url){
      children.push(<meta property="og:type" content={type} />)
    }

    return (
      <Helmet>
        { children }
      </Helmet>
    )
  }
}
