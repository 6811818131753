import runtimeEnv from '@mars/heroku-js-runtime-env';


export default function(){

    const env = runtimeEnv();

    window.Api = {};

    window.Api.Base = env.REACT_APP_API_BASE

    window.Api.User = {};

    window.Api.User.Base = window.Api.Base + "/user"

    window.Api.User.Login                 = window.Api.User.Base + "/login"
    window.Api.User.Logout                = window.Api.User.Base + "/logout"
    window.Api.User.Info                  = window.Api.User.Base + "/info"
    window.Api.User.RequestResetPassword  = window.Api.User.Base + "/requestResetPassword"
    window.Api.User.ResetPassword         = window.Api.User.Base + "/resetPassword"
    window.Api.User.RefreshToken          = window.Api.User.Base + "/login/refresh"

    window.Api.Splinks                    = window.Api.Base + "/splinks"
    window.Api.Payments                   = window.Api.Base + "/payments"
    window.Api.ReverseFeeCalc             = window.Api.Base + "/reverse-fee-calc"
    window.Api.Subscriptions              = window.Api.Base + "/subscriptions"
    window.Api.Companies                  = window.Api.Base + "/companies"
    window.Api.CompanySubscriptions       = window.Api.Base + "/company-subscriptions"
    window.Api.Sectors                    = window.Api.Base + "/sectors"
    window.Api.PaymentMethods             = window.Api.Base + "/payment-methods"
    window.Api.PlatformPaymentMethods     = window.Api.Base + "/platform-payment-methods"
    window.Api.Branding                   = window.Api.Base + "/branding"
    window.Api.Connect                    = window.Api.Base + "/connect"
    window.Api.Currencies                 = window.Api.Base + "/currencies"
    window.Api.Invoices                   = window.Api.Base + "/invoices"

    window.Api.Keys = {}

    window.Api.Keys.IntercomAppId         = env.REACT_APP_INTERCOM_ID

    window.Api.RecaptchaSiteKey           = env.REACT_APP_RECAPTCHA_SITE_KEY

    window.Api.GooglePayLiveEnv           = env.REACT_APP_GOOGLE_PAY_LIVE_ENV

    window.Api.FlinksLiveEnv              = env.REACT_APP_FLINKS_LIVE_ENV
}
