
export default class PaymentAttempt {
  static getState(){

    try {
      let savedState = null
      let stripeState = PaymentAttempt._getStripeState()
      if(stripeState){
        savedState = stripeState
      }

      let aibState = PaymentAttempt._getAibState()
      if(aibState){
        savedState = aibState
      }

      let empState = PaymentAttempt._getEMPState()
      if(empState){
        savedState = empState
      }

      let boipaState = PaymentAttempt._getBoipaState()
      if(boipaState){
        savedState = boipaState
      }

      let tokenState = PaymentAttempt._getTokenState()
      if(tokenState){
        savedState = tokenState
      }

      localStorage.removeItem("payment_data")
      return savedState
    }
    catch(error){
      let message = "Unexpected error occured please contact support"
      return { error: { message }, showErrorModal: true }
    }
  }

  static _getStripeState(){
    let url = new URL(window.location.href);
    let params = new URLSearchParams(url.search);

    if(params.get("bacs")){
      return this._getStripeBACsState(url, params)
    }
    if(!params.get("payment_intent_client_secret")){
      return null
    }

    params.delete("source_type")
    params.delete("payment_intent")
    params.delete("redirect_status")
    params.delete("payment_intent_client_secret")

    let pathName = window.location.pathname
    if(params.toString()){
      pathName += "?" + params.toString()
    }
    window.history.replaceState(null, window.document.title, pathName)

    let paymentData = localStorage.getItem("payment_data")
    if(!paymentData){
      return null
    }
    paymentData = JSON.parse(paymentData)
    paymentData._savedPaymentMethod = paymentData.paymentMethods.klarna
    return paymentData
  }

  static _getStripeBACsState(url, params){

    if(!params.get("payment_intent")){
      return null
    }
    params.delete("bacs")
    params.delete("payment_intent")
    params.delete("redirect_status")
    params.delete("payment_intent_client_secret")

    let pathName = window.location.pathname
    if(params.toString()){
      pathName += "?" + params.toString()
    }
    window.history.replaceState(null, window.document.title, pathName)

    let paymentData = localStorage.getItem("payment_data")
    if(!paymentData){
      return null
    }
    paymentData = JSON.parse(paymentData)
    paymentData._savedPaymentMethod = paymentData.paymentMethods.bacs
    return paymentData
  }

  static _getAibState(){
    const aibACSResponse = window._aibACSResponse
    const res = aibACSResponse?.res
    const type = aibACSResponse?.type === "c" ? "cres" : "pares"
    const md = aibACSResponse?.md

    if(!res){
      return
    }

    let paymentData = localStorage.getItem("payment_data")
    if(!paymentData){
      return null
    }

    paymentData = JSON.parse(paymentData)

    paymentData.paymentDetails[type] = res
    paymentData.paymentDetails.md = md
    paymentData._savedPaymentMethod = paymentData.paymentMethods.card

    return paymentData
  }

  static _getEMPState(){
    let url = new URL(window.location.href)
    let params = new URLSearchParams(url.search)

    let eMerchantpayStatus = params.get("empstatus")

    if(eMerchantpayStatus == null){
      return
    }

    params.delete("empstatus")
    let pathName = window.location.pathname
    if(params.toString()){
      pathName += "?" + params.toString()
    }
    window.history.replaceState(null, window.document.title, pathName)

    let paymentData = localStorage.getItem("payment_data")
    if(!paymentData){
      return null
    }
    paymentData = JSON.parse(paymentData)
    paymentData._savedPaymentMethod = paymentData.paymentMethods.card
    return paymentData
  }

  static _getBoipaState(){
    let url = new URL(window.location.href)
    let params = new URLSearchParams(url.search)

    let merchantTxId = params.get("merchantTxId")

    if(merchantTxId == null){
      return
    }

    let result = params.get("result")

    params.delete("merchantTxId")
    params.delete("txId")
    params.delete("currency")
    params.delete("amount")
    params.delete("result")

    let pathName = window.location.pathname
    if(params.toString()){
      pathName += "?" + params.toString()
    }
    window.history.replaceState(null, window.document.title, pathName)

    let paymentData = localStorage.getItem("payment_data")
    if(!paymentData){
      return null
    }
    paymentData = JSON.parse(paymentData)

    // set both values to ensure a previous failure
    // is cleared
    if(result == "success"){
      paymentData._boipaSuccessResult = true
      paymentData._boipaFailureResult = false
    }
    else{
      paymentData._boipaFailureResult = true
      paymentData._boipaSuccessResult = false
    }
    return paymentData
  }

  static _getTokenState(){
    let url = new URL(window.location.href)
    let params = new URLSearchParams(url.search)

    let memberId = params.get("member-id")

    if(memberId == null){
      return
    }

    let paymentId = params.get("payment-id")

    let state = params.get("state")
    let status = params.get("status")

    params.delete("member-id")
    params.delete("payment-id")
    params.delete("state")
    params.delete("status")

    let pathName = window.location.pathname
    if(params.toString()){
      pathName += "?" + params.toString()
    }
    window.history.replaceState(null, window.document.title, pathName)

    let paymentData = localStorage.getItem("payment_data")
    if(!paymentData){
      return null
    }
    paymentData = JSON.parse(paymentData)

    let { sepa, sepa_instant, faster_payments } = paymentData.paymentMethods

    let paymentMethodId = paymentData.paymentDetails.payment_method_id
    paymentData._savedPaymentMethod = null
    if(sepa?.id == paymentMethodId){
      paymentData._savedPaymentMethod = sepa
    }
    else if(sepa_instant?.id == paymentMethodId){
      paymentData._savedPaymentMethod = sepa_instant
    }
    else if(faster_payments?.id == paymentMethodId){
      paymentData._savedPaymentMethod = faster_payments
    }

    paymentData.paymentDetails.payment_id = paymentId
    paymentData.paymentDetails.member_id = memberId
    paymentData.paymentDetails.state = state

    return paymentData
  }
}
