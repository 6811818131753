import React from "react";

import {
  isSafari,
  isChrome,
  isAndroid,
} from 'react-device-detect';


import { Portal } from 'react-portal';

import Customer from "../Customer"

import LockInputAppendIcon from "../LockInputAppendIcon"

import BankAuthModal from "./BankAuthModal"
import BankSelectionModal from "./BankSelectionModal"
import BankDecoupledAuthModal from "./BankDecoupledAuthModal"

import Separator from "../../common/Separator"

import PayButton from "../button/PayButton"
import BankPayButton from "../button/BankPayButton"

import PaymentMethodUtils from "../../../../utils/PaymentMethods";

import PaymentMethodPortal from '../PaymentMethodPortal';

import Backend from "../../../../utils/Backend"
import Currency from "../../../../utils/Currency";
import Name from "../../../../utils/Name";
import Notify from "../../../../utils/Notify";
import General from "../../../../utils/General";

export default class PaymentMethods extends React.Component{

  constructor(props) {
    super(props);

    let company = props.company

    this.state = {
      ...this._getState(props),
      loading: true,
      errors: []
    }

    this.customer = React.createRef()
    this.bankInput = React.createRef()
  }

  componentWillReceiveProps(nextProps) {
    this.setState(this._getState(nextProps))
  }

  componentDidMount(){
    setTimeout(() => {
      this.setState({ ready: true })
    }, 30)
  }

  _getState(nextProps){
    let {
      company,
      customer,
      paymentPlan,
      paymentMethods,
      paymentAttemptId
    } = nextProps

    let processor = PaymentMethodUtils.getDefault(paymentMethods).processor

    return {
      company,
      customer,
      processor,
      paymentPlan,
      paymentMethods,
      paymentAttemptId
    }
  }

  handleAction(response){
    if(response.redirect_url){
      return new Promise((resolve, reject) => {
        window.location.href = response.redirect_url
      })
    }
    if(response.authentication){
      return new Promise((resolve, reject) => {
        this.setState({
          bankAuth: {
            reject,
            resolve,
            paymentId: response.payment_id,
            fields: response.authentication.fields,
            description: response.authentication.description,
          },
          showBankAuthenticationModal: true
        })
      })
    }
    if(response.action == "pending_decoupled_auth" || response.action == "pending"){
      return new Promise((resolve, reject) => {
        this.setState({
          bankDecoupledAuth: {
            reject,
            resolve,
            paymentId: response.payment_id,
          },
          showBankDecoupledAuthenticationModal: true
        })
      })
    }
    else{
      throw { "error": "3DS", message: "Unsupported SCA requests, please contact support for assistance"}
    }
  }

  isValid(scrollToError){

    let isValid = true
    if(this.customer.current && !this.customer.current.isValid(scrollToError)){
      scrollToError = false
      isValid = false
    }

    return isValid
  }

  getPaymentDetails(paymentMethod){
    let {
      bank,
      creditor,
      embeddedAuth
    } = this.state

    return new Promise((resolve,reject) => {
      let paymentDetails = {
        ...creditor,
        bank_id: bank?.id,
        payment_method_id: paymentMethod.id,
        initial_embedded_auth: embeddedAuth,
        callback_url: window.location.href
      }

      this.props.onSaveState(paymentDetails)

      resolve({
        paymentMethod,
        paymentDetails
      })
    })
  }

  _remove(selector, errors){
    let errorIndex = errors.findIndex(error => error.selector === selector)
    if(errorIndex > -1){
      errors.splice(errorIndex, 1)
    }

    return errors
  }

  _supportedPaymentMethod(){
    let { paymentMethods } = this.state
    return paymentMethods.sepa || paymentMethods.sepa_instant || paymentMethods.faster_payments
  }

  render() {

    let {
      iban,
      company,
      customer,
      paymentPlan,
      paymentMethods,
      errors,
      bankAuth,
      processor,
      creditor,
      paymentAttemptId,
      bankDecoupledAuth,
      showBankSelectionModal,
      showBankAuthenticationModal,
      showBankDecoupledAuthenticationModal
    } = this.state

    if(paymentPlan.type == "recurring" || !this._supportedPaymentMethod()){
      return null
    }

    return (
      <>

        <PaymentMethodPortal type={this._supportedPaymentMethod().type}>
          <BankPayButton
            primaryColor={company.settings.primary_color}
            paymentPlan={paymentPlan}
            paymentMethod={this._supportedPaymentMethod()}
            onClick={e => {
              this.setState({ showBankSelectionModal: true })
            }}
          />
        </PaymentMethodPortal>

        <BankSelectionModal
          show={showBankSelectionModal}
          processor={processor}
          company={company}
          customer={customer}
          paymentPlan={paymentPlan}
          paymentMethods={paymentMethods}
          paymentAttemptId={paymentAttemptId}
          onClose={() => this.setState({ showBankSelectionModal: false })}
          onSelected={(bank, creditor, customer, embeddedAuth, paymentMethod) => {
            this.props.onUpdatedCustomer(customer)
            this.setState({
              bank,
              creditor,
              embeddedAuth,
              paymentMethod,
              showBankSelectionModal: false,
            }, () => this.props.onPayPressed(paymentMethod))
          }}
          onCancel={bank => {
            this.setState({
              showBankSelectionModal: false
            })
          }}
        />

        { bankAuth &&
          <BankAuthModal
            show={showBankAuthenticationModal}
            fields={bankAuth.fields}
            description={bankAuth.description}
            onContinue={(data) => {
              this.setState({
                showBankAuthenticationModal: false
              })
              let auth = {
                ...creditor,
                embedded_auth: data,
                bank_id: this.state.bank?.id,
                payment_id: bankAuth.paymentId,
                callback_url: window.location.href,
                payment_method_id: this.state.paymentMethod?.id
              }
              console.log("***", auth)
              bankAuth.resolve(auth)
            }}
            onCancel={() => {
              this.setState({
                showBankAuthenticationModal: false
              })
              bankAuth.reject()
            }}
          />
        }

        { bankDecoupledAuth &&
          <BankDecoupledAuthModal
            show={showBankDecoupledAuthenticationModal}
            onContinue={() => {
              this.setState({
                showBankDecoupledAuthenticationModal: false
              })
              let data = {
                ...creditor,
                bank_id: this.state.bank?.id,
                payment_id: bankDecoupledAuth.paymentId,
                callback_url: window.location.href,
                payment_method_id: this.state.paymentMethod?.id
              }
              bankDecoupledAuth.resolve(data)
            }}
            onCancel={() => {
              this.setState({
                showBankDecoupledAuthenticationModal: false
              })
              bankDecoupledAuth.reject()
            }}
          />
        }

      </>
    )
  }

}
