import React from 'react';
import ContentLoader from "react-content-loader";

export default class LoadingPage extends React.Component {

  render() {
    return (
      <section className="d-flex justify-content-center min-vh-100 align-items-center">
          
        <ContentLoader 
          speed={1}
          width={450}
          height={890}
          viewBox="0 0 400 602"
          // backgroundColor="#B0B0B0"
          // foregroundColor="#D0D0D0"
        >
          <circle cx="571" cy="201" r="20" /> 
          <circle cx="200" cy="120" r="37" /> 
          <rect x="1" y="125" rx="20" ry="20" width="398" height="500" />
        </ContentLoader>
         
      </section>
    )
  }

}