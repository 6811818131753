import bigDecimal from 'js-big-decimal'

export default class Discount {

  static get(paymentPlan, paymentMethod){

    if(paymentMethod.processor.indexOf("tsys") == -1){
      return null
    }
    if(!paymentPlan.allow_discounts){
      return null
    }

    for(var i = 0; i < paymentPlan.discounts.length; i++){
      let discount = paymentPlan.discounts[i]
      for(var j = 0; j < discount.conditions.length; j++){
        let condition = discount.conditions[j]
        if(condition.payment_plan && condition.payment_plan != paymentPlan.id){
          continue
        }
        if(condition.payment_method && condition.payment_method != paymentMethod.id){
          continue
        }
      }
      return discount
    }

    return null
  }

  static getAmount(paymentPlan, paymentMethod){
    let discount = Discount.get(paymentPlan, paymentMethod)
    if(!discount || !paymentPlan.total){
      return 0
    }

    if(discount.percentage_off){
      let discountedAmount = bigDecimal.multiply(paymentPlan.subtotal, discount.percentage_off)
      return parseInt(bigDecimal.round(discountedAmount, 0, bigDecimal.RoundingModes.HALF_UP))
    }
    else if(discount.amount_off){
      let discountAmount = discount.amount_off
      if(discount.amount_off > paymentPlan.total - 100){
        discountAmount = paymentPlan.total - 100
      }
      if(discountAmount < 0){
        discountAmount = 0
      }
      return discount.amount_off
    }

    return 0
  }

  static getDiscountedTotal(paymentPlan, paymentMethod){

    if(!paymentPlan.subtotal){
      return 0
    }
    let discountAmount = Discount.getAmount(paymentPlan, paymentMethod)
    let subtotal =  paymentPlan.subtotal - discountAmount

    let vatPercentage = bigDecimal.divide(paymentPlan.vat.toString(), paymentPlan.subtotal.toString())
    let vat = bigDecimal.multiply(subtotal, vatPercentage)
    vat = parseInt(bigDecimal.round(vat, 0, bigDecimal.RoundingModes.HALF_UP))

    return subtotal + vat
  }
}
