import React from "react";

import CardModal from "./CardModal"

import Customer from "../Customer"

import PayButton from "../button/PayButton"

import Separator from "../../common/Separator"
import CountrySelect from "../../common/CountrySelect"

import PaymentMethodPortal from '../PaymentMethodPortal';

import Backend from "../../../../utils/Backend"
import General from "../../../../utils/General"
import AsyncStorage from "../../../../utils/AsyncStorage";

import {
  isMobile,
} from 'react-device-detect';

export default class PaymentMethods extends React.Component{

  constructor(props) {
    super(props);

    this.state = {
      ...this._getState(props),
      loading: true
    }

    this.form = React.createRef()
    this.customer = React.createRef()
    this.cardModal = React.createRef()
  }

  componentWillReceiveProps(nextProps) {
    this.setState(this._getState(nextProps))
  }

  _getState(nextProps){
    let {
      company,
      customer,
      paymentPlan
    } = nextProps

    let paymentMethod = company.payment_methods.card

    return {
      company,
      customer,
      paymentPlan,
      paymentMethod
    }

  }

  componentDidMount() {
    let paymentMethod = this.state.company.payment_methods.card
    General.loadScript(paymentMethod.data.javascript_form_url)
  }

  handleAction(response){
    let { paymentMethod, paymentPlan } = this.state
    if(response.token) {
      this.props.onSaveState()
      return this.cardModal.current._setupPaymentForm(response.token, paymentMethod, paymentPlan)
    }
  }

  _getTermUrl(){
    return window.location.href
  }

  isValid(scrollToError){
    let { customer } = this.state
    let error = false

    if(!customer.billing_address?.country_short){
      this.setState({ countryError: { message:  "Please select a country" } })
      if(scrollToError){
        General.scrollTo("#input-address-country")
      }
      scrollToError = false
      error = true
    }

    if(this.customer.current && !this.customer.current.isValid(scrollToError)){
      error = true
    }


    return !error
  }

  getPaymentDetails(paymentMethod){
    let { company, customer } = this.state
    return new Promise((resolve,reject) => {
      let originUrl = window.location.href
      return resolve({
        paymentMethod: company.payment_methods.card,
        paymentDetails: {
          tokenize: true,
          customer: customer,
          origin_url: "*",
          return_url: originUrl,
          browser: this._getBrowserData(),
        }
      })
    })
  }

  _getBrowserData(){
    return {
      user_agent: window.navigator.userAgent,
      user_device: isMobile ? "MOBILE" : "DESKTOP",
      language: window.navigator.language,
    }
  }

  _renderCountryPicker(){
    let { data, customer, countryError } = this.state
    let className = "input-group"

    if(countryError){
      className += " validation-error"
    }

    return (
      <div id="input-address-country" className="form-group mb-2">
        <div className={className}>
          <CountrySelect
            value={customer.billing_address?.country_short}
            className={className}
            onSelected={country => {
              customer.billing_address = {
                country_short: country.value
              }
              this.setState({ countryError: null }, () => {
                this.props.onUpdatedCustomer(customer)
              })
            }}
          />
        </div>
        { countryError &&
          <span className="validation-error-message">{ countryError.message }</span>
        }
      </div>
    )
  }

  render() {

    let {
      company,
      customer,
      loading,
      paymentMethod,
      token
    } = this.state

    return (
      <PaymentMethodPortal type="card">
        <div className="components payment boi payment-methods">

          { this._renderCountryPicker() }

          <Customer
            ref={this.customer}
            customer={customer}
            collectName={false}
            collectBillingAddress={false}
            collectEmail={this.props.collectEmail}
            onUpdated={customer => {
              this.props.onUpdatedCustomer(customer)
            }}
          />

          <PayButton
            payButtonTitle={"Enter Card Details"}
            payButtonContainerId={this.props.payButtonContainerId}
            backgroundColor={company.settings.primary_color}
            paymentPlan={this.state.paymentPlan}
            onClick={() => {
              this.props.onPayPressed(paymentMethod)
            }}
          />

          <CardModal
            ref={this.cardModal}
            modalClassName={company.settings.layout}
            onClose={() => this.setState({ token: null })}
          />

        </div>
      </PaymentMethodPortal>
    )
  }

}
