import FetchHelper from './FetchHelper'
import AuthManager from './AuthManager'
import AsyncStorage from './AsyncStorage'
import General from './General'
import Discount from './Discount'

export default class Backend {

  static getSplink(companySlug, authToken, paymentAttemptId){
    let url = `${window.Api.Splinks}/${companySlug}?auth_token=${authToken}`
    if(paymentAttemptId){
      url += "&payment_attempt_id=" + paymentAttemptId
    }
    return FetchHelper.get(url)
  }

  static getShop(companySlug, paymentAttemptId){
    let url = `${window.Api.Splinks}/${companySlug}/shop`
    if(paymentAttemptId){
      url += "?payment_attempt_id=" + paymentAttemptId
    }
    return FetchHelper.get(url)
  }

  static getPaymentLink(companySlug, paymentLinkSlug, paymentAttemptId){
    let url = `${window.Api.Splinks}/${companySlug}/payment-links/${paymentLinkSlug}`
    if(paymentAttemptId){
      url += "?payment_attempt_id=" + paymentAttemptId
    }
    return FetchHelper.get(url)
  }

  static getPaymentRequest(companySlug, paymentRequestSlug, paymentAttemptId){
    let url =`${window.Api.Splinks}/${companySlug}/payment-requests/${paymentRequestSlug}`
    if(paymentAttemptId){
      url += "?payment_attempt_id=" + paymentAttemptId
    }
    return FetchHelper.get(url)
  }

  static getPaymentLinks(companySlug){
    return FetchHelper.get(`${window.Api.Splinks}/${companySlug}/payment-links`)
  }

  static makePayment(company, paymentPlan, paymentMethod, paymentDetails, customer, answers, description, paymentAttemptId, recaptcha){
    let paymentPlanData = General.clone(paymentPlan)
    paymentPlanData.currency = paymentPlanData.currency.id

    let discount = Discount.get(paymentPlan, paymentMethod)
    let discountAmount = Discount.getAmount(paymentPlan, paymentMethod)
    let discountedTotal = Discount.getDiscountedTotal(paymentPlan, paymentMethod)
    if(discount && discountAmount && discountedTotal){
      paymentPlanData = {
        ...paymentPlanData,
        discount: discount.id,
        discount_amount: discountAmount,
        discounted_total: discountedTotal
      }
    }

    let data = {
      answers,
      customer,
      description,
      recaptcha,
      company: company.id,
      payment_plan: paymentPlanData,
      payment_method: paymentMethod.id,
      payment_details: paymentDetails,
      payment_attempt_id: paymentAttemptId
    }
    if (window.ReactNativeWebView) {
      data.payment_details.merchant_initiated_transaction = true
    }
    return FetchHelper.post(`${window.Api.Payments}`, data)
  }

  static payInvoice(invoice, authToken){
    let data = { invoice_id: invoice.id }
    return FetchHelper.post(`${window.Api.Invoices}/${invoice.id}/payments?subscription_auth_token=${authToken}`, data)
  }

  static reverseFeeCalc(amount, company, paymentPlan, paymentMethod){
    let url = `${window.Api.ReverseFeeCalc}?company_id=${company.id}&amount=${amount}`
    url += `&payment_plan_id=${paymentPlan.id}&payment_method_id=${paymentMethod.id}`
    return FetchHelper.get(url)
  }

  static getSubscription(companySlug, initialPaymentId, authToken){
    return FetchHelper.get(`${window.Api.Subscriptions}/${companySlug}/${initialPaymentId}/${authToken}?objects=all`)
  }

  static cancelSubscription(subscription, authToken){
    let id = subscription.initial_payment.id
    let company = subscription.initial_payment.company
    return FetchHelper.delete(`${window.Api.Subscriptions}/${company.slug}/${id}/${authToken}`)
  }

  static changePaymentInstrument(subscription, authToken, data){
    let id = subscription.initial_payment.id
    let company = subscription.initial_payment.company
    return FetchHelper.patch(`${window.Api.Subscriptions}/${company.slug}/${id}/${authToken}`, data)
  }

  static slugify(name){
    return FetchHelper.post(`${window.Api.Splinks}/slugify`, { name }, false, false)
  }

  static activate(company, data){
    return FetchHelper.patch(`${window.Api.Companies}/${company.id}?mode=partial`, data);
  }

  static purchaseSubscription(company, authToken, data){
    return FetchHelper.patch(`${window.Api.CompanySubscriptions}/${company.id}?auth_token=${authToken}`, data)
  }

  static getPaymentMethod(id){
    return FetchHelper.get(`${window.Api.PaymentMethods}/${id}`)
  }

  static getPlatformPaymentMethods(){
    let url = window.location.hostname
    return FetchHelper.get(`${window.Api.PlatformPaymentMethods}?website_url=${url}`)
  }

  static getBranding(){
    let url = window.location.hostname
    let demo = AuthManager.currentUser?.company.settings.live || false

    return FetchHelper.get(`${window.Api.Branding}?website_url=${url}&demo=${demo}`, false)
  }

  static exchangeGooglePayPaymentRequestForCardConnectToken(paymentRequest, paymentMethod){
    return FetchHelper.post(`${window.Api.Connect}/${paymentMethod.processor}/tokenize`, {
      google_pay_device_data: paymentRequest.paymentMethodData.tokenizationData.token,
      payment_method_id: paymentMethod.id
    }, false)
    .then(response => response.token)
  }

  static getPlaidLinkToken(paymentMethod){
    return FetchHelper.post(`${window.Api.Connect}/${paymentMethod.processor}/plaid-link-token`, {
      payment_method_id: paymentMethod.id,
    })
  }

  static exchangeFlinksForEftDetails(paymentMethod, flinksLoginId, flinksAccountId, flinksRequestId=null, retries=0){
    let data = {
      payment_method_id: paymentMethod.id,
      flinks_login_id: flinksLoginId,
      flinks_account_id: flinksAccountId,
    }

    if(flinksRequestId){
      data.flinks_request_id = flinksRequestId
    }

    return FetchHelper.post(`${window.Api.Connect}/${paymentMethod.processor}/flinks-token`, data)
    .then(response => {

      if(response.pending){
        if(retries > 30 * 30){
          throw {"error": "timeout", message: "Payment failed, your bank took too long to respond, please try again."}
        }
        return Backend.exchangeFlinksForEftDetails(paymentMethod, flinksLoginId, flinksAccountId, response.request_id, retries + 1)
      }
      else{
        return response
      }
    })
  }

  static getBanks(processorSlug, countryCode, company){
    return FetchHelper.get(`${window.Api.Connect}/${processorSlug}/banks?countries=${countryCode}&company_id=${company.id}`)
    .then(response => response.results || response.banks || [])
  }

}
