import React from "react";

import moment from "moment"

export default class DueDate extends React.Component{
  render() {
    if(!this.props.dueAt){
      return null
    }

    return (
      <div className="form-group row due-date">
				<div className="col-4 col-sm-3 text-left">
					<label><strong>Due Date: </strong></label>
				</div>
				<div className="col-8 col-sm-9">
					<p className="form-control-plaintext text-dark py-0 text-left">
            { moment(this.props.dueAt).format("Do MMM YYYY") }
            { this.props.file &&
              <a target="_blank"
                href={this.props.file.url}
                style={{ color: this.props.primaryColor }}
              > (Download PDF)</a>
            }
          </p>
				</div>
			</div>
    )
  }
}
