import React from 'react'

import ReactReadMoreReadLess from "react-read-more-read-less";

import Toggle from "../../common/Toggle";
import Description from "./../Description"

import LockInputAppendIcon from "../LockInputAppendIcon"

import General from "../../../../utils/General";

export default class BankInput extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      show: props.show,
      error: props.error,
      company: props.company,
      focusedInput: "institution"
    }

    this.institutionField = React.createRef()
    this.transitField = React.createRef()
    this.accountField = React.createRef()
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  isValid(scrollToError){
    let { transitNumber, institutionNumber, accountNumber, acceptedTerms} = this.state

    let error = null
    let termsError = null

    let errors = []
    if(!accountNumber){
      error = "Please enter a valid account number"
      errors.push({ selector: "#input-account-number",  message: error })
    }
    if(!transitNumber){
      error = "Please enter a transit number"
      errors.push({ selector: "#input-transit-number",  message: error })
    }
    if(!institutionNumber){
      error = "Please enter a valid institution number"
      errors.push({ selector: "#input-institution-number",  message: error })
    }
    if(!acceptedTerms){
      termsError = "You must agree / accept the terms"
    }

    if(errors.length > 0 || error || termsError){
      this.setState({ error, errors, termsError})
      if(scrollToError && (errors.length > 0 || error)){
        General.scrollTo(error ? "#apaylo-bank-input" : errors[0].selector)
      }
      return false
    }

    return true
  }


  _showTransitNumber(){
    return true
    return this.state.focusedInput == "transit" || this.state.transitNumber
  }

  _showAccountNumber(){
    return true
    return this.state.focusedInput == "account" || this.state.accountNumber
  }

  _update(){
    let { accountNumber, transitNumber, institutionNumber} = this.state
    this.setState({ error: null, termsError: null })
    this.props.onUpdated({
      accountNumber,
      transitNumber,
      institutionNumber
    })
  }

  render(){
    let {
      error,
      termsError,
      acceptedTerms,
      institutionNumber,
      transitNumber,
      accountNumber,
      company,
      focusedInput
    } = this.state

    return (
      <div 
        id="apaylo-bank-input" 
        className={`payment-method apaylo bank-input horizontal form-group mb-2 text-left format-${company.settings.layout}`}
      >
        <div className={`input-group with-append ${error ? "validation-error" : ""}`}>
          <div
            className="form-control h-auto border-0"
            style={{ overflowX: "hidden" }}
          >
            <div
              className="row"
            >
              <div
                className="col institution-number pr-0"
                style={{
                  maxWidth: institutionNumber?.length == 3 ? "50px" : null
                }}
              >
                <input
                  ref={this.institutionField}
                  className="border-0 w-100 px-0"
                  placeholder="Institution #"
                  maxLength="3"
                  inputmode="numeric"
                  onChange={e => {
                    institutionNumber = e.target.value
                    let institutionNumberLength = institutionNumber?.length || 0
                    focusedInput = institutionNumberLength == 3 ? "transit" : "institution"
                    this.setState({
                      institutionNumber,
                      focusedInput
                    }, () => {
                      if(institutionNumberLength == 3){
                        this.transitField.current.focus()
                      }
                      this._update()
                    })
                  }}
                />
              </div>
              <div
                className="col transit-number pr-0"
                style={{
                  display: this._showTransitNumber() ? "block" :  "none",
                  maxWidth: transitNumber?.length == 5 ? "60px" : null
                }}
                onClick={e => {
                  if(institutionNumber?.length === 0){
                    e.preventDefault()
                    this.institutionField.current.focus()
                  }
                }}
              >
                <input
                  ref={this.transitField}
                  className="border-0 w-100 px-0"
                  placeholder="Transit #"
                  maxLength="5"
                  inputmode="numeric"
                  onFocus={e => {
                    if(institutionNumber?.length != 3){
                      e.preventDefault()
                      this.institutionField.current.focus()
                    }
                  }}
                  onKeyDown={e => {
                    let transitNumberIsEmpty = !transitNumber

                    let backspacePressed = e.keyCode == 8 || e.keyCode == 46
                    if(transitNumberIsEmpty && backspacePressed){
                      this.setState({
                        focusedInput: "institution"
                      }, () => this.institutionField.current.focus())
                    }
                    return true
                  }}
                  onChange={e => {
                    transitNumber = e.target.value
                    let transitNumberLength = transitNumber?.length || 0
                    focusedInput = transitNumberLength == 5 ? "account" : "transit"
                    this.setState({
                      transitNumber,
                      focusedInput
                    }, () => {
                      if(focusedInput == "account"){
                        this.accountField.current.focus()
                      }
                      this._update()
                    })
                  }}
                />
              </div>
              <div
                className="col account-number pr-0"
                style={{
                  display: this._showAccountNumber() ? "block" :  "none"
                }}
                onClick={e => {
                  if(institutionNumber?.length === 0){
                    e.preventDefault()
                    this.institutionField.current.focus()
                  }
                }}
              >
                <input
                  ref={this.accountField}
                  className="border-0 w-100 px-0"
                  placeholder="Account #"
                  inputmode="numeric"
                  onFocus={e => {
                    if(institutionNumber?.length != 3){
                      e.preventDefault()
                      this.institutionField.current.focus()
                    }
                    if(transitNumber?.length != 5){
                      e.preventDefault()
                      this.transitField.current.focus()
                    }
                  }}
                  onKeyDown={e => {
                    let accountNumberWasEmpty = !accountNumber

                    let backspacePressed = e.keyCode == 8 || e.keyCode == 46
                    if(accountNumberWasEmpty && backspacePressed){
                      this.setState({
                        focusedInput: "transit"
                      }, () => this.transitField.current.focus())
                    }
                    return true
                  }}
                  onChange={e => {
                    accountNumber = e.target.value
                    this.setState({
                      accountNumber,
                    }, () => this._update())
                  }}
                />
              </div>
            </div>
          </div>
          <LockInputAppendIcon/>
        </div>

        { error &&
          <span className="validation-error-message mb-3">{ error }</span>
        }

        <br/>

        <div className="form-group row c-description mb-0">
          <div className="col-auto text-left mx-height-px-34">
             <span className={`switch switch-icon mt-0 ${termsError ? "validation-error" : ""}`}>
              <label className="mb-0">
               <input
                type="checkbox"
                checked={this.props.checked}
                onChange={e => {
                  this.setState({
                    acceptedTerms: e.target.checked
                  }, () => this._update())
                }}
               />
               <span/>
              </label>
             </span>
            </div>
          <div className="col px-0">
            <p className="form-control-plaintext text-dark py-0 pr-10 pre">
              <ReactReadMoreReadLess
                  charLimit={60}
                  readMoreText={"Read more"}
                  readLessText={"Read less"}
                  readMoreStyle={{ color: company.settings.primary_color }}
                  readLessStyle={{ color: company.settings.primary_color }}
                  readMoreClassName={"cursor-pointer"}
                  readLessClassName={"cursor-pointer"}
              >
                  {`I agree that I have full authority to transact on the bank account entered previously without obtaining approval from any third party. I have read and agree to the Terms and Condition with ${company.name}.  This means that Apaylo Finance Technology Inc., payment service provider to ${company.name}, can set up pre-authorized debit on this account by way of accepting this request for payment`}
              </ReactReadMoreReadLess>
            </p>
          </div>
        </div>

        { termsError &&
          <span className="validation-error-message">{ termsError }</span>
        }

      </div>
    )
  }
}

BankInput.defaultProps = {
  modalClassName: '',
  advanced: true
}
