import React from "react";

export default class NFC extends React.Component{

  render() {
    let enabled = this.props.paymentPlan.total > 50
    let company = this.props.company

    return (
      <div className={ ` components payment nfc ${enabled ? "enabled" : ""} ` }>

          <div className="wifi">
            <svg width="109" height="150" viewBox="0 0 109 150" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path 
                fill-rule="evenodd" 
                clip-rule="evenodd" 
                d="M47.52 32.0471C72.5236 55.5886 73.71 95.0052 50.1691 120.008C48.5998 121.675 48.6789 124.303 50.3457 125.872C52.0126 127.441 54.6404 127.362 56.2098 125.696C82.8885 97.3599 81.5438 52.6858 53.2075 26.0064C51.5397 24.4361 48.9128 24.5162 47.3434 26.183C45.7741 27.8499 45.8523 30.4768 47.52 32.0471ZM24.7702 56.2098C36.441 67.1982 36.9948 85.5874 26.0064 97.2582C24.4371 98.9251 24.5162 101.553 26.183 103.122C27.8499 104.692 30.4777 104.613 32.0471 102.946C46.1752 87.9402 45.4622 64.2963 30.4577 50.1691C28.7899 48.5988 26.163 48.6789 24.5936 50.3457C23.0242 52.0125 23.1024 54.6395 24.7702 56.2098ZM70.2699 7.8844C108.611 43.9838 110.431 104.417 74.3318 142.758C72.7624 144.425 72.8416 147.053 74.5084 148.622C76.1762 150.192 78.8031 150.112 80.3725 148.445C119.611 106.77 117.633 41.0827 75.9573 1.84373C74.2896 0.273468 71.6627 0.353497 70.0933 2.02034C68.5239 3.68717 68.6021 6.31414 70.2699 7.8844ZM7.70783 74.3318C9.37466 75.9011 9.45381 78.529 7.88443 80.1958C6.31506 81.8627 3.68721 81.9418 2.02037 80.3724C0.352587 78.8022 0.274389 76.1752 1.84376 74.5084C3.41314 72.8415 6.04005 72.7615 7.70783 74.3318Z" 
                fill={enabled ? window.General.Branding.Colors.take : "" }
              />
            </svg>
          </div>
          <p>Hold your card or device to the back of this phone to pay</p>

			</div>
    )
  }

}
