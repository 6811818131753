import React from "react";

export default class Toggle extends React.Component{
  render() {
    let isLabelString = typeof this.props.label === 'string' || this.props.label instanceof String
    return (
      <div className="form-group row mb-0 align-items-center c-toggle">
        <div className="col-auto text-left mx-height-px-34">
         <span className="switch switch-icon mt-0">
          <label className="mb-0">
           <input
            type="checkbox"
            checked={this.props.checked}
            onChange={this.props.onChange}
           />
           <span/>
          </label>
         </span>
        </div>
        { isLabelString &&
          <label
            className="col px-0 col-form-label text-left"
            dangerouslySetInnerHTML={{ __html: this.props.label }}
          />
        }
        { !isLabelString &&
          <label
            className="col px-0 col-form-label text-left"
          >
           { this.props.label }
          </label>
         }
      </div>
    )
  }
}
