import React from "react";

import LoadingOverlay from 'react-loading-overlay';

import { withRouter } from "react-router-dom"

import ReCAPTCHA from "react-google-recaptcha";

import ErrorModal from "../modal/ErrorModal"
import PaymentSuccessModal from "../modal/PaymentSuccessModal"

import Amount from "./Amount"
import PaymentPlanSelect from "./PaymentPlanSelect"
import PaymentMethods from "./PaymentMethods"
import PaymentMethodsContainer from "./PaymentMethodsContainer"

import Separator from "../common/Separator"

import LockInputAppendIcon from "./LockInputAppendIcon"
import Description from "./Description"
import Address from "./Address"
import Questions from "./Questions"
import DueDate from "./DueDate"
import NFC from "./NFC"

import ProductGallery from "./ProductGallery";

import General from "../../../utils/General";
import Currency from "../../../utils/Currency";
import Backend from "../../../utils/Backend";
import Splink from "../../../utils/Splink";
import AsyncStorage from "../../../utils/AsyncStorage";

import PaymentMethodUtils from "../../../utils/PaymentMethods";

export class Form extends React.Component{

  constructor(props) {
    super(props);

    this.state = this._getState(props)

    this.amount = React.createRef()
    this.paymentPlan = React.createRef()
    this.paymentMethods = React.createRef()
    this.questions = React.createRef()
    this.address = React.createRef()
    this.description = React.createRef()

    this.recaptcha = React.createRef()
  }

  componentDidMount(){
    let data = this.props.savedState
    if(data){
      this.setState(data, () => {
        if(data._savedPaymentMethod && data.paymentDetails){
          this._pay(data._savedPaymentMethod, data.paymentDetails)
        }
        else if(data._boipaFailureResult){
          this.setState({
            error: { message: "An unexpected error occurred, please try again "},
            loading: false,
            showErrorModal: true,
          })
        }
        else if(data._boipaSuccessResult){

          this.setState({
            loading: false,
            showPaymentSuccessModal: !this.state.inApp
          })

          if(this.state.inApp && window.ReactNativeWebView){
            window.ReactNativeWebView.postMessage("payment-success")
          }
        }
      })
    }
  }

  _getState(props){
    let {
      company,
      customer,
      dueAt,
      file,
      dynamicCurrency,
      nfcPrioritised,
      allowPayment,
      description,
      redirectUrl,
      collectEmail,
      collectAddress,
      collectDescription,
      questions,
      products,
      selectedProduct,
      paymentPlan,
      paymentPlans,
      paymentMethods,
      paymentDetails,
      paymentAttemptId,
      showProductGallery,
      showAlternativePaymentMethods,
    } = props

    let url = new URL(window.location.href)
    let params = new URLSearchParams(url.search)

    let presetAmount = params.get("amount")
    // boipa sends back i ndollars
    if(presetAmount && params.get("merchantTxId")){
      presetAmount *= 100
    }
    customer = customer || {}

    paymentPlan = this._preparePaymentPlan(paymentPlan, (products?.length > 0 && selectedProduct == null))
    if(paymentPlan.open && presetAmount){
      paymentPlan.total = presetAmount
      if(!paymentPlan.subtotal){
        paymentPlan.subtotal = presetAmount
        paymentPlan.vat = 0
      }
    }

    paymentMethods = paymentMethods || company.payment_methods

    questions = questions || []

    if(selectedProduct){
      redirectUrl = selectedProduct.payment_success_url
    }

    return {
      company,
      customer,
      dueAt,
      file,
      dynamicCurrency,
      nfcPrioritised,
      allowPayment,
      description,
      redirectUrl,
      collectEmail,
      collectAddress,
      collectDescription,
      questions,
      products,
      paymentPlans,
      paymentMethods,
      paymentDetails,
      paymentAttemptId,
      showProductGallery,
      showAlternativePaymentMethods,
      selectedProduct: selectedProduct,
      selectedPaymentPlan: paymentPlan,
      inApp: window.ReactNativeWebView != null
    }
  }

  _preparePaymentPlan(paymentPlan, zero){
    return General.preparePaymentPlan(paymentPlan, zero)
  }

  _isValid(paymentMethod){
    let {
      customer,
      collectAddress,
      collectDescription
    } = this.state

    let isValid = true
    if(this.amount.current && !this.amount.current.isValid(isValid)){
      isValid = false
    }

    if(this.paymentPlan.current && !this.paymentPlan.current.isValid(isValid)){
      isValid = false
    }

    if(collectDescription && this.description.current && !this.description.current.isValid(isValid)){
      isValid = false
    }

    if(collectAddress && this.address.current && !this.address.current.isValid(isValid)){
      isValid = false
    }

    if(this.questions.current && !this.questions.current.isValid(isValid)){
      isValid = false
    }

    if(this.paymentMethods.current && !this.paymentMethods.current.isValid(isValid, paymentMethod)){
      isValid = false
    }

    return isValid
  }

  _handlePay(paymentMethod){
    if(!this._isValid(paymentMethod) || this.state.loading){
      return false
    }

    this.setState({ loading: true })
    this.paymentMethods.current.getPaymentDetails(paymentMethod)
    .then(({paymentMethod, paymentDetails}) => {
      this._pay(paymentMethod, paymentDetails)
    })
    .catch(error => {
      this.setState({
        error,
        loading: false,
        showErrorModal: true,
      })
    })
  }

  _pay(paymentMethod, paymentDetails){
    let {
      inApp,
      company,
      customer,
      questions,
      description,
      nfcPrioritised,
      selectedPaymentPlan,
      paymentAttemptId
    } = this.state

    let answers = questions.map(question => question.answer)
    answers = answers.filter(answer => answer != null)
    this.setState({
      loading: true
    })

    return this.recaptcha.current.executeAsync()
    .then(recaptcha => {
      this.recaptcha.current.reset()
      return Backend.makePayment(company, selectedPaymentPlan, paymentMethod, paymentDetails, customer, answers, description, paymentAttemptId, recaptcha)
    })
    .then(response => {
      if(response.requires_action){
        // boipa will show card modal here so we need to remove loading spinner
        this.setState({ loading: paymentMethod.processor.indexOf("boipa") == -1 })
        return this.paymentMethods.current.handleAction(response, paymentMethod)
        .then(paymentDetails => {
          return this._pay(paymentMethod, paymentDetails)
        })
      }

      this.setState({
        loading: false,
        showPaymentSuccessModal: !inApp
      })

      if(inApp && window.ReactNativeWebView){
        window.ReactNativeWebView.postMessage("payment-success")
      }
      else if(window.parent && window.parent.postMessage){
        window.parent.postMessage({ type: "payment", status: "success" }, "*")
      }
    })
    .catch(error => {
      this.setState({
        error,
        loading: false,
        showErrorModal: true,
      })
    })
  }

  _renderEmail(customer, inputEmailError){
    let className = "input-group with-append"
    if(inputEmailError){
      className += " validation-error"
    }
    return (
      <div className="form-group">
        <div
          className={className}
          style={{
            border: "1px solid #FFF",
            borderRadius: "24px"
          }}
        >
          <input
            className="form-control h-auto text-white bg-dark-o-90 rounded-pill border-0 py-4 px-8"
            type="text"
            placeholder="Email (receipt)"
            defaultValue={customer.email}
            disabled={customer.id != null}
            onChange={e => {
              customer.email = e.target.value
              this.setState({ customer, inputEmailError: null })
            }}
          />
          <LockInputAppendIcon/>
        </div>
        { inputEmailError &&
          <span className="validation-error-message">{ inputEmailError }</span>
        }
      </div>
    )
  }

  _renderContent(){
    let {
      company,
      customer,
      dueAt,
      file,
      inApp,
      dynamicCurrency,
      nfcPrioritised,
      allowPayment,
      redirectUrl,
      description,
      collectEmail,
      collectAddress,
      collectDescription,
      questions,
      products,
      selectedProduct,
      paymentPlans,
      paymentMethods,
      selectedPaymentPlan,
      showProductGallery,
      showAlternativePaymentMethods,
      paymentAttemptId
    } = this.state

    let { placeholderPaymentMethodsContent } = this.props


    let showPaymentPlanSelect = true
    if(products && !selectedProduct){
      showPaymentPlanSelect = false
    }

    let paymentPlanSelect = (
      <PaymentPlanSelect
        ref={this.paymentPlan}
        paymentPlans={paymentPlans}
        paymentPlan={selectedPaymentPlan}
        onUpdated={paymentPlan => {
          if(selectedPaymentPlan && paymentPlan.total === 0){
            paymentPlan.total = selectedPaymentPlan.total
            paymentPlan.subtotal = selectedPaymentPlan.subtotal
            paymentPlan.vat = selectedPaymentPlan.vat
          }

          paymentPlan = this._preparePaymentPlan(paymentPlan)

          this.setState({ selectedPaymentPlan: paymentPlan })
        }}
      />
    )

    let dueDateContent = (
      <DueDate
        dueAt={dueAt}
        file={file}
        primaryColor={company.settings.primary_color}
      />
    )

    let descriptionContent = (
      <Description
        ref={this.description}
        text={description}
        collect={collectDescription}
        customer={customer}
        primaryColor={company.settings.primary_color}
        onUpdated={customer => this.setState({ customer })}
      />
    )

    let addressContent = (
      <Address
        ref={this.address}
        customer={customer}
        onUpdated={customer => this.setState({ customer })}
      />
    )

    let questionsContent = (
      <Questions
        ref={this.questions}
        questions={questions}
        onUpdated={questions => this.setState({ questions })}
      />
    )

    let paymentMethodsContent = placeholderPaymentMethodsContent ? null : (
      <PaymentMethods
        ref={this.paymentMethods}
        customer={customer}
        company={company}
        products={products}
        product={selectedProduct}
        paymentMethods={paymentMethods}
        paymentPlan={selectedPaymentPlan}
        paymentAttemptId={paymentAttemptId}
        collectEmail={collectEmail}
        collectAddress={collectAddress}
        nfcPrioritised={nfcPrioritised}
        payButtonContainerId={"payment-form-footer"}
        onUpdated={paymentDetails => {
          this.setState({ paymentDetails })
        }}
        onUpdatedCustomer={customer => {
          this.setState({ customer })
        }}
        onPayPressed={(paymentMethod, paymentDetails) => {
          if(paymentMethod.type !== "digital_wallet"){
            this._handlePay(paymentMethod)
            return
          }

          if(!this._isValid(paymentMethod)){
            return
          }

          this._pay(paymentMethod, paymentDetails)
        }}
        onSaveState={(paymentDetails) => {
          this.setState({ paymentAttemptId, paymentDetails}, () => {
            AsyncStorage.setItem("payment_data", JSON.stringify(this.state))
          })
        }}
      />
    )

    let amountContent = (
      <Amount
        ref={this.amount}
        company={company}
        products={products}
        product={selectedProduct}
        paymentPlan={selectedPaymentPlan}
        paymentMethods={paymentMethods}
        nfcPrioritised={nfcPrioritised}
        dynamicCurrency={dynamicCurrency}
        onUpdated={paymentPlan => this.setState({ selectedPaymentPlan: paymentPlan })}
        onUpdatedProduct={selectedProduct => {
          collectAddress = selectedProduct.collect_address
          redirectUrl = selectedProduct.payment_success_url
          questions = selectedProduct.questions

          let paymentPlan = this._preparePaymentPlan(selectedProduct.payment_plans[0])
          paymentPlan.product_payment_plans = [selectedProduct.payment_plans[0].id]
          paymentPlan.selected_products = [{
            ...selectedProduct,
            quantity: 1,
            questions
          }]

          this.setState({
            questions,
            redirectUrl,
            collectAddress,
            selectedProduct,
            paymentPlans: [paymentPlan],
            selectedPaymentPlan: paymentPlan
          }, () => {
            var routeObj = {
              pathname: Splink.getProductSlug(company, selectedProduct),
            }
            this.props.history.replace(routeObj);
          })
        }}
      />
    )


    let demo = null
    if(General.getQueryVariable('demo')=="pmd"){ // pmd = Payments Matter Digital
      demo = "pmd"
    }
    if(General.getQueryVariable('demo')=="db"){ // db = Demo Bank
      demo = "db"
    }

    if(showProductGallery){
      return (
        <ProductGallery
          demo={demo}
          products={products}
          paymentPlan={selectedPaymentPlan}
          onUpdated={(selectedPaymentPlan, questions, collectAddress) => {
            this.setState({
              questions,
              collectAddress,
              selectedPaymentPlan,
              paymentPlans: [selectedPaymentPlan],
            })
          }}
          collectAddress={collectAddress}
          renderPaymentPlan={() => paymentPlanSelect}
          renderAddress={() => addressContent}
          renderPayment={() => paymentMethodsContent}
          inApp={inApp}
          paymentMethods={paymentMethods}
          nfcPrioritised={nfcPrioritised}
          showAlternativePaymentMethods={showAlternativePaymentMethods}
        />
      )
    }

    return (
      <>
        { !inApp &&
          <>
            { amountContent }

            { showPaymentPlanSelect &&  paymentPlanSelect }

            { dueDateContent }

            { descriptionContent }

            { collectAddress && addressContent }

            { questionsContent }

            { allowPayment &&
              <>
                <div
                  id="other-payment-methods-form-middle"
                  style={{
                    display: showAlternativePaymentMethods ? "visible" : "none"
                  }}
                />

                { paymentMethodsContent }
              </>
            }
          </>
        }
        { inApp &&
          <>
            { amountContent }

            { !nfcPrioritised &&
              <>
                { dueDateContent }

                { descriptionContent }

                { collectAddress && addressContent }

                { questionsContent }
              </>
            }

            { allowPayment && paymentMethodsContent}

            { !nfcPrioritised &&
              <>
                { showPaymentPlanSelect &&  paymentPlanSelect }
              </>
            }

            { nfcPrioritised &&
              <NFC
                company={company}
                paymentPlan={selectedPaymentPlan}
              />
            }

          </>
        }

        { this.props.placeholderPaymentMethodsContent ||
          <PaymentMethodsContainer
            inApp={inApp}
            paymentMethods={paymentMethods}
            nfcPrioritised={nfcPrioritised}
            showAlternativePaymentMethods={showAlternativePaymentMethods}
          />
        }

      </>
    )
  }
  render() {

    let {
      error,
      loading,
      inApp,
      company,
      redirectUrl,
      showErrorModal,
      showPaymentSuccessModal
    } = this.state

    let className = "form payment-form"
    if(loading){
      className += " payment-form-loading"
    }

    return (
      <>
        <div className={className}>
          {this._renderContent() }
        </div>
        <LoadingOverlay
            active={loading}
            className={loading ? "payment-form-loading-overlay" : ""}
            spinner
            text={<p>Loading... <br/> {inApp ? "please do not close the app" : "please do not refresh"}</p>}
        />

        <PaymentSuccessModal
          show={showPaymentSuccessModal}
          redirectUrl={redirectUrl}
          onPaid={this.props.onPaid}
          modalClassName={this.props.paymentSuccessModalClassName}
          company={company}
        />

        <ErrorModal
          show={showErrorModal}
          company={company}
          error={error}
          modalClassName={this.props.errorModalClassName}
          onClose={() => this.setState({ showErrorModal: false })}
        />

        <ReCAPTCHA
          ref={this.recaptcha}
          sitekey={window.Api.RecaptchaSiteKey}
          size="invisible"
        />
      </>
    )
  }

}

Form.defaultProps = {
  collectAddress: false,
  collectEmail: true,
  allowPayment: true,
  showAlternativePaymentMethods: true,
}

export default withRouter(Form)
