import React from "react";

import { Elements, ElementsConsumer, CardElement } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import PaymentMethodPortal from '../PaymentMethodPortal';

import Card from "./Card"
import ACH from "./ACH"

import DigitalWallet from "./DigitalWallet"
import SepaModal from "./SepaModal"
import BACSModal from "./BACSModal"
import BACSAuthModal from "./BACSAuthModal"

import Customer from "../Customer"
import PayButton from "../button/PayButton"
import CardPayButton from "../button/CardPayButton"
import SepaPayButton from "../button/SepaPayButton"
import KlarnaPayButton from "../button/KlarnaPayButton"
import BankPayButton from "../button/BankPayButton"

import Separator from "../../common/Separator"

import Name from "../../../../utils/Name"
import General from "../../../../utils/General"
import Currency from "../../../../utils/Currency"

import PaymentMethodUtils from "../../../../utils/PaymentMethods"

let stripePromise = null

let stripeConnectPromise = null

const SEPARATOR = (
  <p className="text-center my-auto payment-method-separator">or</p>
)

export default class PaymentMethods extends React.Component{

  constructor(props) {
    super(props);

    this.state = {
      ...this._getState(props),
      mode: "card"
    }

    this.card = React.createRef()
    this.ach = React.createRef()
    this.digitalWallet = React.createRef()

    this.customer = React.createRef()

    this.sepaModal = React.createRef()

    this.bacsModal = React.createRef()
    this.bacsAuthModal = React.createRef()

    let paymentMethod = PaymentMethodUtils.getDefault(props.paymentMethods)
    let platformPublicKey = paymentMethod.data.platform_public_key
    stripePromise = loadStripe(platformPublicKey)
  }

  componentWillReceiveProps(nextProps) {
    this.setState(this._getState(nextProps))
  }

  _getState(props){
    let {
      company,
      customer,
      paymentPlan,
      paymentMethods,
    } = props

    //paymentMethods.digital_wallet = null

    return {
      company,
      customer,
      paymentPlan,
      paymentMethods,
    }
  }

  _scrollToInputEmail(){
    setTimeout(() => {
      General.scrollTo("#input-email")
    }, 50)
  }

  isValid(scrollToError){
    let { mode } = this.state

    if(mode === "ach"){
      return true
    }

    if(this.customer.current && !this.customer.current.isValid(scrollToError)){
      return false
    }

    return true
  }

  getPaymentDetails(paymentMethod){

    return this._getPaymentDetails(paymentMethod)
    .then(response => {
      response.paymentDetails.return_url = window.location.href
      return response
    })
  }

  _getPaymentDetails(paymentMethod){

    if(paymentMethod.type === "card"){
      return this.card.current.collectDetails()
    }

    if(paymentMethod.type === "sepa"){
      return this.sepaModal.current.collectDetails()
    }

    if(paymentMethod.type === "bacs"){
      return this.bacsModal.current.collectDetails()
    }

    if(paymentMethod.type === "ach"){
      return this.ach.current.collectDetails()
    }

    if(paymentMethod.type === "klarna"){
      return this._getKlarnaPaymentDetails(paymentMethod)
    }

    return null
  }

  _getKlarnaPaymentDetails(paymentMethod){
    return new Promise((resolve, reject) => {
      let url = new URL(window.location.href);
      let params = new URLSearchParams(url.search);

      url = this.props.subscriptionPayment ? window.location.href : url
      resolve({
        paymentMethod,
        paymentDetails: {
          return_url: url,
        }
      })
    })
  }

  handleAction(response, paymentMethod){
    return this._validatePayment(response, paymentMethod)
  }

  _validatePayment(response, paymentMethod){
    return new Promise((resolve, reject) => {
      if(response?.type == "redirect_to_url"){
        this.props.onSaveState(response)
        setTimeout(() => window.location.href = response.redirect_url, 500)
        return
      }
      this._handleStripeAction(response, paymentMethod)
      .then(result => {
        if(result.error){
          return reject(result.error)
        }

        return resolve({
          payment_intent_id: result.paymentIntent.id,
          payment_method_id: response.payment_method_id
        })
      })
      .catch(error => {
        reject(error)
      })
    })
  }

  async _handleStripeAction(response, paymentMethod){

    if(paymentMethod.type == "bacs"){
      return this.bacsAuthModal.current.handleAction(response)
    }

    const stripeConnectAccount = await loadStripe(paymentMethod.data.platform_public_key, {
      stripeAccount: paymentMethod.data.account_id
    });

    if(response.recurring_payment){
      return stripeConnectAccount.confirmCardPayment(response.client_secret)
    }
    else{
      return stripeConnectAccount.handleCardAction(response.client_secret)
    }
  }

  _canShowSepaInput(){
    let {
      mode,
      paymentMethods
    } = this.state

    return mode === "sepa" || (!paymentMethods.digital_wallet && !paymentMethods.card)
  }

  _canShowCardInput(){
    let {
      mode,
      paymentMethods
    } = this.state

    return paymentMethods.card
  }

  _renderCard(paymentMethod, customer, company){
    if(!paymentMethod){
      return null
    }

    return (
      <PaymentMethodPortal type={paymentMethod.type}>
        { this._canShowCardInput() &&
          <>
            <Customer
              ref={this.customer}
              customer={customer}
              collectEmail={this.props.collectEmail}
              onUpdated={customer => {
                this.props.onUpdatedCustomer(customer)
              }}
            />

            <Card
              ref={this.card}
              stripe={this.stripe}
              elements={this.elements}
              company={company}
              customer={customer}
              paymentMethod={paymentMethod}
              collectAddress={this.props.collectAddress}
            />

            <PayButton
              company={company}
              payButtonTitle={this.props.payButtonTitle}
              payButtonContainerId={this.props.payButtonContainerId}
              backgroundColor={company.settings.primary_color}
              paymentPlan={this.state.paymentPlan}
              onClick={() => this.props.onPayPressed(paymentMethod)}
            />
          </>
        }
      </PaymentMethodPortal>
    )
  }

  _renderPayBySepa(paymentMethod, customer, company){
    let { mode, paymentPlan } = this.state
    if(!paymentMethod || !company.add_ons?.sepa || paymentPlan.currency.code != "eur"){
      return null
    }

    return (
      <PaymentMethodPortal type={paymentMethod.type}>

        <BankPayButton
          primaryColor={company.settings.primary_color}
          paymentPlan={this.state.paymentPlan}
          paymentMethod={paymentMethod}
          title="Pay By Direct Debit"
          onClick={e => {
            this.setState({
              showSepaModal: true
            })
          }}
        />

      </PaymentMethodPortal>
    )
  }

  _renderPayByBACS(paymentMethod, customer, company){
    let { mode, paymentPlan } = this.state
    if(!paymentMethod || !company.add_ons?.sepa || paymentPlan.currency.code != "gbp"){
      return null
    }

    return (
      <PaymentMethodPortal type={paymentMethod.type}>

        <BankPayButton
          primaryColor={company.settings.primary_color}
          paymentPlan={this.state.paymentPlan}
          paymentMethod={paymentMethod}
          title="Pay By Direct Debit"
          onClick={e => {
            this.setState({
              showBACSModal: true
            })
          }}
        />

      </PaymentMethodPortal>
    )
  }

  _renderDigitalWallet(paymentMethod, customer, company){
    if(!paymentMethod || !this.stripe){
      return null
    }

    return (
      <PaymentMethodPortal type={paymentMethod.type}>

        <DigitalWallet
          ref={this.digitalWallet}
          stripe={this.stripe}
          elements={this.elements}
          paymentMethod={paymentMethod}
          paymentPlan={this.state.paymentPlan}
          onPayPressed={(paymentDetails, billingDetails, address) => {

            customer.billing_address = address.billing
            // full addrress is witheld by the browser so unusable
            // see: https://stripe.com/docs/js/payment_request/events/on_shipping_address_change
            //customer.shipping_address = address.shipping

            let fullName = billingDetails.name
            if(!customer.first_name){
              customer.first_name = Name.getFirstName(fullName)
              customer.last_name = Name.getLastName(fullName)
            }

            let email = billingDetails.email
            customer.email = customer.email || email

            this.props.onUpdatedCustomer(customer)
            this.props.onPayPressed(paymentMethod, paymentDetails)
          }}
          renderHeader={() => {
            return null
          }}
        />

      </PaymentMethodPortal>
    )
  }

  _renderKlarna(paymentMethod, customer, company){
    let { paymentPlan } = this.state
    if(!paymentMethod || !this.stripe){
      return null
    }

    if(paymentPlan.type === "recurring"){
      return null
    }

    if(!paymentPlan.total){
      return null
    }

    return (
      <PaymentMethodPortal type={paymentMethod.type}>
        <KlarnaPayButton
          onClick={() => {
            this.props.onPayPressed(paymentMethod)
          }}
        />
      </PaymentMethodPortal>
    )
  }

  _renderACH(paymentMethod, customer, company){
    if(!paymentMethod){
      return null
    }

    return (
      <PaymentMethodPortal type={paymentMethod.type}>

        <ACH
          ref={this.ach}
          company={company}
          customer={customer}
          paymentPlan={this.props.paymentPlan}
          paymentMethod={paymentMethod}
          onPayPressed={() => {
            this.setState({
              mode: "ach"
            }, () => this.props.onPayPressed(paymentMethod))
          }}
        />

      </PaymentMethodPortal>
    )
  }

  _renderPaymentMethodSelector(paymentMethods){
    let {
      company,
      customer,
    } = this.state

    let content = []

    if(paymentMethods.digital_wallet){
      content.push(this._renderDigitalWallet(paymentMethods.digital_wallet, customer, company))
    }

    if(paymentMethods.card && (paymentMethods.digital_wallet || paymentMethods.sepa)){
      content.push(
        <div className="col">
          <CardPayButton
            onClick={e => {
              this.setState({ mode: "card" })
              this._scrollToInputEmail()
            }}
          />
        </div>
      )
      if(paymentMethods.sepa){
        content.push(SEPARATOR)
      }
    }

    if(paymentMethods.sepa && (paymentMethods.digital_wallet || paymentMethods.card)){
      content.push(
        <div className="col">
          <SepaPayButton
            onClick={e => {
              this.setState({ mode: "sepa" })
              this._scrollToInputEmail()
            }}
          />
        </div>
      )
    }

    return (
      <>
        <div className="form-group">
          { content.length > 1 &&
            <h6 className="text-center font-size-h6 font-size-h6-lg my-10">Pay With</h6>
          }
          <div className="row">
            { content }
          </div>
        </div>
      </>
    )
  }

  render() {

    const fonts = [{ cssSrc: "https://fonts.googleapis.com/css2?family=Poppins&display=swap" }]

    let {
      mode,
      company,
      customer,
      paymentPlan,
      paymentMethods,
      showSepaModal,
      showBACSModal,
    } = this.state

    if(!stripePromise){
      return null
    }

    let cardAndACHSupported = paymentMethods.card != null && paymentMethods.ach != null

    return (
      <>
        <Elements stripe={stripePromise} fonts={fonts}>
          <ElementsConsumer fonts={fonts}>
            {({stripe, elements}) => {
              this.stripe = stripe
              this.elements = elements

              return (
                <>
                    { this._renderCard(paymentMethods.card, customer, company) }

                    { this._renderACH(paymentMethods.ach, customer, company) }

                    { this._renderPayBySepa(paymentMethods.sepa, customer, company) }

                    { this._renderPayByBACS(paymentMethods.bacs, customer, company) }

                    { this._renderDigitalWallet(paymentMethods.digital_wallet, customer, company) }

                    { this._renderKlarna(paymentMethods.klarna, customer, company) }


                </>
              )
            }}
          </ElementsConsumer>
        </Elements>
        { paymentMethods.sepa &&
          <SepaModal
            show={showSepaModal}
            ref={this.sepaModal}
            company={company}
            customer={customer}
            paymentMethod={paymentMethods.sepa}
            onUpdatedCustomer={customer => this.props.onUpdatedCustomer(customer)}
            onCancel={() => {
              this.setState({ showSepaModal: false })
            }}
            onPayPressed={(paymentMethod) => {
              this.setState({ showSepaModal: false })
              this.props.onPayPressed(paymentMethod)
            }}
          />
        }
        { paymentMethods.bacs &&
          <>
            <BACSModal
              show={showBACSModal}
              ref={this.bacsModal}
              company={company}
              customer={customer}
              paymentMethod={paymentMethods.bacs}
              onUpdatedCustomer={customer => this.props.onUpdatedCustomer(customer)}
              onCancel={() => {
                this.setState({ showBACSModal: false })
              }}
              onPayPressed={(paymentMethod) => {
                this.setState({ showBACSModal: false })
                this.props.onPayPressed(paymentMethod)
              }}
            />
            <BACSAuthModal
              ref={this.bacsAuthModal}
              stripe={this.stripe}
              elements={this.elements}
              company={company}
              paymentMethod={paymentMethods.bacs}
              onSaveState={this.props.onSaveState}
              onCancel={() => {
                this.setState({ bacsAuthModal: false })
              }}
              onPayPressed={() => {
                this.setState({ showBACSModal: false })
                this.props.onPayPressed(paymentMethods.bacs)
              }}
            />
          </>
        }
      </>
    )
  }
}
