import React from 'react'
import Modal from 'react-bootstrap/Modal'

export default class PaymentSuccessModal extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      show: props.show,
      company: props.company,
      redirectUrl: props.redirectUrl
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  render() {
    let {
      show,
      company,
      redirectUrl
    } = this.state

    return (
      <Modal
        show={show}
        onHide={() => {}}
        centered
        className={this.props.modalClassName}
      >
        <Modal.Body>
          <div className="swal2-icon swal2-success swal2-icon-show" style={{display: 'flex'}}>
            <div className="swal2-success-circular-line-left"></div>
            <span className="swal2-success-line-tip"></span>
            <span className="swal2-success-line-long"></span>
            <div className="swal2-success-ring"></div>
            <div className="swal2-success-fix"></div>
            <div className="swal2-success-circular-line-right"></div>
          </div>

          <h1 className="text-center">{ company.settings.payment_success_title }</h1>
          <p className="text-center">{ company.settings.payment_success_message }</p>
        </Modal.Body>

        <Modal.Footer>
          <div className="w-100 text-center">
            <button
              type="button"
              className={`btn btn-secondary`}
              onClick={() => {
                if(this.props.onPaid){
                  this.props.onPaid()
                  return
                }

                let url = `https://${window.General.WebsiteDomain}`
                if(redirectUrl){
                  url = redirectUrl
                }
                else if(company.settings.payment_success_url){
                  url = company.settings.payment_success_url
                }
                window.location = url
              }}
            >
              { company.settings.payment_success_button }
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    )
  }
}

PaymentSuccessModal.defaultProps = {
  modalClassName: ''
}
