import React from 'react';


import PaymentForm from "./components/payment/Form";

import ActivateForm from "./components/activate/Form";

import Customer from "./components/payment/Customer"
import PayButton from "./components/payment/button/PayButton"
import CardPayButton from "./components/payment/button/CardPayButton"

import PageLayout from "./components/PageLayout";
import LoadingPage from "./components/LoadingPage";

import Backend from "../utils/Backend";
import Event from "../utils/Event";
import SubscriptionForm from "./components/activate/SubscriptionForm";

export default class Activate extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      company: null
    }

    this.activateForm = React.createRef()
    this.pageLayout = React.createRef()
  }

  componentDidMount() {
    let companySlug = this.props.companySlug
    let authToken = this.props.match.params.authToken

    let timeout = 0

    let boipaPostPayment = window.location.search.indexOf("merchantTxId") > -1
    if(boipaPostPayment){
      timeout = 3000
    }

    setTimeout(() => {
      this._setSplink(companySlug, authToken, boipaPostPayment)
    }, timeout)

  }

  _setSplink(companySlug, authToken, boipaPostPayment=false, reattempt=0){
    Backend.getSplink(companySlug, authToken)
    .then(company => {

      if(boipaPostPayment && !company.subscription.active && reattempt < 10){
        return this._setSplink(companySlug, authToken, boipaPostPayment, reattempt + 1)
      }

      let paymentPlan = company.settings.payment_plan
      let paymentPlans = [paymentPlan]

      // we override the companies payment methods with the platforms so that
      // the payment form will show appropriate payment methods for the platform
      company.payment_methods = window.General.PlatformPaymentMethods
      let processor = company.payment_methods.card?.processor
      if(processor?.indexOf("aib") > -1 || processor?.indexOf("tsys") > -1){
        // aib and tsys use scripts to insert fields which messes with the side
        // by side functionality as there are technically 2 forms here for
        // activate, so we override them with stripe
        company.payment_methods = PLACEHOLDER_STRIPE_PAYMENT_METHODS
      }

      this.setState({
        company,
        authToken,
        paymentPlan,
        paymentPlans
      })

    })
    // .catch(error => {
    //   this.props.history.push("/404")
    // })
  }

  _renderSidePanel(){
    let {
      company,
      authToken
    } = this.state

    if(company.subscription.active){
      return <ActivateForm company={company} authToken={authToken} requirePostalCode={true}/>
    }else{
      return (
        <SubscriptionForm
          company={company}
          authToken={authToken}
          onSuccessfulPayment={() => {
            company.subscription.active = true
            this.setState({ company })
          }}
        />
      )
    }
  }

  render() {
    let {
      company,
      authToken,
      paymentPlan,
      paymentPlans,
    } = this.state

    if(!company){
      return <LoadingPage/>
    }

    return (
      <PageLayout
        ref={this.PageLayout}
        company={company}
        activate={true}
        layout="retro"
        renderSidePanel={() => this._renderSidePanel()}
        onActivateMaskClicked={() => Event.emit('SHOW_ACTIVATE_TOUR')}
      >
        <PaymentForm
          company={company}
          paymentPlan={paymentPlan}
          paymentPlans={paymentPlans}
          placeholderPaymentMethodsContent={(
            <>
              <Customer
                customer={{}}
                collectName={true}
                collectEmail={true}
                onUpdated={() => {}}
              />
              <button
                className="btn btn-primary btn-block btn-paynow"
                onClick={() => {}}
                style={{paddingLeft: 6}}
              >
                <span className="d-flex align-items-center justify-content-center">
                  <span className="font-weight-bolder">Pay Now</span>
                </span>
              </button>
            </>
          )}
          showAlternativePaymentMethods={false}
        />
      </PageLayout>
    )
  }

}

const PLACEHOLDER_STRIPE_PAYMENT_METHODS = {
  card: {
    type: "card",
    processor: "stripe_test",
    data: {
      account_id: "acct_1HZhM6CoVSlQU5W0",
    }
  },
  stripe: {
    platform_public_key: "pk_test_51JgBFSLBkWrwhXolps7LYgfJOWA5Cp4DTZWUNJp1RsoMGtowcFNZwVBM0rvPlQyRwwsUzgaK8wtXu79P87PRK3Cr00v5wHhuuj"
  }
}
