import React from 'react'

import Modal from 'react-bootstrap/Modal'

import Select from "../../common/CustomSelect";
import Customer from "../Customer";

import General from "../../../../utils/General";
import Backend from "../../../../utils/Backend";

export default class BACSModal extends React.Component {
  constructor(props){
    super(props)

    this.state = this._getState(props)

    this.customer = React.createRef()
  }

  componentWillReceiveProps(nextProps){
    this.setState(this._getState(nextProps))
  }

  _getState(props){
    let {
      show,
      company,
      customer,
      paymentPlan,
      paymentMethod,
    } = props

    return {
      show,
      company,
      customer,
      paymentPlan,
      paymentMethod,
    }
  }

  async collectDetails(){
    let { customer, paymentMethod } = this.state
    this._isValid()

    return {
      paymentMethod,
      paymentDetails: {}
    }
  }

  _onPayPressed(paymentMethod){
    this._isValid()
    this.props.onPayPressed(paymentMethod)
  }

  _isValid(){
    let { customer } = this.state
    if(!this.customer.current.isValid(false)){
      throw { message: "Please check your details and try again" }
    }
    return true
  }

  render(){
    let {
      show,
      company,
      customer,
      paymentMethod
    } = this.state

    let className = "form payment-form sepa-modal"
    if(company.settings.layout==="modern"){
      className += " text-white"
    }

    const fonts = [{ cssSrc: "https://fonts.googleapis.com/css2?family=Poppins&display=swap" }]

    return (
      <Modal
        show={show}
        onHide={() => this.props.onCancel()}
        centered
        className={"layout-" + company.settings.layout}
      >
        <Modal.Body>

          <div className={className}>

            <h3 className="font-weight-bolder text-dark font-size-h4 mb-8">
              Bank Details
            </h3>

            <Customer
              ref={this.customer}
              customer={customer}
              namePlaceholder="Account Holder Name"
              collectBillingAddress={false}
              collectEmail={this.props.collectEmail}
              onUpdated={customer => {
                this.props.onUpdatedCustomer(customer)
              }}
            />

          </div>


        </Modal.Body>

        <Modal.Footer>

          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => this.props.onCancel()}
          >
            Cancel
          </button>

          <button
            type="button"
            className={`btn btn-primary no-icon`}
            onClick={() => this._onPayPressed(paymentMethod)}
          >
            Continue
          </button>

        </Modal.Footer>
      </Modal>
    )
  }
}

BACSModal.defaultProps = {
  modalClassName: '',
  advanced: true
}
