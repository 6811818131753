import React from 'react'

import { Elements, ElementsConsumer, CardElement } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import Sepa from "./Sepa"

import Modal from 'react-bootstrap/Modal'

import Select from "../../common/CustomSelect";
import Customer from "../Customer";

import General from "../../../../utils/General";
import Backend from "../../../../utils/Backend";

let stripePromise = null

export default class SepaModal extends React.Component {
  constructor(props){
    super(props)

    this.state = this._getState(props)

    this.sepa = React.createRef()

    stripePromise = loadStripe(
      props.paymentMethod.data.platform_public_key,
      { stripeAccount: props.paymentMethod.data.account_id }
    )
  }

  componentWillReceiveProps(nextProps){
    this.setState(this._getState(nextProps))
  }

  _getState(props){
    let {
      show,
      company,
      customer,
      paymentPlan,
      paymentMethod,
    } = props

    //paymentMethods.digital_wallet = null

    return {
      show,
      company,
      customer,
      paymentPlan,
      paymentMethod,
    }
  }

  collectDetails(){
    return this.sepa.current.collectDetails()
  }

  _getPaymentDetails(paymentMethod){
    return this.sepa.current.collectDetails()
  }

  render(){
    let {
      show,
      company,
      customer,
      paymentMethod
    } = this.state

    let className = "form payment-form sepa-modal"
    if(company.settings.layout==="modern"){
      className += " text-white"
    }

    const fonts = [{ cssSrc: "https://fonts.googleapis.com/css2?family=Poppins&display=swap" }]

    return (
      <Modal
        show={show}
        onHide={() => this.props.onCancel()}
        centered
        className={"layout-" + company.settings.layout}
      >
        <Modal.Body>

          <div className={className}>

            <h3 className="font-weight-bolder text-dark font-size-h4 mb-8">
              Bank Details
            </h3>

            <Customer
              ref={this.customer}
              customer={customer}
              namePlaceholder="Account Holder Name"
              collectBillingAddress={false}
              collectEmail={this.props.collectEmail}
              onUpdated={customer => {
                this.props.onUpdatedCustomer(customer)
              }}
            />

            <Elements stripe={stripePromise} fonts={fonts}>
              <ElementsConsumer fonts={fonts}>
                {({stripe, elements}) => {
                  this.stripe = stripe
                  this.elements = elements
                  return (
                    <Sepa
                      ref={this.sepa}
                      stripe={this.stripe}
                      elements={this.elements}
                      company={company}
                      customer={customer}
                      paymentMethod={paymentMethod}
                    />
                  )
                }}
              </ElementsConsumer>
            </Elements>

          </div>


        </Modal.Body>

        <Modal.Footer>

          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => this.props.onCancel()}
          >
            Cancel
          </button>

          <button
            type="button"
            className={`btn btn-primary no-icon`}
            onClick={() => this.props.onPayPressed(paymentMethod)}
          >
            Pay
          </button>

        </Modal.Footer>
      </Modal>
    )
  }
}

SepaModal.defaultProps = {
  modalClassName: '',
  advanced: true
}
