import bigDecimal from 'js-big-decimal'

import React from "react"
import AutosizeInput from 'react-input-autosize';

import Select from "../common/Select";
import AsyncSelect from "../common/AsyncSelect";

import Toggle from "../common/Toggle";

import Backend from "../../../utils/Backend";
import General from "../../../utils/General";
import Currency from "../../../utils/Currency";
import PaymentMethodUtils from "../../../utils/PaymentMethods";
import Splink from "../../../utils/Splink";

import { TAX } from "../../../constants/Variables";

export default class Amount extends React.Component{
  constructor(props) {
    super(props);

    this.state = this._getState(props)

    if(this.state.amount){
      this._updateReverseFeeAmount()
    }
  }

  componentWillReceiveProps(nextProps) {

    this.setState(this._getState(nextProps), () => {
      // getError relives onn up to date state
      this.setState({
        error: this._getError()
      })
    })
  }

  _getState(props){
    return {
      company: props.company,
      products: props.products,
      selectedProduct: props.product,
      paymentPlan: props.paymentPlan,
      paymentMethods: props.paymentMethods,
      amount: (props.paymentPlan.total - (props.paymentPlan.reverse_fee_amount || 0)) / 100.0,
      nfcPrioritised: props.nfcPrioritised,
      dynamicCurrency: props.dynamicCurrency
    }
  }

  _getError(){
    if(!this.state){
      return null
    }

    let {
      paymentPlan,
      products,
      selectedProduct
    } = this.state

    let amount = this.state.paymentPlan.total

    let error = null

    if(!amount || amount <= 50){
      let centsName = this.state.paymentPlan.currency.code == "gbp" ? "pence" : "cents"
      error = { selector: "#input-amount", message: `Invalid amount, must be greater than 50 ${centsName}` }
    }

    if(products && !selectedProduct){
      error = { selector: "#product-select-container", message: "Please select a product" }
    }

    return error
  }

  isValid(scrollToError){
    let error = this._getError()

    if(error){
      this.setState({ error })
      if(scrollToError){
        General.scrollTo(error.selector)
      }
      return false
    }

    return true
  }


  _updatePaymentPlan(paymentPlan, amount, reverseFeeAmount=0){
    let {
      company
    } = this.state

    paymentPlan.total = amount

    let vat = 0
    if(company.settings.vat_enabled || paymentPlan._original.vat){
      let companyVatRate = parseFloat(company.settings.vat_rate) || 0
      let vatUnit = bigDecimal.divide(amount, (1 + companyVatRate))
      vat = Math.round(bigDecimal.multiply(vatUnit, companyVatRate))
    }

    paymentPlan.vat = Math.round(vat)
    paymentPlan.subtotal = paymentPlan.total - paymentPlan.vat
    paymentPlan.reverse_fee_amount = reverseFeeAmount

    this.props.onUpdated(paymentPlan)
  }

  _updateReverseFeeAmount = General.debounce(() => {
    let {
      amount,
      company,
      paymentPlan,
      paymentMethods,
    } = this.state


    let paymentMethod = PaymentMethodUtils.getDefault(paymentMethods)
    if(!paymentMethod){
      return
    }
    
    let amountInCent = Math.round(amount * 100)

    Backend.reverseFeeCalc(amountInCent, company, paymentPlan, paymentMethod)
    .then(data => {
      this.setState({
        reverseFeeAmount: data.total_charges
      })

      if(paymentPlan.reverse_fee !== "yes"){
        return
      }

      this._updatePaymentPlan(paymentPlan, data.amount, data.total_charges)
    })
    .catch(error => {

    })

  }, 300, false)

  _renderProductSelect(){
    let {
      error,
      amount,
      company,
      products,
      selectedProduct
    } = this.state

    let className = "input-group with-prepend"
    if(error){
      className += " validation-error"
    }

    return (
      <div id="product-select-container" className="input-group">
        <Select
          options={products}
          value={selectedProduct ? selectedProduct.id : null}
          placeholder="Select A Product"
          getOptionLabel={product => {
            let paymentPlan = product.payment_plans[0]
            let total = paymentPlan.total
            if(paymentPlan.reverse_fee === "optional"){
              total -= (paymentPlan.reverse_fee_amount || 0)
            }

            let price = Currency.format(paymentPlan.currency.symbol, total)

            let title = `${product.name}  (${price})`

            if(product.stock != null && product.stock <= 0){
              title = (
                <>
                  <s>{title}</s> Out Of Stock
                </>
              )
            }

            return title
          }}
          getOptionValue={product => product.id}
          className="form-control h-auto border-0 c-selectbox"
          onSelected={product => {
            if(product.stock != null && product.stock <= 0){
              return
            }
            this.props.onUpdatedProduct(product)
          }}
        />
      </div>
    )
  }

  _renderAmountInput(){

    let {
      error,
      amount,
      company,
      products,
      paymentPlan,
      reverseFeeEnabled,
      dynamicCurrency,
      nfcPrioritised,
      amountRaw,
    } = this.state

    let className = "input-group with-prepend"
    if(error){
      className += " validation-error"
    }

    if(products){
      return this._renderProductSelect()
    }

    if(!paymentPlan._original.open){
      return (
        <div className="row align-items-center pr-example-amount">
          <div className="col-4 col-sm-3">
            <label><strong>Amount: </strong></label>
          </div>
          <div className="col-8 col-sm-9">
            <p className="form-control-plaintext text-dark py-0 text-left">
              { Currency.format(paymentPlan.currency.symbol, paymentPlan.total) }
              { (paymentPlan.vat > 0) &&
                <small> (inc. { Currency.format(paymentPlan.currency.symbol, paymentPlan.vat) } {TAX})</small>
              }
            </p>
          </div>
        </div>
      )
    }

    return (

      <>

        { window.ReactNativeWebView && nfcPrioritised &&
          <div
            className="title"
          >
            Enter Amount
          </div>
        }

        <div className={className}>
          <div className="input-group-prepend input-group-pill">

            { !dynamicCurrency &&
              <span className="input-group-text input-group-payment-form h5">
                <span>{paymentPlan.currency.symbol}</span>
              </span>
            }

            { dynamicCurrency &&
                <AsyncSelect
                  endpoint={window.Api.Currencies}
                  className="dynamic-currency"
                  classNamePrefix={'custom-async-select'}
                  params={{ }}
                  value={General.getSingleAsyncOption(paymentPlan.currency)}
                  onSelected={currency => {
                    paymentPlan.currency = currency
                    this._updatePaymentPlan(paymentPlan, paymentPlan.total)
                  }}
                  getOptions={currencies => General.getAsyncOptions(currencies)}
                  validateTokens={false}
                  isSearchable={false}
                />
            }
          </div>

          { !window.ReactNativeWebView &&
            <input
              className="form-control h-auto border-0"
              type="number"
              autoComplete="off"
              placeholder="0.00"
              name="amount"
              defaultValue={amount || ""}
              disabled={!paymentPlan._original.open}
              onBlur={e => this._updateReverseFeeAmount()}
              onChange={e => {
                amount = Math.round(Currency.parseNumber(e.target.value) * 100)
                this._updatePaymentPlan(paymentPlan, amount)
              }}
            />
          }

          { window.ReactNativeWebView &&
            <AutosizeInput
              className="auto-size-input"
              type="number"
              inputmode="decimal"
              autoComplete="off"
              placeholder="0.00"
              name="amount"
              value={amountRaw || amount || ""}
              disabled={!paymentPlan._original.open}
              onBlur={e => this._updateReverseFeeAmount()}
              onChange={e => {
                amount = Math.round(Currency.parseNumber(e.target.value) * 100)
                this._updatePaymentPlan(paymentPlan, amount)
                this.setState({ amountRaw: e.target.value })
              }}
            />
          }

        </div>

      </>

    )
  }

  render() {
    let {
      error,
      amount,
      company,
      products,
      selectedProduct,
      paymentPlan,
      reverseFeeAmount,
      reverseFeeEnabled
    } = this.state

    let showReverseFeeToggle = paymentPlan._original.reverse_fee === "optional"

    if(products && !selectedProduct){
      showReverseFeeToggle = false
    }

    let productImage = selectedProduct?.featured_image || null

    let showSublineContainer = !error || paymentPlan.reverse_fee_amount > 0 || showReverseFeeToggle

    let reverseFeeText = "I'll pay the fee"
    if(reverseFeeAmount){
      reverseFeeText = (
        <p>
          I'll pay the fee of
          <b>
            { " "+Currency.format(paymentPlan.currency.symbol, reverseFeeAmount)  }
          </b>
        </p>
      )
    }
    if(reverseFeeAmount && !showReverseFeeToggle){
      reverseFeeText = (
        <p className="text">
          plus
          <b>
            { " "+Currency.format(paymentPlan.currency.symbol, reverseFeeAmount)  }
          </b>
          {" handling and admin fee"}
        </p>
      )
    }

    let inputAmountCss = "form-group mb-2 components payment amount"
    if(window.ReactNativeWebView && amount){
      inputAmountCss += " amount-has-value"
    }

    return (

      <>



        <div id="input-amount" className={inputAmountCss}>

          { this._renderAmountInput() }

          { error &&
            <div className="mt-1 validation-error-message">{ error.message }</div>
          }

          { showReverseFeeToggle &&
            <div className="mt-2">
              <Toggle
                checked={paymentPlan.reverse_fee === "yes"}
                label={reverseFeeText}
                onChange={e => {
                  if(e.target.checked){
                    paymentPlan.reverse_fee = "yes"
                    this._updateReverseFeeAmount()
                  }
                  else{
                    paymentPlan.reverse_fee = "no"
                    this._updatePaymentPlan(paymentPlan, amount * 100)
                  }
                }}
              />
            </div>
          }
          { (!showReverseFeeToggle && reverseFeeAmount) && reverseFeeText}
        </div>

        { productImage &&
          <div className="form-group product-image" style ={{ backgroundImage: "url("+productImage.original+")" }}></div>
        }

      </>

    )
  }

}
