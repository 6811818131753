import React from 'react'
import Modal from 'react-bootstrap/Modal'

import Backend from "../../../../utils/Backend"
import Notify from "../../../../utils/Notify";

import LockInputAppendIcon from "../LockInputAppendIcon"

const CONTAINER_ID = "boi-payment-form"
export default class CardModal extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      show: false,
      token: null,
      layout: props.modalClassName
    }
  }

  _setupPaymentForm(token, paymentMethod, paymentPlan){
    this.setState({ show: true })
    return new Promise((resolve, reject) => {
      let cashier = window.com.myriadpayments.api.cashier();
      cashier.init({
        baseUrl: paymentMethod.data.form_url,
        changeCallback : (event, data) => {
          if (data.height) {
            document.getElementById(CONTAINER_ID).height(data.height);
          }
        },
        successCallback : (event, data) => {
          console.log("Success:", event, data)
          this._handleCallback(data)
        },
        failureCallback : (event, data) => {
          console.log("Failure:", event, data)
          this._handleCallback(data)
        },
        cancelCallback : (event, data) => {
          console.log("Cancel:", event, data)
          this._handleCallback(data)
        }
      });

      let merchantIds = paymentMethod.data.merchant_ids
      let merchantId = merchantIds.default
      let currencyCode = paymentPlan.currency.code.toLowerCase()

      if(merchantIds[currencyCode]){
        merchantId = merchantIds[currencyCode]
      }

      cashier.show({
        containerId: CONTAINER_ID,
        merchantId: merchantId,
        integrationMode: "iframe",
        token: token,
      });
    })
  }

  _handleCallback(data){

    let url = new URL(window.location.href);
    let params = new URLSearchParams(url.search);

    url = this.props.subscriptionPayment ? window.location.href : url

    params.set("txId", data.txId)
    params.set("amount", data.amount)
    params.set("result", data.result)
    params.set("currency", data.currency)
    params.set("merchantTxId", data.merchantTxId)

    window.location = `${url}?${params.toString()}`
  }

  render() {
    let {
      show
    } = this.state

    let className = `boi-card-modal layout-${this.props.modalClassName} `

    return (

      <Modal
        show={show}
        onHide={() => this.props.onClose()}
        centered
        className={className}
      >
        <Modal.Body>
          <div>

            <h3 className="font-weight-bolder text-dark font-size-h4 skip mb-8">
              Enter Card Details
            </h3>

            <div id={CONTAINER_ID}/>

          </div>
        </Modal.Body>
      </Modal>

    )
  }
}

CardModal.defaultProps = {
  //modalClassName: 'sub-modal',
}
