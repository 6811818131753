import React from 'react';

import {
  isAndroid,
} from 'react-device-detect';

import PaymentForm from "./components/payment/Form";
import PaymentAttempt from "./components/payment/PaymentAttempt";

import PageLayout from "./components/PageLayout";
import LoadingPage from "./components/LoadingPage";

import Backend from "../utils/Backend";
import General from "../utils/General";

export default class Open extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      company: null
    }
  }

  componentDidMount() {
    let companySlug = this.props.companySlug
    let savedState = PaymentAttempt.getState()

    Backend.getSplink(companySlug, savedState?.paymentAttemptId)
    .then(company => {

      let paymentPlan = company.settings.payment_plan
      let paymentPlans = [paymentPlan]

      this.setState({
        company,
        savedState,
        paymentPlan,
        paymentPlans,
      })

    })

  }

  render() {
    let {
      company,
      savedState,
      paymentPlan,
      paymentPlans
    } = this.state

    if(!company){
      return <LoadingPage/>
    }

    let description = company.settings.collect_note === "description" ? company.description : null

    let nfcPrioritised = company.settings.nfc_prioritised && isAndroid && window.ReactNativeWebView

    let inApp = window.ReactNativeWebView != null
    return (
      <PageLayout company={company}>
        <PaymentForm
          company={company}
          savedState={savedState}
          paymentPlan={paymentPlan}
          paymentPlans={paymentPlans}
          paymentAttemptId={company.payment_attempt_id}
          questions={company.settings.questions}
          description={description}
          collectAddress={company.settings.collect_address}
          collectDescription={company.settings.collect_note === "yes"}
          collectEmail={company.settings.collect_email}
          nfcPrioritised={nfcPrioritised}
          dynamicCurrency={inApp}
          showAlternativePaymentMethods={!inApp}
        />
      </PageLayout>
    )
  }

}
