import React from "react";

import Select from "../common/Select";
import Toggle from "../common/Toggle";
import MobileSelect from "../common/MobileSelect";

import General from "../../../utils/General";

export default class PaymentPlanSelect extends React.Component{

  constructor(props) {
    super(props);

    this.state = {}
  }

  isValid(scrollToError){

    let { paymentPlan } = this.props

    if(paymentPlan.type === "recurring" && paymentPlan.installments !== null && paymentPlan.installments < 2){

      this.setState({ installmentError: "Installments must be 2 or more"})
      if(!scrollToError){
        General.scrollTo("#input-installments")
      }
      return false
    }

    return true
  }

  _clearErrors(){
    this.setState({ installmentError: null })
  }

  _getInstallments(){
    let installments = [
      "Continuous"
    ]

    for(var i=2; i < 21; i++){
      installments.push(i)
    }

    return installments
  }

  _getFrequencies(){
    return ["weekly", "monthly", "quarterly", "yearly"]
  }

  _renderRecurringMessage(){
    if(!this.props.showSubscriptionMessage){
      return null
    }

    return (
      <div className="form-group payment-recurring-msg">
        <div className="row">
          <div className="col-12">
            <small>By creating a recurring/installment plan, you will be charged immediately for the amount shown above and then you will be automatically charged that same amount each billing cycle until your plan expires or is canceled.</small>
          </div>
        </div>
      </div>
    )
  }

  _renderMobile(paymentPlan){
    let { showAdvancedOptions } = this.state
    return (
      <div className="advanced-options">

        <div
          className="d-flex flex-center toggle"
          onClick={() => this.setState({ showAdvancedOptions: !showAdvancedOptions})}
        >

          { showAdvancedOptions &&
            <>
              HIDE ADDITIONAL OPTIONS
              <span className="material-icons">keyboard_arrow_down</span>
            </>
          }

          { !showAdvancedOptions &&
            <>
              SHOW ADDITIONAL OPTIONS
              <span className="material-icons">keyboard_arrow_up</span>
            </>
          }


        </div>

        { showAdvancedOptions &&
          <div className="options">
           { this._renderTypeSelect(paymentPlan) }
           { paymentPlan.type === "recurring" &&
             this._renderSubscriptionDetailMobile(paymentPlan)
           }
          </div>
        }

      </div>
    )
  }

  _renderSubscriptionDetailMobile(paymentPlan){
    if(paymentPlan.type !== "recurring"){
      return null
    }

    let frequencies = this._getFrequencies()
    let installments = this._getInstallments()

    return (
      <div className="payment-plan">
        <MobileSelect
          value={ paymentPlan.frequency }
          options={frequencies}
          getOptionLabel={frequency => General.toTitleCase(frequency)}
          getOptionValue={frequency => frequency}
          prompt="Frequency"
          onSelected={frequency => {
            paymentPlan.frequency = frequency
            this.props.onUpdated(paymentPlan)
          }}
        />

        <div className="mobile-input">
            <div className="custom">
              <div className="title">Installments</div>
              <div className="field">
                { this._renderSubcriptionInstallments(paymentPlan) }
              </div>
            </div>
        </div>

        { this._renderRecurringMessage() }
      </div>
    )
  }

  _renderSubscriptionDetail(paymentPlan){
    return (
      <div className="payment-plan">

        <div className="form-group align-items-center period mb-2">
          <div className="row align-items-center">
            <div className="col-4 col-sm-3"><label><strong>Period: </strong></label></div>
            <div className="col-8 col-sm-9">
              { this._renderSubcriptionFrequency(paymentPlan) }
            </div>
          </div>
        </div>

        <div className="form-group align-items-center installments">
          <div className="row align-items-center">
            <div className="col-4 col-sm-3"><label><strong>Installments: </strong></label></div>
            <div className="col-8 col-sm-9">
              { this._renderSubcriptionInstallments(paymentPlan) }
            </div>
          </div>
        </div>

        { this._renderRecurringMessage() }

      </div>
    )
  }

  _renderSubcriptionFrequency(paymentPlan){
    // if the plan has a set type and frequecy we cant let the user select one
    if(paymentPlan._original.type === "recurring" && paymentPlan._original.frequency != null){
      return <p className="form-control-plaintext text-dark py-0 text-left">{ General.toTitleCase(paymentPlan._original.frequency) }</p>
    }

    let frequencies = this._getFrequencies()
    return (
      <div className="input-group">
        <Select
          value={paymentPlan.frequency || frequencies[0]}
          options={frequencies}
          getOptionLabel={frequency => General.toTitleCase(frequency)}
          getOptionValue={frequency => frequency}
          className="form-control h-auto border-0 c-selectbox"
          onSelected={frequency => {
            paymentPlan.frequency = frequency
            this.props.onUpdated(paymentPlan)
          }}
        />
      </div>
    )
  }

  _renderSubcriptionInstallments(paymentPlan){
    // if the plan has a set installments type we cant let the user select
    let isLocked = paymentPlan._original.type != "optional"
    if(isLocked || paymentPlan._original.installments > 0){
      return <p className="form-control-plaintext text-dark py-0 text-left">{ paymentPlan._original.installments || "Continuous" }</p>
    }

    let installments = this._getInstallments()

    let { installmentError } = this.state
    let className = "input-group"

    if(installmentError){
      className += " validation-error"
    }

    return (
      <>
        <div className={className} id="input-installments">
          <input
            className={"form-control"}
            type="number"
            min={2}
            placeholder="Leave blank for continuous"
            name={"installments"}
            value={paymentPlan.installments}
            onChange={(e) => {
              paymentPlan.installments = e.target.value || null
              this.props.onUpdated(paymentPlan)
              this._clearErrors()
            }}
          />
        </div>
        { installmentError &&
          <span className="validation-error-message">{ installmentError }</span>
        }
      </>
    )
  }

  _renderTypeSelect(paymentPlan){

    let types = [
      {
        value: "one_time",
        label: "One Time Payment"
      },
      {
        value: "recurring",
        label: "Recurring / Installment Plan"
      }
    ]

    if(window.ReactNativeWebView){
      return (
        <MobileSelect
          value={ paymentPlan.type }
          options={types}
          getOptionLabel={type => type.label}
          getOptionValue={type => type.value}
          prompt="Payment Type"
          onSelected={option => {
            paymentPlan.type = option.value
            this.props.onUpdated(paymentPlan)
          }}
        />
      )
    }

    return (
      <Toggle
        checked={paymentPlan.type === "recurring"}
        label="This is a recurring payment"
        onChange={e => {
          if(e.target.checked){
            paymentPlan.type = "recurring"
            paymentPlan.frequency = "weekly"
            paymentPlan.installments = null
          }
          else{
            paymentPlan.type = "one_time"
            paymentPlan.frequency = null
            paymentPlan.installments = null
          }

          this.props.onUpdated(paymentPlan)
          this._clearErrors()
        }}
      />
    )
  }

  render() {

    let {
      paymentPlans,
      paymentPlan,
    } = this.props

    if(window.ReactNativeWebView){
      return this._renderMobile(paymentPlan)
    }

    if(paymentPlans.length === 1 && paymentPlan._original.type === "one_time"){
      return null
    }

    if(paymentPlans.length === 1 && paymentPlan._original.type === "recurring"){
      return this._renderSubscriptionDetail(paymentPlan)
    }

    if(paymentPlans.length === 1 && paymentPlan._original.type === "optional"){
      return (
        <div className="mb-2 mt-2">
         { this._renderTypeSelect(paymentPlan) }
         { paymentPlan.type === "recurring" &&
           this._renderSubscriptionDetail(paymentPlan)
         }
        </div>
      )
    }

    // TODO:  Render Multiple Payment Plans, perhaps an accordion picker?
    return null
  }

}

PaymentPlanSelect.defaultProps = {
  showSubscriptionMessage: true
}
