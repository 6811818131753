import React from 'react'

export default class InteracPayButton extends React.Component {
  render() {
    return (
      <div className="interac-pay-button">

        <button
          onClick={() => this.props.onClick()}
          className="btn btn-primary btn-interac btn-block"
        >

          <span className="d-flex align-items-center justify-content-center">

            <span className="svg-icon ml-2">

              <svg version="1.1" id="layer" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 0 652 652" xmlSpace="preserve">

                <g>
                  <path class="st0" d="M570.8,529.1c0,23-18.7,41.7-41.7,41.7H112.5c-23,0-41.7-18.7-41.7-41.7V112.5c0-23,18.6-41.7,41.7-41.7h416.7
		c23,0,41.7,18.7,41.7,41.7V529.1z"/>
                  <path d="M144.9,351.6c-7-0.7-11.8,1.6-11.8,1.6l0-64.5l23.6-5.6v10.1c0,0,6.4-14.1,23.3-18.1c18.7-4.4,24.7,6.5,24.7,22.1l0,64.2
		l-24.8,5.8l0-60.7c0-9.5-4.4-11.4-9.8-10.2c-7.1,1.6-12,9.5-12,19.5v41.7C157.9,357.7,153.2,352.4,144.9,351.6"/>
                  <path d="M349.9,327.2l0-89.5l23.4-5.6v11.8c0,0,6.1-15.4,20.1-19c4.3-1.1,6.5-0.4,6.5-0.4v22.8c0,0-5.3-0.2-12.3,2.6
		c-8.6,3.4-13,10.8-13,22.2v49.4L349.9,327.2z"/>
                  <path d="M226.8,356.3c0,0-3.8-5.7-3.8-25.8v-44.6l-12,2.8v-18.3l12-2.9v-20l24.9-5.9l0,20l17.6-4.2v18.3l-17.6,4.1
		c0,0,0,36.5,0,45.4c0,20.7,5.5,24.6,5.5,24.6L226.8,356.3z"/>
                  <path d="M268,303c0-15.9,2.3-27.5,7.1-36.4c5.8-10.6,15.3-17.5,29.1-20.6c27.2-6.2,37,9.9,36.7,31.6c-0.1,7.7-0.1,11.5-0.1,11.5
		l-48,11.3v0.8c0,15.1,3.2,22.3,12.7,20.3c8.3-1.8,10.6-6.9,11.3-13.4c0.1-1,0.2-3.7,0.2-3.7l22.5-5.4c0,0,0.1,1.8,0,3.9
		c-0.2,8.6-2.7,29.9-34,37.3C275.9,347.2,268,329.1,268,303 M305.3,262.6c-8,1.8-12.2,9.6-12.3,22.6l24.2-5.8c0-0.6,0-1.9,0-3.9
		C317.1,265.8,314.1,260.6,305.3,262.6"/>
                  <path d="M484,250.7c-0.9-27.3,6.1-48.9,36.5-55.8c19.4-4.4,26.8,0.6,30.5,5.5c3.6,4.6,5,10.9,5,19.7v1.6l-23.8,5.6c0,0,0-3.2,0-3.3
		c0-10.4-2.9-14.4-10.5-12.5c-9,2.3-12.6,10.8-12.6,27.8v8c0,17.3,2.4,25.2,12.7,23c9-1.9,10.2-9.8,10.3-16.7c0-1.1,0.1-4.6,0.1-4.6
		l23.8-5.6c0,0,0,1.8,0,3.7c-0.1,22.6-12,36.9-34.3,42.1C491,296.4,484.9,278.6,484,250.7"/>
                  <path d="M401.7,288.9c0-20.8,12.1-27.3,30.4-34.9c16.4-6.8,16.8-10.2,16.9-16.1c0.1-5-2.2-9.2-10.4-7.1c-7.5,1.9-9.5,7.3-9.9,11.7
		c-0.2,2.3-0.1,4.6-0.1,4.6l-23.1,5.5c0,0-0.3-7.1,1.6-13.7c3.7-12.4,14.7-20.7,33.5-25.1c24.5-5.7,32.7,5.1,32.8,21.8
		c0,0,0,22.7,0,39.7c0,19.1,3.6,22,3.6,22l-22.7,5.3c0,0-2.1-4.4-3-8.1c0,0-5,12.5-22.1,16.5C411.2,315.2,401.7,304.1,401.7,288.9
		 M448.9,260.5c0,0-4.7,3.5-12.1,7.1c-7.5,3.7-10.9,8.2-10.9,15.2c0,6.1,3.7,10.1,10.6,8.4c7.3-1.8,12.4-8.7,12.4-18.2
		C448.8,267.6,448.9,262.2,448.9,260.5"/>
                  <path class="st1" d="M165.8,390.5l39.7,27c5.5,3.7,9.1,9.9,9.1,17c0,1.2,0,97.2,0,117.8c-9.8,0-32.8,0-45,0c-23.8,0-43-19.3-43-43
		l0-134.2c0-8.5,6-15.4,15.5-15.5c9.1-0.1,15.4,7.1,15.4,15.5c0,8.4,0.1,77,0.1,77c0,2.3,1.4,4.2,4.2,4.2c2.8-0.1,4.2-2,4.2-4.7
		C165.8,448.7,165.8,390.5,165.8,390.5"/>
                  <path d="M85.6,398.6c-1.4,2.1-2.2,4.7-2.2,7.4c0,7.5,6.1,13.6,13.6,13.6c1,0,1.9-0.1,2.8-0.3c1.8-0.3,10.7-2.4,18.7-4.3v-24.2
		L85.6,398.6z"/>
                  <path d="M85.6,431.7c-1.4,2.2-2.2,4.7-2.2,7.5c0,7.5,6.1,13.7,13.6,13.7c1,0,1.9-0.1,2.8-0.3c1.8-0.4,10.7-2.4,18.7-4.4V424
		L85.6,431.7z"/>
                  <path d="M85.6,464.9c-1.4,2.1-2.2,4.7-2.2,7.4c0,7.6,6.1,13.7,13.6,13.7c1,0,1.9-0.1,2.8-0.3c1.8-0.4,10.7-2.4,18.7-4.3v-24.3
		L85.6,464.9z"/>
                  <polygon points="92.2,388 92.2,244.4 118.4,238.2 118.4,381.8 	" />
                  <path d="M529.9,179.4c-14.1,0-25.6-11.5-25.6-25.6c0-14.1,11.5-25.6,25.6-25.6c14.1,0,25.5,11.5,25.5,25.6
		C555.5,167.9,544,179.4,529.9,179.4 M529.9,131.4c-12.3,0-22.4,10-22.4,22.4c0,12.3,10,22.4,22.4,22.4c12.3,0,22.4-10,22.4-22.4
		C552.3,141.4,542.3,131.4,529.9,131.4"/>
                  <path d="M520.6,138.5h11c4.4,0,7.9,2.2,7.9,8c0,4.1-1.8,7.2-5.2,7.7v0c3.1,0.3,4.7,2,4.9,6.4c0.1,2,0.1,4.4,0.2,6.3
		c0.1,1.5,0.8,2.3,1.5,2.7h-5.6c-0.5-0.6-0.7-1.5-0.8-2.8c-0.2-1.9-0.1-3.6-0.2-5.9c-0.1-3.4-1.1-4.9-4.6-4.9h-4.1v13.6h-5V138.5z
		 M529.6,152.5c3.2,0,5-1.9,5-5.1c0-3.3-1.4-5.1-4.7-5.1h-4.2v10.1H529.6z"/>
                </g>
              </svg>

            </span>

            <span className="font-weight-bolder">
              Pay By Interac
            </span>

          </span>


        </button>

      </div>
    )
  }
}

InteracPayButton.defaultProps = {
}
