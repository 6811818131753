import React, { useEffect, useState } from "react";
import { Portal } from 'react-portal';

const PaymentMethodPortal = ({ type, children }) => {

  let [show, setShow] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setShow(true)
    }, 300)
  }, [])

  if(!show){
    return null
  }

  return (
    <Portal node={document && document.getElementById(`payment-method-section-${type}`)}>
      { children }
    </Portal>
  )
}

export default PaymentMethodPortal
