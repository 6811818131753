import React from "react";

import CreditCardInput from 'react-credit-card-input';
import "../../../../assets/scss/npm-packages/react-credit-cards/react-credit-cards.scss"

import Customer from "../Customer"

import PaymentMethodPortal from '../PaymentMethodPortal';

import LockInputAppendIcon from "../LockInputAppendIcon"

import Separator from "../../common/Separator"

import PayButton from "../button/PayButton"

import Backend from "../../../../utils/Backend"
import Currency from "../../../../utils/Currency";
import AsyncStorage from "../../../../utils/AsyncStorage";
import General from "../../../../utils/General";

import BankPayButton from "../button/BankPayButton";


export default class PaymentMethods extends React.Component{

  constructor(props) {
    super(props);

    this.state = {
      ...this._getState(props),
      loading: true
    }

    this.form = React.createRef()
    this.customer = React.createRef()
    this.methodForm = React.createRef()
    this.cardInput = React.createRef()
  }

  componentDidMount(){
    window.onCardScanned = (event) => {
      General.hideKeyboard()
      this._handleMessage(event)
    };
  }

  _handleMessage(message){
    if(message?.type !== "card"){
      return
    }

    let { card } = message
    this.setState({
      expiry: card.expiryDate,
      cardNumber: card.cardNumber,
      cvc: card.cvv,
      nfc: message.nfc || false,
    }, () => {
      this.props.onPayPressed(this.state.paymentMethod)
    })
  }

  componentWillReceiveProps(nextProps) {
    this.setState(this._getState(nextProps))
  }

  _getState(nextProps){
    let {
      company,
      customer,
      paymentPlan,
      nfcPrioritised,
    } = nextProps

    let paymentMethod = nextProps.paymentMethods.card
    return {
      company,
      customer,
      paymentPlan,
      paymentMethod,
      nfcPrioritised,
    }
  }

  handleAction(response){
    if(response.method_form) {
      return this._validateMethodForm(response)
    }
    if(response.acs) {
      return this._acsRedirect(response)
    }
  }

  _validateMethodForm(response){
    return new Promise((resolve, reject) => {
      this.setState({
        methodFormData: response.method_form
      }, () => this.methodForm.current.submit())

      // wait 1 second to allow methodForm to communicate with acs
      setTimeout(() => {
        resolve(response)
      }, 1000 * 1)
    })
  }

  _acsRedirect(response){
    this.props.onSaveState(response)
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        window.location.href = response.acs.redirect_url
      }, 1000 * 1)
    })
  }

  _getBrowserData(){
    const colorDepth = window.screen.colorDepth
    return {
      accept_header: "application/json,text/plain,text/html,*/*",
      java_enabled: window.navigator.javaEnabled(),
      language: window.navigator.language,
      color_depth: colorDepth === 30 ? 24 : colorDepth,
      screen_height: window.screen.height,
      screen_width: window.screen.height,
      time_zone_offset: new Date().getTimezoneOffset(),
      user_agent: window.navigator.userAgent,
    }
  }

  isValid(scrollToError){
    let { nfcPrioritised } = this.state

    if(nfcPrioritised){
      return true
    }

    if(this.customer.current && !this.customer.current.isValid(scrollToError)){
      return false
    }

    let { cardNumber, expiry, cvc} = this.state

    if(!cardNumber || !cvc){
      return false
    }
    if(!expiry || expiry.indexOf("/") == -1){
      return false
    }

    return true
  }

  getPaymentDetails(paymentMethod){
    let {
      paymentForm,
      cardNumber,
      expiry,
      cvc,
      nfc,
      nfcPrioritised
    } = this.state

    var cse = window.Encrypto.createEncryption(paymentMethod.data.public_key);

    let data = {
      card_number: cardNumber.replace(/\s/g, ""),
      expiration_month: expiry.split("/")[0].trim(),
      expiration_year: "20" + expiry.split("/")[1].trim()
    };

    // cvc not guaranteed as this card may be scanned by nfc
    // and cse cant encrypt null
    if(cvc){
      data.cvv = cvc
    }

    var card = cse.encrypt(data);

    card.cvv = card.cvv || null

    return new Promise((resolve,reject) => {
      let url = new URL(window.location.href);
      let params = new URLSearchParams(url.search);

      url = this.props.subscriptionPayment ? window.location.href : url
      let key = this.props.subscriptionPayment ? "empsubstatus" : "empstatus"

      params.set(key, "success")
      let successUrl = `${url}?${params.toString()}`
      params.set(key, "failure")
      let failureUrl = `${url}?${params.toString()}`

      resolve({
          paymentMethod,
          paymentDetails: {
            card,
            nfc: card.cvv == null,
            success_url: successUrl,
            failure_url: failureUrl,
            browser: this._getBrowserData()
          }
        })
    })
  }

  render() {

    let {
      company,
      customer,
      loading,
      paymentPlan,
      methodFormData,
      challengeForm,
      paymentMethod,
      cardNumber,
      expiry,
      cvc,
      nfcPrioritised
    } = this.state


    if(nfcPrioritised){
      return null
    }

    return (
      <PaymentMethodPortal type={"card"}>

        <div className="components payment emerchantpay payment-methods">

          {methodFormData &&
            <div className={"acs-method-form"}>
              <iframe name="iframeMethodForm"></iframe>
              <form
                ref={this.methodForm}
                method="post"
                action={methodFormData.threeds_method_url}
                target="iframeMethodForm"
              >
                <input name="unique_id" value={methodFormData.unique_id} hidden/>
                <input name="signature" value={methodFormData.signature} hidden/>
              </form>
            </div>
          }

          {challengeForm &&
              <form
                  ref={this.form}
                  action={challengeForm.acs_url}
                  method={"post"}
              >

                <input name={"TermURL"} hidden value={challengeForm.term_url}/>

                { challengeForm.c_req &&
                  <input name={"creq"} hidden value={challengeForm.c_req}/>
                }

                { challengeForm.payer_authentication_request &&
                  <input name={"PaReq"} hidden value={challengeForm.payer_authentication_request}/>
                }

                { challengeForm.session_data !== undefined &&
                  <input name={"threeDSSessionData"} hidden value={challengeForm.session_data}/>
                }

                { challengeForm.merchant_data !== undefined &&
                  <input name={"MD"} hidden value={challengeForm.merchant_data}/>
                }
              </form>
          }
          
          <Customer
            ref={this.customer}
            customer={customer}
            collectEmail={this.props.collectEmail}
            collectName={true}
            collectBillingAddress={true}
            onUpdated={customer => {
              this.props.onUpdatedCustomer(customer)
            }}
          />

          <div
            className={`form-emerchantpay format-${company.settings.layout}`}
          >
            <div className="form-group react-credit-cards mb-2 text-left">
              <div className="input-group with-append">
                <div className="form-control h-auto border-0">
                  <CreditCardInput
                    ref={this.cardInput}
                    cardNumberInputProps={{ value: cardNumber, onChange: e => this.setState({ cardNumber: e.target.value}) }}
                    cardExpiryInputProps={{ value: expiry, onChange: e => this.setState({ expiry: e.target.value }) }}
                    cardCVCInputProps={{ value: cvc, onChange: e => this.setState({ cvc: e.target.value }) }}
                    fieldClassName="c-feild"
                    cardImageClassName="c-card-image"
                  />
                </div>
                <LockInputAppendIcon/>
              </div>
            </div>
          </div>

          <PayButton
            payButtonTitle={this.props.payButtonTitle}
            payButtonContainerId={this.props.payButtonContainerId}
            backgroundColor={company.settings.primary_color}
            paymentPlan={this.state.paymentPlan}
            onClick={() => this.props.onPayPressed(paymentMethod)}
          />

        </div>
      </PaymentMethodPortal>
    )
  }

}
