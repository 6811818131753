import React from "react";

import StripePaymentMethods from "./stripe/PaymentMethods"

import AibPaymentMethods from "./aib/PaymentMethods"

import BoipaPaymentMethods from "./boipa/PaymentMethods"

import EMerchantPayPaymentMethods from "./emerchantpay/PaymentMethods"

import CardConnectPaymentMethods from "./cardconnect/PaymentMethods"

import ApayloPaymentMethods from "./apaylo/PaymentMethods"

import TSYSPaymentMethods from "./tsys/PaymentMethods"

import TokenPaymentMethods from "./token/PaymentMethods"

import PaymentMethodUtils from "../../../utils/PaymentMethods";

const PROCESSORS = [
  "stripe",
  "aib",
  "boipa",
  "emerchantpay",
  "cardconnect",
  "apaylo",
  "tsys",
  "token",
]

export default class PaymentMethods extends React.Component{

  constructor(props) {
    super(props);

    this.stripe = React.createRef()
    this.aib = React.createRef()
    this.boipa = React.createRef()
    this.emerchantpay = React.createRef()
    this.cardconnect = React.createRef()
    this.apaylo = React.createRef()
    this.tsys = React.createRef()
    this.token = React.createRef()
  }

  isValid(scrollToError, paymentMethod){
    for(var i = 0; i < PROCESSORS.length; i++){
      let key = PROCESSORS[i]
      if(this._isProcessor(key, paymentMethod) && this[key].current){
        return this[key].current.isValid(scrollToError)
      }
    }
    return true
  }

  _isProcessor(slug, paymentMethod){
    let processor = paymentMethod.processor
    return processor.indexOf(slug) > -1
  }

  getPaymentDetails(paymentMethod){
    for(var i = 0; i < PROCESSORS.length; i++){
      let key = PROCESSORS[i]
      if(this._isProcessor(key, paymentMethod) && this[key].current){
        return this[key].current.getPaymentDetails(paymentMethod)
      }
    }
    return null
  }

  handleAction(response, paymentMethod){
    for(var i = 0; i < PROCESSORS.length; i++){
      let key = PROCESSORS[i]
      if(this._isProcessor(key, paymentMethod) && this[key].current){
        return this[key].current.handleAction(response, paymentMethod)
      }
    }
    return null
  }

  render() {

    let methodsByProcessor = PaymentMethodUtils.getByProcessor(this.props.paymentMethods)
    return Object.entries(methodsByProcessor).map((processorPaymentMethods) => {
      let [processor, paymentMethods] = processorPaymentMethods
      if(processor.indexOf("stripe") > -1){
        return (
          <StripePaymentMethods
            {...this.props}
            ref={this.stripe}
            paymentMethods={paymentMethods}
            onUpdatedCustomer={customer => this.props.onUpdatedCustomer(customer)}
            onPayPressed={(paymentMethod, paymentDetails) => this.props.onPayPressed(paymentMethod, paymentDetails)}
            collectEmail={this.props.collectEmail}
            collectAddress={this.props.collectAddress}
          />
        )
      }
      else if(processor === "aib" || processor === "aib_test"){
        return (
            <AibPaymentMethods
              {...this.props}
              ref={this.aib}
              paymentMethods={paymentMethods}
              onSaveState={paymentDetails => this.props.onSaveState(paymentDetails)}
            />
        )
      }
      else if(processor === "boipa" || processor === "boipa_test"){
        return (
            <BoipaPaymentMethods
              {...this.props}
              ref={this.boipa}
              paymentMethods={paymentMethods}
              onSaveState={paymentDetails => this.props.onSaveState(paymentDetails)}
            />
        )
      }
      else if(processor === "emerchantpay" || processor === "emerchantpay_test"){
        return (
            <EMerchantPayPaymentMethods
              {...this.props}
              ref={this.emerchantpay}
              paymentMethods={paymentMethods}
              onPayPressed={(paymentMethod, paymentDetails) => this.props.onPayPressed(paymentMethod, paymentDetails)}
              onSaveState={paymentDetails => this.props.onSaveState(paymentDetails)}
            />
        )
      }
      else if(processor === "card_connect" || processor === "card_connect_test"){
        return (
            <CardConnectPaymentMethods
              {...this.props}
              ref={this.cardconnect}
              paymentMethods={paymentMethods}
              onPayPressed={(paymentMethod, paymentDetails) => this.props.onPayPressed(paymentMethod, paymentDetails)}
            />
        )
      }
      else if(processor === "apaylo" || processor === "apaylo_test"){
        return (
            <ApayloPaymentMethods
              {...this.props}
              ref={this.apaylo}
              paymentMethods={paymentMethods}
              onPayPressed={(paymentMethod, paymentDetails) => this.props.onPayPressed(paymentMethod, paymentDetails)}
            />
        )
      }
      else if(processor === "tsys" || processor === "tsys_test"){
        return (
            <TSYSPaymentMethods
              {...this.props}
              ref={this.tsys}
              paymentMethods={paymentMethods}
              onPayPressed={(paymentMethod, paymentDetails) => this.props.onPayPressed(paymentMethod, paymentDetails)}
            />
        )
      }
      else if(processor === "token" || processor === "token_test"){
        return (
            <TokenPaymentMethods
              {...this.props}
              ref={this.token}
              paymentMethods={paymentMethods}
              onPayPressed={(paymentMethod, paymentDetails) => this.props.onPayPressed(paymentMethod, paymentDetails)}
            />
        )
      }

      return null
    })
  }
}
