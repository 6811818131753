import React from "react";

import Currency from "../../../../utils/Currency";
import Discount from "../../../../utils/Discount";

export default class BankPayButton extends React.Component {
  render() {
    let { paymentPlan, paymentMethod, title } = this.props
    let discount = Discount.get(paymentPlan, paymentMethod)

    let discountAmount = Discount.getAmount(paymentPlan, paymentMethod)
    let discountedTotal = Discount.getDiscountedTotal(paymentPlan, paymentMethod)
    let vatExemptDiscountAmount = paymentPlan.total - discountedTotal

    let discountAmountFormatted = ""
    let paymentAmountFormatted = ""
    let vatExemptDiscountFormatted = ""
    if(discountAmount){
      let symbol = paymentPlan.currency.symbol
      discountAmountFormatted = `${Currency.format(symbol, discountAmount)}`
      paymentAmountFormatted = `${Currency.format(symbol, discountedTotal)}`
      vatExemptDiscountFormatted  = `${Currency.format(symbol, vatExemptDiscountAmount)}`
    }

    return (
      <div className="bank-pay-button mb-2">
        <button
          onClick={() => this.props.onClick()}
          className="btn btn-primary btn-white btn-block"
          style={{position: "relative"}}
          disabled={!paymentPlan.total}
        >
          <span className="d-flex align-items-center justify-content-center ml-4">
            <span className="svg-icon svg-icon-2x">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M20 19.725V18.725C20 18.125 19.6 17.725 19 17.725H5C4.4 17.725 4 18.125 4 18.725V19.725H3C2.4 19.725 2 20.125 2 20.725V21.725H22V20.725C22 20.125 21.6 19.725 21 19.725H20Z"
                  // fill={this.props.primaryColor}
                  fill="#3699FF"
                />
                <path
                  opacity="1.0"
                  d="M22 6.725V7.725C22 8.325 21.6 8.725 21 8.725H18C18.6 8.725 19 9.125 19 9.725C19 10.325 18.6 10.725 18 10.725V15.725C18.6 15.725 19 16.125 19 16.725V17.725H15V16.725C15 16.125 15.4 15.725 16 15.725V10.725C15.4 10.725 15 10.325 15 9.725C15 9.125 15.4 8.725 16 8.725H13C13.6 8.725 14 9.125 14 9.725C14 10.325 13.6 10.725 13 10.725V15.725C13.6 15.725 14 16.125 14 16.725V17.725H10V16.725C10 16.125 10.4 15.725 11 15.725V10.725C10.4 10.725 10 10.325 10 9.725C10 9.125 10.4 8.725 11 8.725H8C8.6 8.725 9 9.125 9 9.725C9 10.325 8.6 10.725 8 10.725V15.725C8.6 15.725 9 16.125 9 16.725V17.725H5V16.725C5 16.125 5.4 15.725 6 15.725V10.725C5.4 10.725 5 10.325 5 9.725C5 9.125 5.4 8.725 6 8.725H3C2.4 8.725 2 8.325 2 7.725V6.725L11 2.225C11.6 1.925 12.4 1.925 13.1 2.225L22 6.725ZM12 3.725C11.2 3.725 10.5 4.425 10.5 5.225C10.5 6.025 11.2 6.725 12 6.725C12.8 6.725 13.5 6.025 13.5 5.225C13.5 4.425 12.8 3.725 12 3.725Z"
                  // fill={this.props.primaryColor}
                  fill="#3699FF"
                />
              </svg>
            </span>
            <span className="font-weight-bolder">{ title }</span>
          </span>
        </button>
      </div>
    );
  }
}

BankPayButton.defaultProps = {
  title: "Pay By Bank"
};
