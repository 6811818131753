import React from "react";

import { withRouter } from "react-router-dom"

import LoadingOverlay from 'react-loading-overlay';

import Tour from "reactour";

import Branding from "../common/Branding";

import PaymentAttempt from "../payment/PaymentAttempt";
import PaymentMethods from "../payment/PaymentMethods"
import PaymentMethodsContainer from "../payment/PaymentMethodsContainer"

import PaymentMethodUtils from "../../../utils/PaymentMethods";

import Backend from "../../../utils/Backend";
import Notify from "../../../utils/Notify";
import Currency from "../../../utils/Currency";
import Event from "../../../utils/Event";
import Intercom from "../../../utils/Intercom";
import AsyncStorage from '../../../utils/AsyncStorage'

let stripePromise = null

export class SubscriptionForm extends React.Component{

  constructor(props) {
    super(props);

    this.state = this._getState(props)

    this.paymentMethods = React.createRef()

    setTimeout(() => {
      this.showTour()
    }, 3000)
  }

  componentDidMount(){
    Event.on('SHOW_ACTIVATE_TOUR', () => this.showTour(true))
    let data = PaymentAttempt.getState()
    if(data){
      this.setState(data, () => {
        if(data.paymentDetails){
          this._pay(window.General.PlatformPaymentMethods.card, data.paymentDetails)
        }
      })
    }
  }

  showTour(shakeTour=null){
    this.setState({ shakeTour, isTourOpen: true, tourStep: 0 })
  }

  _getState(props){
    let {
      company,
      authToken,
    } = props

    let subscription = company.subscription

    return {
      company,
      authToken,
      slug: company.slug,
      customer: {
        first_name: "",
        last_name: "",
        email: null
      },
      paymentPlan: {
        vat: 0,
        total: subscription.package.total,
        subtotal: subscription.package.total,
        currency: subscription.package.currency,
        frequency: subscription.package.frequency,
        type: subscription.package.frequency === "one_time" ? "one_time" : "recurring"
      }
    }
  }

  _isValid(paymentMethod){
    let isValid = true
    if(!this.paymentMethods.current.isValid(isValid, paymentMethod)){
      isValid = false
    }

    return isValid
  }

  _handlePay(paymentMethod){
    if(!this._isValid(paymentMethod)){
      return false
    }

    this.setState({ isLoading: true })
    this.paymentMethods.current.getPaymentDetails(paymentMethod)
    .then(({paymentMethod, paymentDetails}) => {
      this._pay(paymentMethod, paymentDetails)
    })
    .catch(error => {
      this.setState({isLoading: false})
      Notify.error(error.message)
    })
  }

  _pay(paymentMethod, paymentDetails){
    let {
      company,
      customer,
      authToken
    } = this.state


    paymentDetails.customer = customer
    let data = {
      package: company.subscription.package.id,
      payment_details: paymentDetails
    }

    this.setState({isLoading: true})
    Backend.purchaseSubscription(company, authToken, data)
    .then(response => {
      if(response.requires_action){
        return this.paymentMethods.current.handleAction(response)
        .then(paymentDetails => {
          return this._pay(paymentMethod, paymentDetails)
        })
      }

      this.props.onSuccessfulPayment()
    })
    .catch(error => {
      this.setState({isLoading: false})
      Notify.error(error.message)
    })
  }


  render() {

    let {
      company,
      tourStep,
      shakeTour,
      isLoading,
      customer,
      paymentPlan
    } = this.state

    let className = "form activate-form components subscription-form"
    if(isLoading){
      className += " activate-form-loading"
    }

    if (!company || !company.subscription) {
      return null
    }

    let maskClassName = tourStep === 0 ? "tour-mask-hidden" : "tour-mask-visible"

    let pkg = company.subscription.package
    let payButtonTitle = `Pay ${Currency.format(pkg.currency.symbol, pkg.total)}`
    payButtonTitle += ` (per ${pkg.frequency.replace('ly', '')})`
    return (
      <>
        <div className={className}>

          <div id="activate-title" className="pb-13 pt-lg-0 pt-5">
            <h3 className="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">
              Activate Your Account
            </h3>
            <p className="font-weight-bold activate-form-explainer">
              Enter your card details to activate your account and start processing payments.
            </p>
          </div>

          <form autoComplete="off">

            <PaymentMethods
              ref={this.paymentMethods}
              customer={customer}
              company={company}
              products={[]}
              product={null}
              payButtonTitle={payButtonTitle}
              payButtonContainerId="sub-payment-form-footer"
              paymentMethods={window.General.PlatformPaymentMethods}
              paymentPlan={paymentPlan}
              collectEmail={false}
              collectAddress={false}
              subscriptionPayment={true}
              onUpdated={paymentDetails => {
                this.setState({ paymentDetails })
              }}
              onUpdatedCustomer={customer => {
                this.setState({ customer })
              }}
              onPayPressed={(paymentMethod, paymentDetails) => {
                if(paymentMethod.type !== "digital_wallet"){
                  this._handlePay(paymentMethod)
                  return
                }

                if(!this._isValid(paymentMethod)){
                  return
                }

                this._pay(paymentMethod, paymentDetails)
              }}
              onSaveState={(paymentDetails) => {
                this.setState({ paymentDetails}, () => {
                  AsyncStorage.setItem("payment_data", JSON.stringify(this.state))
                })
              }}
            />

            <PaymentMethodsContainer
              paymentMethods={window.General.PlatformPaymentMethods}
              showAlternativePaymentMethods={true}
              payButtonContainerId="sub-payment-form-footer"
            />

            {
              !window.General.AssociationId &&
              <div className="form-group">
                <button
                  type="button"
                  className="btn btn-block font-size-h6 font-weight-bolder py-4 my-3 mt-5"
                  style={{ textDecoration: 'underline' }}
                  onClick={() => Intercom.show()}
                >
                  Speak To Sales Team
                </button>
              </div>
            }

          </form>

          <LoadingOverlay
            active={isLoading}
            className={isLoading ? "payment-form-loading-overlay" : ""}
            spinner
            text={<p>Loading... <br/>please do not refresh</p>}
          />

        </div>
        <Tour
          startAt={0}
          steps={TOUR_STEPS}
          className={shakeTour ? "tour-container tour-shake" : "tour-container"}
          accentColor={"#333"}
          scrollDuration={300}
          maskClassName={maskClassName}
          isOpen={this.state.isTourOpen}
          onRequestClose={() => this.setState({ isTourOpen: false })}
          onAfterOpen={this.disableBody}
          onBeforeClose={this.enableBody}
          goToStep={tourStep}
          showNumber={tourStep != 0}
          showButtons={false}
          showNavigation={false}
          showCloseButton={false}
          badgeContent={(curr, tot) => <p className="tour-step-badge">{tourStep}</p>}
          getCurrentSep={tourStep => this.setState({ tourStep: tourStep + 1})}
          disableInteraction={true}
          closeWithMask={true}
        />

        <Branding name="Subscription"/>
      </>
    )
  }

}

const step = function(title, text, arrow){
  return (
    <div className={"tour-step " + arrow}>
      <div className="tour-content">
        <h3 className="mb-0 text-center">{ title }</h3>
        <p hidden>
          { text }
        </p>
        <span className="material-icons">{arrow}</span>
      </div>
    </div>
  )
}

const TOUR_STEPS = [
  {
    selector: "#activate-title",
    position: 'left',
    content: step("Activate", "Enter your card details to pay for your package and start processing payments instantly","arrow_right")
  }
]


export default withRouter(SubscriptionForm)
