import React from 'react'

import Modal from 'react-bootstrap/Modal'

import Select from "../../common/CustomSelect";

import General from "../../../../utils/General";
import Backend from "../../../../utils/Backend";

export default class BankDecoupledAuthModal extends React.Component {
  constructor(props){
    super(props)

    this.state = {}

  }

  render(){
    let {
      show,
    } = this.props

    if(!show){
      return null
    }

    return (
      <Modal
        show={show}
        onHide={() => this.props.onCancel()}
        dialogClassName="w-70"
        className={'sub-modal'}
      >
        <Modal.Header closeButton>
          <Modal.Title>Bank Authentication</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p className="my-3">Please authorise the payment with your bank and click continue once complete.</p>
        </Modal.Body>

        <Modal.Footer>

          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => this.onCancel()}
          >
            Cancel
          </button>

          <button
            type="button"
            className={`btn btn-primary`}
            onClick={() => this.props.onContinue()}
          >
            Continue
          </button>
        </Modal.Footer>
      </Modal>
    )
  }
}

BankDecoupledAuthModal.defaultProps = {
  modalClassName: '',
  advanced: true
}
