import React from "react";

import Toggle from "../common/Toggle";

import General from "../../../utils/General";

export default class Questions extends React.Component{

  constructor(props) {
    super(props);

    this.state = this._getState(props)
  }

  componentWillReceiveProps(nextProps) {
    this.setState(this._getState(nextProps))
  }

  _getState(props){
    return {
      questions: this._parse(props.questions)
    }
  }

  _parse(questions){
    for(var i=0; i<questions.length; i++){
      let question = questions[i]
      if(!question.answer){
        question.answer = {
          question: question.id,
        }
        if(question.type === "checkbox" ){
          question.answer.bool = false
        }
        else{
          question.answer.text = null
        }
      }
    }

    return questions
  }

  isValid(scrollToError){
    let {
      questions,
    } = this.state

    let firstError = null
    for(var i=0; i<questions.length; i++){
      let question = questions[i]
      question.error = null
      if(question.type === "text" && !question.answer.value && question.is_required){
        question.error = "This field is required"
        if(!firstError){
          firstError = { question }
        }
      }
      else if(question.type === "checkbox" && question.answer.bool != "yes" && question.is_required){
        question.error = "This field must be checked"
        if(!firstError){
          firstError = { question }
        }
      }
    }

    this.setState({ questions })
    if(firstError){
      if(scrollToError){
        General.scrollTo(`#input-question-${firstError.question.id}`)
      }
      return false
    }

    return true
  }

  render(){
    let {
      questions
    } = this.state

    return questions.map((question, index) => {
      let className = "input-group"
      if(question.error){
        className += " validation-error"
      }

      return (
        <div id={`input-question-${question.id}`} className={"form-group question type-" + question.type }>
          { question.type === "checkbox" &&

            <Toggle
              checked={question.answer.bool === "yes"}
              label={question.text}
              onChange={e => {
                question.error = null
                questions[index] = question
                this.props.onUpdated(questions)
                if(e.target.checked){
                  question.answer.bool = "yes"
                }
                else{
                  question.answer.bool = "no"
                }
              }}
            />

          }
          { question.type === "text" &&
            <div className={className}>

                <input
                  className="form-control h-auto border-0"
                  type="text"
                  placeholder={question.text}
                  defaultValue={question.answer.value || ""}
                  onChange={e => {
                    question.error = null
                    question.answer.value = e.target.value || null
                    questions[index] = question
                    this.props.onUpdated(questions)
                  }}
                />
            </div>
          }
          { question.error &&
            <span className="validation-error-message">{ question.error }</span>
          }
        </div>
      )
    })
  }
}
