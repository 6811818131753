import React from 'react';

import PaymentForm from "./components/payment/Form";
import PaymentAttempt from "./components/payment/PaymentAttempt";

import PageLayout from "./components/PageLayout";
import LoadingPage from "./components/LoadingPage";

import Backend from "../utils/Backend";

export default class PaymentRequest extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      company: null,
      paymentRequest: null
    }
  }

  componentDidMount() {
    let companySlug = this.props.companySlug
    let paymentRequestSlug = this.props.match.params.paymentRequestSlug
    let savedState = PaymentAttempt.getState()

    Backend.getPaymentRequest(companySlug, paymentRequestSlug, savedState?.paymentAttemptId)
    .then(paymentRequest => {
      this.setState({ paymentRequest, savedState })
    })
  }

  render() {
    let {
      savedState,
      paymentRequest
    } = this.state

    if(!paymentRequest){
      return <LoadingPage/>
    }

    let labelText = `Viewed: ${paymentRequest.views_no}`
    if(paymentRequest.paid){
      labelText = "Paid"
    }

    let collectAddress = paymentRequest.collect_address && !paymentRequest.paid

    if(paymentRequest.pay_by_bank){
      paymentRequest.company.payment_methods.card = null
      paymentRequest.company.payment_methods.digital_wallet = null
      paymentRequest.company.payment_methods.klarna = null
    }
    if(paymentRequest.pay_by_interac){
      paymentRequest.company.payment_methods.eft = null
    }

    if(paymentRequest.processor_request_data?.ReferenceNumber){
      let interacUrl = paymentRequest.company.payment_methods.interac.data.interac_link
      paymentRequest.company.payment_methods.interac.link = interacUrl + paymentRequest.processor_request_data.ReferenceNumber
    }

    return (
      <PageLayout
        company={paymentRequest.company}
        showCaption={false}
        labelText={labelText}
      >
        <PaymentForm
          savedState={savedState}
          company={paymentRequest.company}
          customer={paymentRequest.customer}
          questions={paymentRequest.questions}
          description={paymentRequest.description}
          paymentPlan={paymentRequest.payment_plans[0]}
          paymentPlans={paymentRequest.payment_plans}
          paymentAttemptId={paymentRequest.payment_attempt_id}
          dueAt={paymentRequest.due_at}
          file={paymentRequest.file}
          allowPayment={!paymentRequest.paid}
          collectAddress={collectAddress}
        />
      </PageLayout>
    )
  }

}
