import runtimeEnv from '@mars/heroku-js-runtime-env';

const env = runtimeEnv();

export default function(){

    window.General = {};

    window.General.WebsiteUrl           = env.REACT_APP_WEBSITE_URL
    window.General.WebsiteDomain        = env.REACT_APP_WEBSITE_DOMAIN

    window.General.ClientDashboardUrl   = env.REACT_APP_CLIENT_DASHBOARD_URL

    window.General.Branding = {}

    window.General.Branding.Name          = "splink"
    window.General.Branding.LinkName      = "splink"
    window.General.Branding.TermsUrl      = `${window.General.WebsiteUrl}/terms-of-use`
    window.General.Branding.SupportUrl    = null
    window.General.Branding.AddOnsUrl     = `${window.General.WebsiteUrl}/#add-ons`
    window.General.Branding.NotFoundUrl   = `${window.General.WebsiteUrl}/404`
    window.General.Branding.ClientGTMID   = null
    window.General.Branding.PaymentGTMID  = null

    window.General.AssociationId = null

    window.General.Branding.Logo      = require('../assets/media/logos/logo-dark.png')
    window.General.Branding.Banner    = require('../assets/media/logos/logo-light.png')
    window.General.Branding.Favicon   = '/favicon.png'
    window.General.Branding.Domain    = window.General.WebsiteDomain
    window.General.Branding.Colors    = {
      "primary": "#3699ff",
      "text_on_primary": "#fff",
      "secondary": "#3699ff",
      "take": "#3699ff",
      "request": "#f64e60",
      "shop": "#1bc5bd",
      "recurring": "#3699ff",
    }

}
