import React from 'react';

const Card = ({ className }) => {
    return (
        <svg className={className} width="49" height="43" viewBox="0 0 49 43" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M48.25 19.125V40.5C48.25 41.1299 47.9998 41.734 47.5544 42.1794C47.109 42.6248 46.5049 42.875 45.875 42.875H3.125C2.49511 42.875 1.89102 42.6248 1.44562 42.1794C1.00022 41.734 0.75 41.1299 0.75 40.5V19.125H48.25ZM48.25 9.625H0.75V2.5C0.75 1.87011 1.00022 1.26602 1.44562 0.820621C1.89102 0.375222 2.49511 0.125 3.125 0.125H45.875C46.5049 0.125 47.109 0.375222 47.5544 0.820621C47.9998 1.26602 48.25 1.87011 48.25 2.5V9.625Z" fill="#333333" />
        </svg>
    )
}

export default Card;