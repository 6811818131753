import React from "react";
import TextareaAutosize from 'react-autosize-textarea';

import General from "../../../utils/General";

import ReactReadMoreReadLess from "react-read-more-read-less";

export default class Description extends React.Component{

  constructor(props) {
    super(props);

    this.state = this._getState(props)
  }

  componentWillReceiveProps(nextProps) {
    this.setState(this._getState(nextProps))
  }

  _getState(props){
    return {
      text: props.text,
      collect: props.collect,
      customer: props.customer,
      errors: this.state?.error || null
    }
  }

  isValid(scrollToError){
    let {
      collect,
      customer,
    } = this.state

    let error = null
    if(collect && !customer.note){
      error = "Please enter a description"
    }

    this.setState({ error })
    if(error){
      if(scrollToError){
        General.scrollTo(`#input-note`)
      }
      return false
    }

    return true
  }

  _renderCustomerRef(customer){
    if(!customer.reference){
      return null
    }

    return (
      <div className="form-group row c-description mb-4">
        <div className="col-4 col-sm-3 text-left">
          <label><strong>Customer: </strong></label>
        </div>
        <div className="col-8 col-sm-9">
          <p className="form-control-plaintext text-dark py-0 pr-10">
            { customer.reference }
          </p>
        </div>
      </div>
    )
  }

  _renderCustomerData(customer){
    if(!customer.data){
      return null
    }

    let content = []
    for (var key in customer.data) {
      if (customer.data.hasOwnProperty(key)) {
        content.push(
          <div className="form-group row c-description mb-4">
            <div className="col-4 col-sm-3 text-left">
              <label><strong>{ key }: </strong></label>
            </div>
            <div className="col-8 col-sm-9">
              <p className="form-control-plaintext text-dark py-0 pr-10">
                { customer.data[key] }
              </p>
            </div>
          </div>
        )
      }
    }

    return content
  }

  _renderText(text){
    if(!text){
      return null
    }

    return (
      <div className="form-group row c-description mb-4">
				<div className="col-4 col-sm-3 text-left">
					<label><strong>Description: </strong></label>
				</div>
				<div className="col-8 col-sm-9">
					<p className="form-control-plaintext text-dark py-0 pr-10 pre">
            <ReactReadMoreReadLess
                charLimit={200}
                readMoreText={"Read more"}
                readLessText={"Read less"}
                readMoreStyle={{ color: this.props.primaryColor }}
                readLessStyle={{ color: this.props.primaryColor }}
            >
                {text}
            </ReactReadMoreReadLess>
				  </p>
        </div>
			</div>
    )
  }

  render() {
    let {
      text,
      collect,
      customer,
      error
    } = this.state

    let noteClassName = "input-group"
    if(error){
      noteClassName += " validation-error"
    }

    return (
      <>
        { collect &&
          <div className="form-group mb-2 mt-2">
            <div className={noteClassName}>
              <TextareaAutosize
                className="form-control border-0"
                id="input-note"
                placeholder="Description"
                defaultValue={customer.note}
                onChange={e => {
                  customer.note = e.target.value
                  this.setState({ error: null }, () => {
                    this.props.onUpdated(customer)
                  })
                }}
              />
            </div>
            { error &&
              <span className="validation-error-message">{ error }</span>
            }
          </div>
        }
        { this._renderText(text) }
        { this._renderCustomerRef(customer) }
        { this._renderCustomerData(customer) }
      </>
    )
  }
}
