import React from 'react';

import moment from 'moment'

import LoadingOverlay from 'react-loading-overlay';

import ConfirmModal from "./components/modal/ConfirmModal"
import SuccessModal from "./components/modal/SuccessModal"
import PaymentInstrumentModal from "./components/modal/PaymentInstrumentModal"

import PaymentForm from "./components/payment/Form";

import PageLayout from "./components/PageLayout";
import LoadingPage from "./components/LoadingPage";

import Separator from "./components/common/Separator"

import LazyLoader from "./components/common/LazyLoader";

import Backend from "../utils/Backend";
import Currency from "../utils/Currency";
import General from "../utils/General";
import Notify from "../utils/Notify";
import AuthManager from "../utils/AuthManager";

export default class Subscriptionss extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      company: null,
      isInitialLoading: true,
      invoices: [],
      hasMore: false,
      loadingInvoices: true,
      isPayingInvoice: false
    }

    this.lazyLoader = React.createRef()
    this.paymentInstrumentModal = React.createRef()
  }

  componentDidMount() {
    let companySlug = this.props.companySlug
    let initialPaymentId = this.props.match.params.initialPaymentId
    let authToken = this.props.match.params.authToken

    Backend.getSubscription(companySlug, initialPaymentId, authToken)
    .then(subscription => {

      this.setState({
        authToken,
        subscription,
        company: subscription.initial_payment.company
      }, () => {
        let savedState = localStorage.getItem("payment_instrument_state")
        if(savedState){
          localStorage.removeItem("payment_instrument_state")
          savedState = JSON.parse(savedState)
          this.setState({ showPaymentInstrumentModal: true }, () => {
            this.paymentInstrumentModal.current.resume(savedState)
          })
        }
      })


    }).catch(error => {
      // suscription may bbe deleted so lets try open splink
      this.props.history.push("/")
    })
  }

  _handleCancelSubscription(){
    let {
      loading,
    } = this.state

    if(loading){
      return
    }

    this.setState({ showCancelSubscriptionModal: true })
  }

  _cancelSubscription(){
    let {
      loading,
      authToken,
      subscription
    } = this.state

    if(loading){
      return
    }

    this.setState({ loading: true })
    Backend.cancelSubscription(subscription, authToken)
    .then(() => {
      subscription.status = "cancelled"
      this.setState({
        subscription,
        loading: false,
        showCancelSubscriptionModal: false,
        showSubscriptionCancelledModal: true,
      })
    }).catch(error => {
      this.setState({
        loading: false,
        showCancelSubscriptionModal: false
      })
    })
  }

  _payInvoice(invoice){
    let {
      authToken,
    } = this.state

    this.setState({ isPayingInvoice: true })
    Backend.payInvoice(invoice, authToken)
    .then(response => {
      this.setState({ isPayingInvoice: false })
      this.lazyLoader.current._refresh()
      Notify.success("Successfully paid invoice")
    })
    .catch(error => {
      this.setState({ isPayingInvoice: false })
      Notify.error(error.message)
    })

  }

  _renderSections(){
    return(
      <div className='s-invoices text-dark'>
        { this._sectionPaymentInstrument() }
        { this._renderInvoices() }
      </div>
    )
  }

  _sectionPaymentInstrument(){
    let { subscription } = this.state
    let { payment_instrument } = subscription
    if(!payment_instrument){
      return null
    }
    let { customer_data } = payment_instrument
    let type = payment_instrument.payment_method.type
    type = General.snakeCaseToTitleCase(type)

    if(type == "Digital Wallet"){
      type = "Google/Apple Pay"
    }

    if(type == "Ach"){
      type = "ACH"
    }

    let id = payment_instrument.identifier
    if(id){
      id = `**** ${id}`
    }

    let showChangePaymentMethod = (subscription.status == "active" || subscription.status == "paused")
    if(payment_instrument.payment_method.processor.indexOf("boipa") > -1){
      showChangePaymentMethod = false
    }

    return(
      <div className='payment-method'>
        <h3 class="card-title">Payment Method</h3>
        <div className='s-box'>
          <div className='row'>
            <div className='col'>
              <div>{ (customer_data.first_name || "") + " " + (customer_data.last_name || "")}</div>
              <div>{ customer_data.email }</div>
            </div>
            <div className='col text-right'>
              <div>{ type }</div>
              <div>{ id }</div>
            </div>
          </div>
          <button
            className='btn btn-sm btn-primary-outline btn-block mt-4'
            onClick={() => this.setState({ showPaymentInstrumentModal: true })}
          >
            Change Payment Method
          </button>
        </div>
      </div>
    )
  }

  _renderInvoices(){
    let initialPaymentId = this.props.match.params.initialPaymentId
    let authToken = this.props.match.params.authToken

    let { invoices, isInitialLoading, hasMore, loadingInvoices, isPayingInvoice } = this.state

    return(
      <div className='items'>
        <h3 class="card-title">Invoices</h3>
          <LazyLoader
            ref={this.lazyLoader}
            endpoint={`${window.Api.Invoices}?subscription_id=${initialPaymentId}&subscription_auth_token=${authToken}&page_size=5&order_by=-created_at`}
            headers={AuthManager.getHeaders()}
            onEndReached="loadMore"
            onItemsUpdated={invoices => {
              this.setState({
                invoices,
                loadingInvoices: false,
                isInitialLoading: false,
                hasMore: invoices.length % 5 == 0,
              })
            }}
            onRefresh={invoices => {
              this.setState({
                invoices: [],
                isInitialLoading: true
              })
            }}
          >
            <>
              { invoices.map((invoice, index) => {
                  let refundedAt = invoice.payment?.refunded_at
                  let paidAt = invoice.payment?.created_at

                  let status = "Unpaid"
                  let statusCss = "label-light-danger"

                  if(refundedAt){
                    status = "Refunded"
                    statusCss = "label-light-warning text-dark"
                  }
                  else if(paidAt){
                    status = "Paid"
                    statusCss = "label-light-success"
                  }

                  return (
                    <div className='s-box item'>
                      <div className='row align-items-center'>

                        <div className='col-8'>

                          <div>Issued: <h4 className='mb-0'>{moment(invoice.created_at).format("Do MMM YYYY")}</h4></div>
                          {paidAt &&
                            <div className="mt-1">
                              Paid: {moment(paidAt).format("Do MMM YYYY")}
                            </div>
                          }
                          { refundedAt &&
                            <div className="mt-1">
                              Refunded: {moment(refundedAt).format("Do MMM YYYY")}
                            </div>
                          }

                        </div>

                        <div className='col text-right'>

                          <div>
                            <span
                              className={`label label-pill label-inline skip font-weight-bolder my-1 ml-auto ${statusCss}`}
                            >
                              {status}
                            </span>
                          </div>

                          <div className='d-inline-flex flex-row align-items-center mt-2'>
                            { !paidAt &&
                              <button
                                className='btn btn-sm btn-primary-outline mr-1'
                                disabled={isPayingInvoice}
                                onClick={() => this._payInvoice(invoice)}
                              >
                                Pay
                              </button>
                            }
                            { invoice.payment?.file &&
                              <button
                                className='btn btn-sm btn-primary-outline'
                                onClick={() => window.open(invoice.payment.file.url, '_blank')}
                              >
                                View
                              </button>
                            }
                          </div>

                        </div>
                      </div>
                    </div>
                  )
                })
              }
            </>
          </LazyLoader>
          <div className='load-more text-center'>
          { (!loadingInvoices && hasMore) &&
            <a
              href='javascript:void(0)'
              className='link-primary'
              onClick={() => {
                this.setState({ loadingInvoices: true })
                if(this.lazyLoader.current){
                  this.lazyLoader.current._loadMore()
                }
              }}
            >
                Load More Invoices
            </a>
          }
          {loadingInvoices &&
            <>
              <div className='s-box item shimmer'>
                <div className='row align-items-center'>
                  <div className='col-8'>
                    <div>&nbsp; <h4 className='mb-0'>&nbsp;</h4></div>
                  </div>
                  <div className='col text-right'>
                    <div>&nbsp;</div>
                    <div className='mt-2'>&nbsp;</div>
                  </div>
                </div>
              </div>
            </>
          }
          </div>
      </div>
    )
  }

  render() {
    let {
      loading,
      company,
      authToken,
      subscription,
      isPayingInvoice,
      showPaymentInstrumentModal,
      showCancelSubscriptionModal,
      showSubscriptionCancelledModal,
    } = this.state

    if(!company){
      return <LoadingPage/>
    }

    let initialPayment = subscription.initial_payment

    let customer = initialPayment.customer

    let paymentPlan = initialPayment.payment_plan

    let paymentInstrumentModalCSS = "layout-" + company.settings.layout;
    if(company.settings.layout==="modern"){
      paymentInstrumentModalCSS += " text-white"
    }

    let statusCss = "active" ? "success" : "danger"
    if(subscription.status == "paused"){
      statusCss = "warning"
    }

    return (
      <PageLayout
        company={company}
        containerClassName="page subscriptions"
      >

        <div className="subscription">

          <h3 className="card-title">Subscription</h3>

          { customer.reference &&
            <div className="row mb-2">
              <div className="col-4 col-form-label font-weight-bolder">Customer :</div>
              <div className="col-8 col-form-label">{ customer.reference }</div>
            </div>
          }

          { customer.first_name &&
            <div className="row mb-2">
              <div className="col-4 col-form-label font-weight-bolder">Cardholder :</div>
              <div className="col-8 col-form-label">{customer.first_name} {customer.last_name}</div>
            </div>
          }

          <div className="row mb-2">
            <div className="col-4 col-form-label font-weight-bolder">Price :</div>
            <div className="col-8 col-form-label">{ Currency.format(paymentPlan.currency.symbol, paymentPlan.total) }</div>
          </div>

          { initialPayment.description &&
            <div className="row mb-2">
              <div className="col-4 col-form-label font-weight-bolder">Description :</div>
              <div className="col-8 col-form-label">{ initialPayment.description }</div>
            </div>
          }

          <div className="row mb-2">
            <div className="col-4 col-form-label font-weight-bolder">Billing Day :</div>
            <div className="col-8 col-form-label">{ subscription.invoice_day }</div>
          </div>

          <div className="row mb-2">
            <div className="col-4 col-form-label font-weight-bolder">Billing Cycle :</div>
            <div className="col-8 col-form-label">{ General.toTitleCase(paymentPlan.frequency) }</div>
          </div>

          <div className="row mb-2">
            <div className="col-4 col-form-label font-weight-bolder">No. Of Installments :</div>
            <div className="col-8 col-form-label">{ paymentPlan.installments || "Continuous"}</div>
          </div>

          <div className="row mb-2">
            <div className="col-4 col-form-label font-weight-bolder">Status :</div>
            <div className={`col-8 col-form-label text-${statusCss}`}>
              { General.toTitleCase(subscription.status) }
            </div>
          </div>

          <div className="row">
            <div className="col-4 col-form-label font-weight-bolder">Date Created :</div>
            <div className="col-8 col-form-label">{ moment(subscription.created_at).format("Do MMM YYYY")}</div>
          </div>


          { this._renderSections() }

          { subscription.status === "active" &&
            <div className="row mt-10">
              <div className="col-12">
                <button
                  type="button"
                  className="btn btn-outline-danger btn-lg btn-block"
                  onClick={() => this._handleCancelSubscription()}
                >
                  <i className="fa fa-times-circle"></i>
                  Cancel Subscription
                </button>
              </div>
            </div>
          }

          <ConfirmModal
            show={showCancelSubscriptionModal}
            loading={loading}
            buttonClassName="danger"
            message="Cancelling your subscription cannot be undone"
            cancelButtonText="No"
            confirmButtonText="Cancel Subscription"
            onCancel={() => this.setState({ showCancelSubscriptionModal: false})}
            onConfirm={() => this._cancelSubscription()}
          />

          <SuccessModal
            show={showSubscriptionCancelledModal}
            message="Your subscription has been cancelled"
            buttonTitle="Ok"
            onButtonClicked={() => this.setState({ showSubscriptionCancelledModal: false })}
          />

          <LoadingOverlay
              active={isPayingInvoice}
              className={isPayingInvoice ? "payment-form-loading-overlay" : ""}
              spinner
              text={<p>Loading... <br/> please do not refresh</p>}
          />

          <PaymentInstrumentModal
            ref={this.paymentInstrumentModal}
            show={showPaymentInstrumentModal}
            subscription={subscription}
            authToken={authToken}
            onCancel={() => this.setState({ showPaymentInstrumentModal: false})}
            onUpdated={subscription => {
              this.setState({ subscription, showPaymentInstrumentModal: false })
            }}
            modalClassName={ paymentInstrumentModalCSS }
          />


        </div>

      </PageLayout>
    )
  }

}
