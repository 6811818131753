import React from 'react'

import paymentRequest from 'react-payment-request-api';

import {
  isMobile,
  isIOS,
  isSafari,
  isChrome,
  isAndroid,
  isMacOS
} from 'react-device-detect';

import GooglePayButton from "./GooglePayButton";
import ApplePayButton from "./ApplePayButton";

const Button = ({ show, isSupported }) => {
  if(!isSupported){
    return null
  }

  if(isSafari){
    return (
      <ApplePayButton
        onClick={show}
      />
    )
  }

  return (
    <GooglePayButton
      onClick={show}
    />
  )
}

export default paymentRequest()(Button);
