import React from 'react'

import { PaymentRequestButtonElement } from '@stripe/react-stripe-js';

import DigitalWalletButton from "./DigitalWalletButton"

export default class DigitalWallet extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      loading: false,
      paymentPlan: props.paymentPlan,
      paymentMethod: props.paymentMethod
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  render(){
    let {
      paymentPlan
    } = this.state

    return (
       <DigitalWalletButton
        stripe={this.props.stripe}
        paymentPlan={paymentPlan}
        onPayPressed={(paymentDetails, billingDetails, address) => this.props.onPayPressed(paymentDetails, billingDetails, address)}
        renderFooter={() => this.props.renderFooter()}
        renderHeader={() => this.props.renderHeader()}
      />
    )
  }
}

DigitalWallet.defaultProps = {
  renderFooter: () => null,
  renderHeader: () => null
}
