import React from 'react'

import { CardElement } from '@stripe/react-stripe-js';

let CARD_ELEMENT_STYLE = {
  base: {
    iconColor: '#666666',
    color: '#fff',
    fontSize: '16px',
    '::placeholder': {
      color: '#87BBFD',
    },
  },
  invalid: {
    iconColor: '#FFC7EE',
    color: '#FFC7EE',
  },
}

export default class Card extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      loading: false,
      company: props.company,
      customer: props.customer,
      paymentMethod: props.paymentMethod
    }
  }

  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  collectDetails(){
    let {
      paymentMethod
    } = this.state
    return this._getPaymentMethod()
    .then(stripePaymentMethod => {
      let paymentDetails = {
        payment_method_id: stripePaymentMethod.id
      }
      return {paymentMethod, paymentDetails}
    })
  }

  async _getPaymentMethod(){
    const customer = this.props.customer

    if(!customer.first_name){
      throw { message: "Please specify the cardholder name" }
    }

    const cardElement = this.props.elements.getElement(CardElement)


    let billing_details =  {
      name: customer.first_name + " " + customer.last_name,
      email: customer.email
    }

    if(this.props.collectAddress){
      billing_details.address = {
        line1: customer.shipping_address.line_1,
        line2: customer.shipping_address.line_2,
        city: customer.shipping_address.city,
        state: customer.shipping_address.state,
        country: customer.shipping_address.country_short,
      }
    }


    // todo add address in collect address enabled
    return this.props.stripe.createPaymentMethod({
      type: 'card',
      billing_details,
      card: cardElement,
    })
    .then(({paymentMethod, error}) => {
      if(paymentMethod == null){
        throw { message: "Please check your card details and try again" }
      }
      return paymentMethod
    });
  }

  render(){
    let style = {
      base: {
        marginTop: '20px',
        fontSize:'13px',
        color: '#fff',
        fontFamily:'Poppins, Helvetica, "sans-serif"',
        '::placeholder': {
          color: '#B5B5C3',
          textTransform: 'capitalize',
          fontSize:'13px',
          fontFamily:'Poppins, Helvetica, "sans-serif"'
        },
        ':-webkit-autofill': {
          color: '#fff',
        },
      },
      invalid: {
        color: '#fa6160',
      }
    }

    let layout = this.state.company.settings.layout

    if(layout === "classic"){
      style.base.color='#464E5F';
      style.base["::placeholder"].color='#B5B5C3';
      style.base[":-webkit-autofill"].color='#464E5F';
    }

    if(layout === "retro" || layout === "dashboard"){
      style.base.fontSize='12px';
      style.base.color='#464E5F';
      style.base["::placeholder"].fontSize='12px';
      style.base[":-webkit-autofill"].color='#464E5F';
    }

    if(window.ReactNativeWebView){
      style.base.fontSize='16px';
      style.base.color='#000000';
      style.base.fontFamily='-apple-system, BlinkMacSystemFont, Helvetica, "sans-serif"';
      style.base["::placeholder"].fontSize='16px';
      style.base[":-webkit-autofill"].color='#767779';
    }

    return (
      <>

        <div 
          className={`form-group react-stripe mb-2 text-left format-${layout}`}
        >

          <div className="input-group with-append">

            <CardElement
              stripe={this.props.stripe}
              options={{
                style,
                hideIcon:true,
                hidePostalCode: !this.props.collectAddress,
              }}
              className="form-control h-auto border-0"
            />

            <div className="input-group-append input-group-pill">
                <span className="input-group-text input-group-payment-form">
                  <span className="svg-icon svg-icon-white svg-icon-2x">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
										    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
										        <mask fill="white">
										            <use xlinkHref="#path-1"/>
										        </mask>
										        <g/>
										        <path d="M7,10 L7,8 C7,5.23857625 9.23857625,3 12,3 C14.7614237,3 17,5.23857625 17,8 L17,10 L18,10 C19.1045695,10 20,10.8954305 20,12 L20,18 C20,19.1045695 19.1045695,20 18,20 L6,20 C4.8954305,20 4,19.1045695 4,18 L4,12 C4,10.8954305 4.8954305,10 6,10 L7,10 Z M12,5 C10.3431458,5 9,6.34314575 9,8 L9,10 L15,10 L15,8 C15,6.34314575 13.6568542,5 12,5 Z" fill="#000000"/>
										    </g>
                    </svg>
                  </span>
                </span>
              </div>

          </div>

        </div>
      </>
    )
  }
}

Card.defaultProps = {
}
