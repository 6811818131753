import React from "react";

export default class Separator extends React.Component{
  render() {

    if(window.ReactNativeWebView){
      return null
    }

    return (
      <div className="c-separator form-group mt-4 mb-4 row align-items-center text-center">
    		<div className="col-2 col-sm-3">
          {this.props.hr &&
      			<hr/>
          }
    		</div>
    		<div className="col-8 col-sm-6">
    			<span><strong>{this.props.text}</strong></span>
    		</div>
    		<div className="col-2 col-sm-3">
          {this.props.hr &&
            <hr/>
          }
    		</div>
	    </div>
    )
  }
}

Separator.defaultProps = {
  hr: true,
}
