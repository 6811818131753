
export default class PaymentMethods {
  static getDefault(paymentMethods){
    if(paymentMethods.card){
      return paymentMethods.card
    }
    else if(paymentMethods.digital_wallet){
      return paymentMethods.digital_wallet
    }
    else if(paymentMethods.sepa){
      return paymentMethods.sepa
    }
    else if(paymentMethods.apple_pay){
      return paymentMethods.apple_pay
    }
    else if(paymentMethods.google_pay){
      return paymentMethods.google_pay
    }
    else if(paymentMethods.ach){
      return paymentMethods.ach
    }
    else if(paymentMethods.eft){
      return paymentMethods.eft
    }
    else if(paymentMethods.interac){
      return paymentMethods.interac
    }
    else if(paymentMethods.sepa_instant){
      return paymentMethods.sepa_instant
    }
    else if(paymentMethods.faster_payments){
      return paymentMethods.faster_payments
    }
    else if(paymentMethods.bacs){
      return paymentMethods.bacs
    }
    return null
  }

  static getProcessor(paymentMethods){
    let paymentMethod = PaymentMethods.getDefault(paymentMethods)

    if(paymentMethod){
      return paymentMethod.processor
    }

    return null
  }

  static getByProcessor(paymentMethods){

    let methods = {}
    Object.keys(paymentMethods).forEach((key, i) => {
      let paymentMethod = paymentMethods[key]
      if(!paymentMethod){
        return
      }
      if(!methods[paymentMethod.processor]){
        methods[paymentMethod.processor] = {}
      }
      methods[paymentMethod.processor][paymentMethod.type] = paymentMethod
    });

    return methods
  }

  static supportsACH(company){
    return company.payment_methods.ach != null
  }

  static supportsNonCard(paymentMethods){
    return Object.keys(paymentMethods).find((key, i) => {

      return key != "card" && paymentMethods[key] != null
    }) != null
  }

  static supportsInterac(company){
    return company.payment_methods.interac != null
  }
}
