import React from 'react';

export default class NotFound extends React.Component {

  render() {
    // TODO: render not found with link to website
    return "Not Found"
  }

}
