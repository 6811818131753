import React from 'react'

export default class CardPayButton extends React.Component {
  render(){
    return (
      <div className="text-center">
        <div
          onClick={() => this.props.onClick()}
          className="card-pay-button pay-buttons bg-icon"
        >
          &nbsp;
        </div>
        <p className="payment-method-title">Credit/Debit Card</p>
      </div>
    )
  }
}

CardPayButton.defaultProps = {
}
