import React from 'react';

import PaymentForm from "./components/payment/Form";
import PaymentAttempt from "./components/payment/PaymentAttempt";

import PageLayout from "./components/PageLayout";
import LoadingPage from "./components/LoadingPage";

import Backend from "../utils/Backend";

export default class PaymentLink extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      company: null,
      paymentLink: null
    }
  }

  componentDidMount() {
    let companySlug = this.props.companySlug
    let paymentLinkSlug = this.props.match.params.paymentLinkSlug
    let savedState = PaymentAttempt.getState()

    Backend.getPaymentLink(companySlug, paymentLinkSlug, savedState?.paymentAttemptId)
    .then(paymentLink => {
      this.setState({ paymentLink, savedState })
    }).catch(error => {
      // payment link may bbe deleted so lets try open splink
      this.props.history.push(`/`)
    })
  }

  render() {
    let {
      savedState,
      paymentLink
    } = this.state

    if(!paymentLink){
      return <LoadingPage/>
    }

    return (
      <PageLayout
        company={paymentLink.company}
        title={paymentLink.title}
        showCaption={false}
        backgroundImage={paymentLink.background_image?.original || null}
      >
        <PaymentForm
          company={paymentLink.company}
          savedState={savedState}
          paymentPlan={paymentLink.payment_plans[0]}
          paymentPlans={paymentLink.payment_plans}
          paymentAttemptId={paymentLink.payment_attempt_id}
          questions={paymentLink.questions}
          description={paymentLink.description}
          collectAddress={paymentLink.collect_address}
          collectEmail={paymentLink.company.settings.collect_email}
        />
      </PageLayout>
    )
  }

}
