import React from 'react'

import Modal from 'react-bootstrap/Modal'

import Select from "../../common/CustomSelect";

import General from "../../../../utils/General";
import Backend from "../../../../utils/Backend";

export default class BankAuthModal extends React.Component {
  constructor(props){
    super(props)

    this.state = this._getState(props)

  }

  componentWillReceiveProps(nextProps){
    this.setState(this._getState(nextProps))
  }

  _getState(props){
    return {
      show: props.show,
      data: {},
      description: props.description,
      fields: props.fields.map(field => {
        return {
          ...field,
          answered: false
        }
      })
    }
  }


  render(){
    let {
      show,
      fields,
      data,
      description
    } = this.state

    if(!show){
      return null
    }

    return (
      <Modal
        show={show}
        onHide={() => this.props.onCancel()}
        dialogClassName="w-70"
        className={'sub-modal'}
      >
        <Modal.Header closeButton>
          <Modal.Title>Bank Authentication</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          { description &&
            <p className="my-3"> { description } </p>
          }
          {
            fields.map(field => {
              let input = null
              if(field.type == "CHOICE_FIELD"){
                let options = field.options.map(option => {
                  return {
                    label: option,
                    value: option
                  }
                })

                input = (
                  <Select
                    className="async-select-paginate"
                    classNamePrefix="async-select-paginate"
                    placeholder="Please Select An Option"
                    onChange={option => {
                      data[field.id] = option.value
                      field.answered = true
                      this.setState({ data, fields })
                    }}
                    options={options}
                    menuPortalTarget={null}
                  />
                )
              }else{
                input = (
                  <input
                    className="form-control form-control-solid"
                    type={(field.type || "text").toLowerCase()}
                    onChange={(e) => {
                      data[field.id] = e.target.value || null
                      field.answered = data[field.id] != null
                      this.setState({ data, fields })
                    }}
                  />
                )
              }
              return (
                <div className="form-group row">
                  <label className="col-3 col-form-label">
                    {General.toTitleCase(field.displayName)}
                  </label>
                  <div className="col-9">
                    { input }
                  </div>
                </div>
              )
            })
          }
        </Modal.Body>

        <Modal.Footer>

          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => this.onCancel()}
          >
            Cancel
          </button>

          <button
            type="button"
            className={`btn btn-primary`}
            disabled={!(fields.find(field => !field.answered) == null)}
            onClick={() => this.props.onContinue(data)}
          >
            Continue
          </button>
        </Modal.Footer>
      </Modal>
    )
  }
}

BankAuthModal.defaultProps = {
  modalClassName: '',
  advanced: true
}
