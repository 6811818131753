import React from "react";

import DigitalWalletButton from "./DigitalWalletButton"

import Backend from "../../../../utils/Backend"
import Currency from "../../../../utils/Currency";
import Name from "../../../../utils/Name";
import Notify from "../../../../utils/Notify";


export default class DigitalWalletButtons extends React.Component{

  componentDidMount(){
    setTimeout(() => this.setState({ canRender: true }), 300)
  }

  _getDigitalWalletConfig(){
    let {
      company,
      paymentPlan,
      googleMerchantId,
      appleMerchantId
    } = this.props

    return {
      onShowSuccess: this.props.onShowSuccess,
      /* tslint:disable-next-line:no-console */
      onShowFail: this.props.onShowFail,
      methodData: [
        {
          supportedMethods: ["https://pay.google.com/about"],
          data: {
            environment: company.settings.live ? window.Api.GooglePayLiveEnv : 'TEST',
            apiVersion: 2,
            apiVersionMinor: 0,
            allowedPaymentMethods: [
              {
                type: 'CARD',
                parameters: {
                  allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
                  allowedCardNetworks: ["AMEX", "DISCOVER", "INTERAC", "JCB", "MASTERCARD", "MIR", "VISA"],
                },
                tokenizationSpecification: {
                  type: 'PAYMENT_GATEWAY',
                  parameters: {
                    gateway: 'cardconnect',
                    gatewayMerchantId: googleMerchantId,
                  },
                },
              },
            ],
            merchantInfo: {
              merchantId: `${company.id}`,
              merchantName: company.name,
            },
            transactionInfo: {
              totalPriceStatus: 'FINAL',
              totalPrice: `${paymentPlan.total / 100.0}`,
              currencyCode: paymentPlan.currency.code.toUpperCase(),
            },
            emailRequired: true,
          },
        },
        {
          supportedMethods: 'https://apple.com/apple-pay',
          data: {
              version: 3,
              merchantIdentifier: appleMerchantId,
              merchantCapabilities: ['supportsDebit'],
              supportedNetworks: ['masterCard', 'visa'],
              countryCode: 'US',
          }
        }
      ],
      details: {
        displayItems: [
          {
            label: 'Due',
            amount: {
              currency: paymentPlan.currency.code.toUpperCase(),
              value: `${paymentPlan.total / 100.0}`
            }
          }
        ],
        total: {
          label: 'Total due',
          amount: {
            currency: paymentPlan.currency.code.toUpperCase(),
            value : `${paymentPlan.total / 100.0}`
          },
        }
      },
      options: {
        requestPayerEmail: true,
        requestPayerName: true
      },
      onMerchantValidation: (event) => {
        event.complete(Promise.resolve(event.validationURL));
      },
    }
  }

  render() {
    return (
      <DigitalWalletButton
        config={this._getDigitalWalletConfig()}
      />
    )
  }

}

DigitalWalletButtons.defaultProps = {
  onShowFail: () => {},
  onShowSuccess: () => {}
}
