import React from 'react';

const Bank = ({ className }) => {
    return (
        <svg className={className} width="51" height="48" viewBox="0 0 51 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.916626 15.6667V25.5025H3.37496V40.25H0.916626V47.625H40.25L47.625 47.6275V47.625H50.0833V40.25H47.625V25.5025H50.0833V15.6667L25.5 0.916687L0.916626 15.6667ZM10.75 40.25V25.5025H15.6666V40.25H10.75ZM23.0416 40.25V25.5025H27.9583V40.25H23.0416ZM40.25 40.25H35.3333V25.5025H40.25V40.25ZM30.4166 15.6667C30.4165 16.3125 30.2891 16.952 30.0418 17.5486C29.7945 18.1452 29.4321 18.6873 28.9753 19.1438C28.5186 19.6004 27.9763 19.9625 27.3796 20.2095C26.7829 20.4565 26.1433 20.5835 25.4975 20.5834C24.8517 20.5832 24.2122 20.4558 23.6156 20.2085C23.019 19.9612 22.4769 19.5988 22.0204 19.1421C21.5638 18.6853 21.2017 18.143 20.9547 17.5463C20.7077 16.9496 20.5807 16.3101 20.5808 15.6642C20.5812 14.3599 21.0996 13.1092 22.0221 12.1871C22.9446 11.2651 24.1957 10.7472 25.5 10.7476C26.8043 10.7479 28.055 11.2663 28.9771 12.1888C29.8991 13.1114 30.417 14.3624 30.4166 15.6667Z" fill="#333333" />
        </svg>
    )
}

export default Bank;