import React from "react";


import LocationInput from "../common/LocationInput";

import General from "../../../utils/General";

export default class Address extends React.Component{

  constructor(props) {
    super(props);

    this.location = React.createRef()

    this.state = this._getState(props)
  }

  componentWillReceiveProps(nextProps) {
    this.setState(this._getState(nextProps))
  }

  _getState(props){

    let customer = props.customer


    return {
      customer,
      type: props.type,
      errors: this.state?.errors || []
    }
  }

  isValid(scrollToError){
    let {
      customer,
    } = this.state

    return this.location.current.isValid(scrollToError)
  }


  render(){
    let {
      name,
      type,
      errors,
      customer
    } = this.state


    let placeholder = window.ReactNativeWebView ? "Address" : "Type your address"
    if(type === "billing_address"){
      placeholder = "Billing Address"
    }

    return (
      <>
        <LocationInput
          ref={this.location}
          className="form-control h-auto border-0"
          allowManualInput={true}
          location={customer[type]}
          placeholder={placeholder}
          requirePostalCode={this.props.requirePostalCode}
          onUpdated={address => {
            customer[type] = address
            this.props.onUpdated(customer)
          }}
        />
      </>
    )
  }

}

Address.defaultProps = {
  type: "shipping_address",
  requirePostalCode: false,
}
