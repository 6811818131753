import React from 'react'

import { Elements, ElementsConsumer, PaymentElement } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import Modal from 'react-bootstrap/Modal'

import Select from "../../common/CustomSelect";
import Customer from "../Customer";

import General from "../../../../utils/General";
import Backend from "../../../../utils/Backend";

let stripePromise = null


export default class BACSAuthModal extends React.Component {
  constructor(props){
    super(props)

    this.state = {
      show: true,
      company: props.company,
      paymentMethod: props.paymentMethod
    }

    this.bacs = React.createRef()

    let platformPublicKey = props.paymentMethod.data.platform_public_key
    stripePromise = loadStripe(
      platformPublicKey,
      { stripeAccount: props.paymentMethod.data.account_id }
    )
  }

  async handleAction(response){

    this.setState({
      show: true,
      paymentIntentId: response.payment_intent_id,
      clientSecret: response.client_secret
    })

    return new Promise((resolve, reject) => {

      this.successResponse = resolve
      this.errorResponse = reject
    })
  }

  _onPayPressed(){
    let { paymentIntentId } = this.state
    this.props.onSaveState({
      payment_intent_id: paymentIntentId
    })

    let url = new URL(window.location.href);
    let params = new URLSearchParams(url.search);
    url.searchParams.set("bacs", "true")

    let returnUrl = url.toString()

    this.setState({ loading: true })
    this.stripe.confirmPayment({
      elements: this.elements,
      confirmParams: {
        return_url: returnUrl,
      },
      redirect: "if_required"
    })
    .then(response => {
      this.successResponse(response)
      this.setState({ show: false, loading: false })
    })
    .catch(error => {
      this.setState({ loading: false })
    })
  }

  _onCancel(){
    this.setState({
      show: false,
      paymentIntentId: null,
      clientSecret: null
    })
    this.errorResponse({
      error: "Payment Cancelled",
      message: "We were unable to verify your payment, please try again or use a different payment method"
    })
  }

  render(){
    let {
      show,
      company,
      customer,
      loading,
      clientSecret,
      paymentMethod
    } = this.state

    if(!show || !clientSecret){
      return null
    }

    let className = "form payment-form sepa-modal"
    if(company.settings.layout==="modern"){
      className += " text-white"
    }

    const fonts = [{ cssSrc: "https://fonts.googleapis.com/css2?family=Poppins&display=swap" }]

    const options = {
      clientSecret,
      pament_methods: ["bacs_debit"]
    };
    return (
      <Modal
        show={show}
        centered
        className={"layout-" + company.settings.layout}
      >
        <Modal.Body>

          <div className={className}>

            <h3 className="font-weight-bolder text-dark font-size-h4 mb-8">
              Bank Auth
            </h3>

            {clientSecret &&
              <Elements stripe={stripePromise} fonts={fonts} options={options}>
                <ElementsConsumer fonts={fonts}>
                  {({stripe, elements}) => {
                    this.stripe = stripe
                    this.elements = elements

                    return (
                      <PaymentElement
                        id="payment-element"
                        options={{ layout: "tabs"}}
                      />
                    )
                  }}
                </ElementsConsumer>
              </Elements>
            }

          </div>


        </Modal.Body>

        <Modal.Footer>

          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => this._onCancel()}
          >
            Cancel
          </button>

          <button
            type="button"
            className={`btn btn-primary no-icon`}
            disabled={loading}
            onClick={() => this._onPayPressed(paymentMethod)}
          >
            Pay
          </button>

        </Modal.Footer>
      </Modal>
    )
  }
}

BACSAuthModal.defaultProps = {
  modalClassName: '',
  advanced: true
}
